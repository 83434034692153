import { IAlertArray } from '../../interfaces/alert'

export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const CLOSE_ALERT = 'CLOSE_ALERT'

export interface SetAlertAction {
  type: typeof SET_ALERT
  payload: IAlertArray
}

export interface RemoveAlertAction {
  type: typeof REMOVE_ALERT
  payload: string
}

export interface CloseAlertAction {
  type: typeof CLOSE_ALERT
  payload: string
}

export type AlertActionConstants =
  | SetAlertAction
  | RemoveAlertAction
  | CloseAlertAction
