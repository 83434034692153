import axios from '../../utils/axios'
import { setAlert } from '../alert/actions'
import { Dispatch } from 'redux'
import {
  CHECK_AUTH_STATE,
  CheckAuthState,
  CHECK_AUTH_TIMEOUT,
  CheckAuthTimeout,
  LOAD_USER,
  LoadUser,
  LOAD_USER_SUCCESS,
  LoadUserSuccess,
  LOAD_USER_FAILED,
  LoadUserFailed,
  LOGIN_USER,
  LoginUser,
  LOGIN_USER_SUCCESS,
  LoginUserSuccess,
  LOGIN_USER_FAILED,
  LoginUserFailed,
  CLEAN_UP_LOGIN_USER,
  CleanupLoginUser,
  RESEND_ACTIVATION_TOKEN,
  ResendActivationToken,
  RESEND_ACTIVATION_TOKEN_SUCCESS,
  ResendActivationTokenSuccess,
  RESEND_ACTIVATION_TOKEN_FAILED,
  ResendActivationTokenFailed,
  REGISTER_USER,
  RegisterUser,
  REGISTER_USER_SUCCESS,
  RegisterUserSuccess,
  REGISTER_USER_FAILED,
  RegisterUserFailed,
  GET_STATES,
  GetStates,
  GET_STATES_SUCCESS,
  GetStatesSuccess,
  GET_STATES_FAILED,
  GetStatesFailed,
  ActivateAccount,
  ACTIVATE_ACCOUNT,
  ActivateAccountSuccess,
  ACTIVATE_ACCOUNT_SUCCESS,
  ActivateAccountFailed,
  ACTIVATE_ACCOUNT_FAILED,
  AdminActivateAccount,
  ADMIN_ACTIVATE_ACCOUNT,
  AdminActivateAccountSuccess,
  ADMIN_ACTIVATE_ACCOUNT_SUCCESS,
  AdminActivateAccountFailed,
  ADMIN_ACTIVATE_ACCOUNT_FAILED,
  ForgotPassword,
  FORGOT_PASSWORD,
  ForgotPasswordSuccess,
  FORGOT_PASSWORD_SUCCESS,
  ForgotPasswordFailed,
  FORGOT_PASSWORD_FAILED,
  ResetPassword,
  RESET_PASSWORD,
  ResetPasswordSuccess,
  RESET_PASSWORD_SUCCESS,
  ResetPasswordFailed,
  RESET_PASSWORD_FAILED,
  INITIATE_LOGOUT_USER,
  InitiateLogoutUser,
  LOGOUT_USER,
  LogoutUser
} from './constants'

type formDataType = object

export const checkAuthState =
  () => async (dispatch: Dispatch<CheckAuthState | any>) => {
    const token = localStorage.getItem('the_ceo_token')
    dispatch({ type: CHECK_AUTH_STATE })
    try {
      if (!token) {
        dispatch(initiateLogoutUser())
      } else {
        const localStorageExpirationDate =
          localStorage.getItem('the_ceo_expiration_date')

        const expirationDate =
          localStorageExpirationDate !== null
            ? new Date(localStorageExpirationDate)
            : new Date()

        if (expirationDate <= new Date()) {
          dispatch(initiateLogoutUser())
        } else {
          dispatch(loadUser())
          dispatch(checkAuthTimeout(expirationDate))
        }
      }
    } catch (error) {}
  }

export const checkAuthTimeout =
  (expirationTime: any) =>
  async (dispatch: Dispatch<CheckAuthTimeout | any>) => {
    dispatch({ type: CHECK_AUTH_TIMEOUT })
    try {
      if (expirationTime <= new Date()) {
        dispatch(initiateLogoutUser())
      }
    } catch (error) {}
  }

// Load User
export const loadUser =
  () =>
  async (
    dispatch: Dispatch<LoadUser | LoadUserSuccess | LoadUserFailed | any>
  ) => {
    try {
      dispatch({ type: LOAD_USER })
      const userInfoFromLocalStorage = localStorage.getItem('the_ceo_user_info')
        ? JSON.parse(localStorage.getItem('the_ceo_user_info'))
        : null
      dispatch({
        type: LOAD_USER_SUCCESS,
        payload: userInfoFromLocalStorage
      })
    } catch (err: any) {
      if (err.response) {
        dispatch({
          type: LOAD_USER_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch({
          type: LOAD_USER_FAILED,
          payload: err.message
        })
      }
    }
  }

// Login User
export const loginUser =
  (formData: formDataType) =>
  async (
    dispatch: Dispatch<LoginUser | LoginUserSuccess | LoginUserFailed | any>
  ) => {
    try {
      dispatch({ type: LOGIN_USER })
      const res = await axios.post('/account/login', formData)

      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: res
      })

      const today: any = new Date()
      today.setSeconds(today.getSeconds() + 86400)

      localStorage.setItem('the_ceo_expiration_date', today)
      localStorage.setItem('the_ceo_user_info', JSON.stringify(res.data.data))
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: LOGIN_USER_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: LOGIN_USER_FAILED,
          payload: err.message
        })
      }
    }
  }

export const cleanupLoginUser =
  () => (dispatch: Dispatch<CleanupLoginUser | any>) => {
    try {
      dispatch({ type: CLEAN_UP_LOGIN_USER })
    } catch (error) {}
  }

// Resend Activation Token
export const resendActivationToken =
  (formData: formDataType) =>
  async (
    dispatch: Dispatch<
      | ResendActivationToken
      | ResendActivationTokenSuccess
      | ResendActivationTokenFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: RESEND_ACTIVATION_TOKEN })
      const res = await axios.post('/account/resend-activation', formData)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: RESEND_ACTIVATION_TOKEN_SUCCESS,
        payload: res.data.data
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: RESEND_ACTIVATION_TOKEN_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'warning'))
        dispatch({
          type: RESEND_ACTIVATION_TOKEN_FAILED,
          payload: err.message
        })
      }
    }
  }

// Register User
export const registerUser =
  (formData: formDataType) =>
  async (
    dispatch: Dispatch<
      RegisterUser | RegisterUserSuccess | RegisterUserFailed | any
    >
  ) => {
    try {
      dispatch({ type: REGISTER_USER })
      const res = await axios.post('/account/register', formData)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: REGISTER_USER_SUCCESS,
        payload: res.data
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: REGISTER_USER_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: REGISTER_USER_FAILED,
          payload: err.message
        })
      }
    }
  }

// Activate User
export const activateAccount =
  (token: string) =>
  async (
    dispatch: Dispatch<
      ActivateAccount | ActivateAccountSuccess | ActivateAccountFailed | any
    >
  ) => {
    try {
      dispatch({ type: ACTIVATE_ACCOUNT })
      const res = await axios.get(`/account/activate/${token}`) 

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: ACTIVATE_ACCOUNT_SUCCESS,
        payload: res.data
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: ACTIVATE_ACCOUNT_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: ACTIVATE_ACCOUNT_FAILED,
          payload: err.message
        })
      }
    }
  }

// Admin Activate User
export const adminActivateAccount =
  (id: string) =>
  async (
    dispatch: Dispatch<
      | AdminActivateAccount
      | AdminActivateAccountSuccess
      | AdminActivateAccountFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: ADMIN_ACTIVATE_ACCOUNT })
      const res = await axios.get(`/account/activate/user/${id}`)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: ADMIN_ACTIVATE_ACCOUNT_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: ADMIN_ACTIVATE_ACCOUNT_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: ADMIN_ACTIVATE_ACCOUNT_FAILED,
          payload: err.message
        })
      }
    }
  }

// forgot password
export const forgotPassword =
  (payload: any) =>
  async (
    dispatch: Dispatch<
      ForgotPassword | ForgotPasswordSuccess | ForgotPasswordFailed | any
    >
  ) => {
    try {
      dispatch({ type: FORGOT_PASSWORD })
      const res = await axios.post(`/account/password/forgot`, payload)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: FORGOT_PASSWORD_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: FORGOT_PASSWORD_FAILED,
          payload: err.message
        })
      }
    }
  }

// get states
export const getStates = () =>
    async (
      dispatch: Dispatch<
        GetStates | GetStatesSuccess | GetStatesFailed | any
      >
    ) => {
      try {
        dispatch({ type: GET_STATES })
        const res = await axios.get(`/account/states`)
        dispatch(setAlert(res.data.message, 'success'))
        dispatch({
          type: GET_STATES_SUCCESS,
          payload: res.data.data
        })
      } catch (err: any) {
        if (err.response) {
          dispatch(setAlert(err.response.data.message, 'warning'))
          dispatch({
            type: GET_STATES_FAILED,
            payload: err.response.data.message
          })
        } else {
          dispatch(setAlert(err.message, 'error'))
          dispatch({
            type: GET_STATES_FAILED,
            payload: err.message
          })
        }
      }
    }

// reset password
export const resetPassword =
  (payload: any) =>
  async (
    dispatch: Dispatch<
      ResetPassword | ResetPasswordSuccess | ResetPasswordFailed | any
    >
  ) => {
    try {
      dispatch({ type: RESET_PASSWORD })
      const res = await axios.post(`/account/password/reset`, payload)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: RESET_PASSWORD_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: RESET_PASSWORD_FAILED,
          payload: err.message
        })
      }
    }
  }

export const initiateLogoutUser =
  () => (dispatch: Dispatch<InitiateLogoutUser | any>) => {
    localStorage.removeItem('the_ceo_token')
    // localStorage.removeItem('the_ceo_expiration_date')
    localStorage.removeItem('the_ceo_user_info')
    dispatch({ type: INITIATE_LOGOUT_USER })
    dispatch(logoutUser())
  }

export const logoutUser = (): LogoutUser => ({
  type: LOGOUT_USER
})
