export const DEFAULT_AUTH = {
  token: localStorage.getItem('the_ceo_token'),
  user: localStorage.getItem('the_ceo_user_info')
    ? JSON.parse(localStorage.getItem('the_ceo_user_info'))
    : null,
  user_loading: false,
  user_role: null,
  is_authenticated: null,
  is_authenticated_loading: false,
  resend_activation_token: null,
  resend_activation_token_loading: false,
  get_states: null,
  is_get_states_loading: false,
  is_registered: null,
  is_registered_loading: false,
  is_activated: null,
  activate_account: null,
  activate_account_loading: false,
  admin_activate_account: null,
  admin_activate_account_loading: false,
  forgot_password: null,
  forgot_password_loading: false,
  verify_token: null,
  verify_token_loading: false,
  reset_password: null,
  reset_password_loading: false,
  error: null
}
