export const getAllUsers = (users: []) => {
  if (users.length) {
    const flattenedUsers = users.map((u: { user: object }) => {
      const { user, ...rest } = u
      return {
        ...rest,
        ...user
      }
    })

    // console.log(flattenedUsers)
    return flattenedUsers
  }


  return []
}


export const getAllReferrals = (referrals?: []) => {
  if (referrals.length) {
    const flattenedWithdrawals = referrals.map((referral: { referredBy: any }) => {
      const { referredBy, ...rest } = referral
      return {
        ...rest,
        referrerName: referredBy?.name ?? '-',
        referrerPhone: referredBy?.phone ?? '-',
        referrerEmail: referredBy?.email ?? '-',
      }
    })

    return flattenedWithdrawals
  }

  return []
}