export const DEFAULT_MEMBERSHIP = {
  purchase_membership: null,
  purchase_membership_loading: false,
  admin_assign_membership: null,
  admin_assign_membership_loading: false,
  admin_revoke_membership: null,
  admin_revoke_membership_loading: false,
  error: {
    admin_assign_membership_error: null,
    admin_revoke_membership_error: null,
    purchase_membership_error: null
  }
}
