import React from 'react'

import BaseLayout from '../base/BaseLayout'

import Logo from '../components/logo/Logo'
import Navbar from '../components/navbar/Navbar'

import Menu from '../components/menu/Menu'
// import Search from '../components/search/Search'

import Actions from '../components/actions/Actions'
import { toggleSidebar } from '../../redux/actions'

import { useDispatch, useSelector } from 'react-redux'

import { IAppState } from '../../interfaces/app-state'
import { AdminNavigation, UserNavigation } from '../navigation'

import './Vertical.scss'

type Props = {
  children: any
}

const VerticalLayout = ({ children }: Props) => {
  const dispatch = useDispatch()

  const settings = useSelector((state: IAppState) => state.settings)
  const { user_role } = useSelector((state: IAppState) => state.authentication)

  const onSidebarToggle = () => dispatch(toggleSidebar())

  const nav = (
    <Navbar
      boxed={settings.boxed}
      color={settings.topbarColor}
      background={settings.topbarBg}
      orientation='horizontal'
    >
      <button
        className='no-style navbar-toggle d-lg-none'
        onClick={onSidebarToggle}
      >
        <span />
        <span />
        <span />
      </button>

      {/* <Search layout='vertical' /> */}

      <Actions />
    </Navbar>
  )

  const sideNav = (
    <Navbar
      onClickOutside={onSidebarToggle}
      opened={settings.sidebarOpened}
      color={settings.sidebarColor}
      background={settings.sidebarBg}
      orientation='vertical'
    >
      <Logo />

      {user_role === 'ADMIN' ? (
        <Menu
          onCloseSidebar={onSidebarToggle}
          opened={settings.sidebarOpened}
          orientation='vertical'
          data={AdminNavigation}
        />
      ) : (
        <Menu
          onCloseSidebar={onSidebarToggle}
          opened={settings.sidebarOpened}
          orientation='vertical'
          data={UserNavigation}
        />
      )}
    </Navbar>
  )

  return (
    <>
      <BaseLayout orientation='vertical' nav={nav} sideNav={sideNav}>
        {children}
      </BaseLayout>
    </>
  )
}

export default VerticalLayout
