export const DEFAULT_WALLET = {
  credit_wallet: null,
  credit_wallet_loading: false,
  get_user_balance: null,
  get_user_balance_loading: false,
  transfer_between_wallets: null,
  transfer_between_wallets_loading: false,
  update_wallet_balance: null,
  update_wallet_balance_loading: false,
  error: {
    credit_wallet_error: null,
    get_user_balance_error: null,
    transfer_between_wallets: null,
    update_wallet_balance: null
  }
}
