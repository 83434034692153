import axios from '../../utils/axios'
import { setAlert } from '../alert/actions'
import { Dispatch } from 'redux'
import {
  CREDIT_WALLET,
  CreditWallet,
  CREDIT_WALLET_SUCCESS,
  CreditWalletSuccess,
  CREDIT_WALLET_FAILED,
  CreditWalletFailed,
  GET_USER_BALANCE,
  GetUserBalance,
  GET_USER_BALANCE_SUCCESS,
  GetUserBalanceSuccess,
  GET_USER_BALANCE_FAILED,
  GetUserBalanceFailed,
  TRANSFER_BETWEEN_WALLETS,
  TransferBetweenWallet,
  TRANSFER_BETWEEN_WALLETS_SUCCESS,
  TransferBetweenWalletSuccess,
  TRANSFER_BETWEEN_WALLETS_FAILED,
  TransferBetweenWalletFailed,
  UPDATE_WALLET_BALANCE,
  UpdateWalletBalance,
  UPDATE_WALLET_BALANCE_SUCCESS,
  UpdateWalletBalanceSuccess,
  UPDATE_WALLET_BALANCE_FAILED,
  UpdateWalletBalanceFailed
} from './constants'

type formDataType = object

// Credit Wallet
export const creditWallet =
  (formData: object) =>
  async (
    dispatch: Dispatch<
      CreditWallet | CreditWalletSuccess | CreditWalletFailed | any
    >
  ) => {
    try {
      dispatch({ type: CREDIT_WALLET })
      const res = await axios.post(`/wallet/credit`, formData)
      dispatch({
        type: CREDIT_WALLET_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: CREDIT_WALLET_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: CREDIT_WALLET_FAILED,
          payload: err.message
        })
      }
    }
  }

//  Get User Balance
export const getUserBalance =
  (id: string) =>
  async (
    dispatch: Dispatch<
      GetUserBalance | GetUserBalanceSuccess | GetUserBalanceFailed | any
    >
  ) => {
    try {
      dispatch({ type: GET_USER_BALANCE })
      const res = await axios.get(`/wallet/${id}`)

      dispatch({
        type: GET_USER_BALANCE_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_USER_BALANCE_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_USER_BALANCE_FAILED,
          payload: err.message
        })
      }
    }
  }

//  Transfer Between Wallet
export const transferBetweenWallet =
  (formData: formDataType) =>
  async (
    dispatch: Dispatch<
      | TransferBetweenWallet
      | TransferBetweenWalletSuccess
      | TransferBetweenWalletFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: TRANSFER_BETWEEN_WALLETS })
      const res = await axios.post(`/wallet/transfer`, formData)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: TRANSFER_BETWEEN_WALLETS_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: TRANSFER_BETWEEN_WALLETS_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: TRANSFER_BETWEEN_WALLETS_FAILED,
          payload: err.message
        })
      }
    }
  }

//  Update Wallet Balance
export const updateWalletBalance =
  (formData: formDataType) =>
  async (
    dispatch: Dispatch<
      | UpdateWalletBalance
      | UpdateWalletBalanceSuccess
      | UpdateWalletBalanceFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: UPDATE_WALLET_BALANCE })
      const res = await axios.put(`/wallet/update`, formData)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: UPDATE_WALLET_BALANCE_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: UPDATE_WALLET_BALANCE_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: UPDATE_WALLET_BALANCE_FAILED,
          payload: err.message
        })
      }
    }
  }
