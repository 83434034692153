import React, { useEffect } from 'react'
import Routes from './routing/Routes'
import { Provider } from 'react-redux'
// import { useSelector } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { store } from './redux/store'
import { history } from './redux/reducers'
import setAuthToken from './utils/setAuthToken'
import { checkAuthState } from './redux/auth/actions'
// import { IAppState } from './interfaces/app-state'
import './App.scss'

const configureStore: any = store()

const App = () => {
  // const { token } = useSelector((state: IAppState) => state.authentication)
  useEffect(
    () => {
      setAuthToken(localStorage.the_ceo_token)
      configureStore.dispatch(checkAuthState())
    },
    [
      /*token*/
    ]
  )

  return (
    <Provider store={configureStore}>
      <ConnectedRouter history={history}>
        <Routes></Routes>
        <ToastContainer />
      </ConnectedRouter>
    </Provider>
  )
}

export default App
