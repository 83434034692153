import {
  typeGetBankListSuccess,
  typeVerifyAccountNumberSuccess
} from '../../interfaces/bank'

export const GET_BANK_LIST = 'GET_BANK_LIST'
export const GET_BANK_LIST_SUCCESS = 'GET_BANK_LIST_SUCCESS'
export const GET_BANK_LIST_FAILED = 'GET_BANK_LIST_FAILED'

export const VERIFY_ACCOUNT_NUMBER = 'VERIFY_ACCOUNT_NUMBER'
export const VERIFY_ACCOUNT_NUMBER_SUCCESS = 'VERIFY_ACCOUNT_NUMBER_SUCCESS'
export const VERIFY_ACCOUNT_NUMBER_FAILED = 'VERIFY_ACCOUNT_NUMBER_FAILED'

export interface GetBankList {
  type: typeof GET_BANK_LIST
}
export interface GetBankListSuccess {
  type: typeof GET_BANK_LIST_SUCCESS
  payload: typeGetBankListSuccess
}
export interface GetBankListFailed {
  type: typeof GET_BANK_LIST_FAILED
  payload: any
}
export interface VerifyAccountNumber {
  type: typeof VERIFY_ACCOUNT_NUMBER
}
export interface VerifyAccountNumberSuccess {
  type: typeof VERIFY_ACCOUNT_NUMBER_SUCCESS
  payload: typeVerifyAccountNumberSuccess
}
export interface VerifyAccountNumberFailed {
  type: typeof VERIFY_ACCOUNT_NUMBER_FAILED
  payload: any
}

export type BankActionConstants =
  | GetBankList
  | GetBankListSuccess
  | GetBankListFailed
  | VerifyAccountNumber
  | VerifyAccountNumberSuccess
  | VerifyAccountNumberFailed
