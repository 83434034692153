import {
  typePurchaseContributionSuccess,
  typeGetAllContributionsSuccess,
  typeGetContributionsForASingleUserSuccess,
  typeGetTotalPayoutsSuccess, 
  typeGetPayersSuccess,
  typeGetCurrentPositionOfAUserSuccess,
  typeGetNextInlineForPayoutSuccess
} from '../../interfaces/contribution'

export const PURCHASE_CONTRIBUTION = 'PURCHASE_CONTRIBUTION'
export const PURCHASE_CONTRIBUTION_SUCCESS = 'PURCHASE_CONTRIBUTION_SUCCESS'
export const PURCHASE_CONTRIBUTION_FAILED = 'PURCHASE_CONTRIBUTION_FAILED'

export const GET_ALL_CONTRIBUTIONS = 'GET_ALL_CONTRIBUTIONS'
export const GET_ALL_CONTRIBUTIONS_SUCCESS = 'GET_ALL_CONTRIBUTIONS_SUCCESS'
export const GET_ALL_CONTRIBUTIONS_FAILED = 'GET_ALL_CONTRIBUTIONS_FAILED'

export const GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER =
  'GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER'
export const GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER_SUCCESS =
  'GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER_SUCCESS'
export const GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER_FAILED =
  'GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER_FAILED'

export const GET_TOTAL_PAYOUTS = 'GET_TOTAL_PAYOUTS'
export const GET_TOTAL_PAYOUTS_SUCCESS = 'GET_TOTAL_PAYOUTS_SUCCESS'
export const GET_TOTAL_PAYOUTS_FAILED = 'GET_TOTAL_PAYOUTS_FAILED'

export const GET_PAYERS = 'GET_PAYERS'
export const GET_PAYERS_SUCCESS = 'GET_PAYERS_SUCCESS'
export const GET_PAYERS_FAILED = 'GET_PAYERS_FAILED'

export const GET_CURRENT_POSITION_OF_A_USER = 'GET_CURRENT_POSITION_OF_A_USER'
export const GET_CURRENT_POSITION_OF_A_USER_SUCCESS =
  'GET_CURRENT_POSITION_OF_A_USER_SUCCESS'
export const GET_CURRENT_POSITION_OF_A_USER_FAILED =
  'GET_CURRENT_POSITION_OF_A_USER_FAILED'

export const GET_NEXT_INLINE_FOR_A_PAYOUT = 'GET_NEXT_INLINE_FOR_A_PAYOUT'
export const GET_NEXT_INLINE_FOR_A_PAYOUT_SUCCESS =
  'GET_NEXT_INLINE_FOR_A_PAYOUT_SUCCESS'
export const GET_NEXT_INLINE_FOR_A_PAYOUT_FAILED =
  'GET_NEXT_INLINE_FOR_A_PAYOUT_FAILED'

export interface PurchaseContribution {
  type: typeof PURCHASE_CONTRIBUTION
}
export interface PurchaseContributionSuccess {
  type: typeof PURCHASE_CONTRIBUTION_SUCCESS
  payload: typePurchaseContributionSuccess
}
export interface PurchaseContributionFailed {
  type: typeof PURCHASE_CONTRIBUTION_FAILED
  payload: any
}
export interface GetAllContributions {
  type: typeof GET_ALL_CONTRIBUTIONS
}
export interface GetAllContributionsSuccess {
  type: typeof GET_ALL_CONTRIBUTIONS_SUCCESS
  payload: typeGetAllContributionsSuccess
}
export interface GetAllContributionsFailed {
  type: typeof GET_ALL_CONTRIBUTIONS_FAILED
  payload: any
}
export interface GetAllContributionsForASingleUser {
  type: typeof GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER
}
export interface GetAllContributionsForASingleUserSuccess {
  type: typeof GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER_SUCCESS
  payload: typeGetContributionsForASingleUserSuccess
}
export interface GetAllContributionsForASingleUserFailed {
  type: typeof GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER_FAILED
  payload: any
}

export interface GetTotalPayouts {
  type: typeof GET_TOTAL_PAYOUTS
}
export interface GetTotalPayoutsSuccess {
  type: typeof GET_TOTAL_PAYOUTS_SUCCESS
  payload: typeGetTotalPayoutsSuccess
}
export interface GetTotalPayoutsFailed {
  type: typeof GET_TOTAL_PAYOUTS_FAILED
  payload: any
}

export interface GetPayers {
  type: typeof GET_PAYERS
}
export interface GetPayersSuccess {
  type: typeof GET_PAYERS_SUCCESS
  payload: typeGetPayersSuccess
}
export interface GetPayersFailed {
  type: typeof GET_PAYERS_FAILED
  payload: any
}

export interface GetCurrentPositionOfAUser {
  type: typeof GET_CURRENT_POSITION_OF_A_USER
}
export interface GetCurrentPositionOfAUserSuccess {
  type: typeof GET_CURRENT_POSITION_OF_A_USER_SUCCESS
  payload: typeGetCurrentPositionOfAUserSuccess
}
export interface GetCurrentPositionOfAUserFailed {
  type: typeof GET_CURRENT_POSITION_OF_A_USER_FAILED
  payload: any
}

export interface GetNextInLineForAPayout {
  type: typeof GET_NEXT_INLINE_FOR_A_PAYOUT
}
export interface GetNextInLineForAPayoutSuccess {
  type: typeof GET_NEXT_INLINE_FOR_A_PAYOUT_SUCCESS
  payload: typeGetNextInlineForPayoutSuccess
}
export interface GetNextInLineForAPayoutFailed {
  type: typeof GET_NEXT_INLINE_FOR_A_PAYOUT_FAILED
  payload: any
}

export type ContributionActionConstants =
  | PurchaseContribution
  | PurchaseContributionSuccess
  | PurchaseContributionFailed
  | GetAllContributions
  | GetAllContributionsSuccess
  | GetAllContributionsFailed
  | GetNextInLineForAPayout
  | GetNextInLineForAPayoutSuccess
  | GetNextInLineForAPayoutFailed
  | GetAllContributionsForASingleUser
  | GetAllContributionsForASingleUserSuccess
  | GetAllContributionsForASingleUserFailed
  | GetTotalPayouts
  | GetTotalPayoutsSuccess
  | GetTotalPayoutsFailed
  | GetCurrentPositionOfAUser
  | GetCurrentPositionOfAUserSuccess
  | GetCurrentPositionOfAUserFailed
  | GetPayers
  | GetPayersSuccess
  | GetPayersFailed
