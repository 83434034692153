import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'

import { getSettings, getUserSettingsRequest } from '../../../../../redux/actions'
import { LoaderOne } from '../../../Loader'

type Props = {
  contributionAmount?: any
  walletTransferCharge?: any
  getSettings?: () => void
  purchase_contribution_loading?: boolean
  get_settings?: any
  get_settings_loading?: boolean
  handleSubmit?: () => void
  handleCancel?: () => void
  onSubmit?: () => void
  onCancel?: () => void
  amount?: number
  submitText?: string
  userPosition?: any 
  userId?: any
  is_authenticated?: boolean | undefined
  user_role?: string | undefined
  getUserSettingsRequest?: any | undefined
}

const defaultSubmitText = 'Confirm'

const WarningComponent = ({
  amount,
  purchase_contribution_loading,
  handleSubmit,
  handleCancel,
  walletTransferCharge
}: Props) => {
  return (
    <div>
      <div className='d-flex flex-column align-items-center justify-content-center'>
        <h6 className='mb-1'>
          You are about to confirm payment of{' '}
          {`₦${new Intl.NumberFormat('en-US').format(amount)}`}.
        </h6>
        <h6 className='mt-1'>
          The receipient will be charged{' '}
          {`${new Intl.NumberFormat('en-US').format(walletTransferCharge)}%`}{' '}of the amount for this transaction.
        </h6>
        <p>Are you sure you want to continue?</p>
      </div>
      <div className='d-flex justify-content-between buttons-list settings-actions'>
        <Button danger onClick={handleCancel}>
          Cancel
        </Button>

        <Button
          type='primary'
          disabled={purchase_contribution_loading}
          loading={purchase_contribution_loading}
          onClick={handleSubmit}
        >
          Pay
        </Button>
      </div>
    </div>
  )
}

const ContributeForm = ({
  contributionAmount,
  getSettings,
  get_settings,
  get_settings_loading,
  purchase_contribution_loading,
  submitText = defaultSubmitText,
  is_authenticated,
  user_role,
  onSubmit,
  onCancel,
  getUserSettingsRequest,
}: Props) => {
  const [showWarningPrompt, setShowWarning] = useState(false)

  useEffect(() => {
    let mounted = true
    if (mounted) {
      if (is_authenticated && user_role === 'ADMIN') {
        !get_settings && getSettings()
      }

      if (is_authenticated && user_role === 'USER') {
        getUserSettingsRequest()
      }

    }
    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let mounted = true
    if (mounted) {}
    return () => {
      mounted = false
    }
  }, [])

  const handleCancel = () => {
    setShowWarning(false)
    onCancel()
  }
  const handleShowWarningPrompt = () => setShowWarning(!showWarningPrompt)
  const handleSubmit = () => onSubmit()

  return (
    <>
      {get_settings_loading ? (
        <LoaderOne />
      ) : (
        <div>
            <>
              {showWarningPrompt ? (
                <WarningComponent
                  amount={contributionAmount}
                  purchase_contribution_loading={purchase_contribution_loading}
                  handleSubmit={handleSubmit}
                  handleCancel={handleCancel}
                  walletTransferCharge={get_settings?.walletTransferCharge}
                />
              ) : (
                <>
                  <div className='d-flex justify-content-center '>
                    <h5>
                      Confirm payment of{' '}
                        {`₦${new Intl.NumberFormat('en-US').format(contributionAmount)}`}.
                    </h5>
                  </div>
                  <div className='d-flex justify-content-center buttons-list settings-actions'>
                    <Button type='primary' onClick={handleShowWarningPrompt}>
                      {submitText}
                    </Button>
                  </div>
                </>
              )}
            </>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  is_authenticated: state.authentication.is_authenticated,
  user_role: state.authentication.user_role,
  get_settings: state.configurations.get_settings,
  get_settings_loading: state.configurations.get_settings_loading,
  purchase_contribution_loading:
    state.contributions.purchase_contribution_loading
})

export default connect(mapStateToProps, { getSettings, getUserSettingsRequest })(ContributeForm)
