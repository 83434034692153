export const DEFAULT_CONFIGURATIONS = {
  update_settings: null,
  update_settings_loading: false,
  get_settings: null,
  get_settings_loading: false,
  start_counter: null,
  start_counter_loading: false,
  get_count: null,
  get_count_loading: false,
  reset_counter: null,
  reset_counter_loading: false,
  error: {
    update_settings_error: null,
    get_settings_error: null,
    start_counter_error: null,
    get_count_error: null,
    reset_counter_error: null
  }
}
