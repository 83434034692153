import {
  AlertActionConstants,
  SET_ALERT,
  REMOVE_ALERT,
  CLOSE_ALERT
} from './constants'

import { IAlert } from '../../interfaces/alert'

const initialState: IAlert = {
  properties: []
}

export const alertReducer = (
  state: IAlert['properties'] = initialState.properties,
  action: AlertActionConstants
) => {
  const { type, payload } = action

  switch (type) {
    case SET_ALERT:
      return [payload]
    case REMOVE_ALERT:
      return state.filter((alert) => alert.id !== payload)
    case CLOSE_ALERT:
      return state.filter((alert) => alert.id !== payload)
    default:
      return state
  }
}
