import moment from 'moment'

interface IContributionData {
  id?: any
  isPaid?: any
  contribution?: any
  payout?: any
  position?: any
  createdAt?: any
  updatedAt?: any
  name?: any
  createdAtDay?: any
  updatedAtDay?: any
}

const groupArrayOfObjects = (list, key) => {
  return list.reduce((rv, x) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export const getNextInLineForPayout = (data: { user: object }) => {
  if (data) {
    return data
  }

  return null
}

export const getAllContributions = (contributions: []) => {
  if (contributions.length) {
    const flattenedContributions = contributions.map(
      (contribution: { user: object }) => {
        const { user, ...rest } = contribution
        return {
          ...rest,
          ...user
        }
      }
    )

    return flattenedContributions
  }

  return []
}


export const getAllPayers = (payers?: []) => {
  if (payers.length) {
    const flattenedWithdrawals = payers.map((payer: { contributor: any }) => {
      const { contributor, ...rest } = payer
      return {
        ...rest,
        ...contributor,
      }
    })

    // console.log(flattenedWithdrawals)

    return flattenedWithdrawals
  }

  return []
}

export const getLastContributor = (contributions: any) => {
  if (contributions.length) {
    const { user, ...rest } = contributions[0]
    return {
      ...user,
      ...rest
    }
  }

  return []
}

export const getCurrentPositionOfAUser = (data: any) => {
  if (data.length) {
    const { ...rest } = data[0]
    return {
      ...rest
    }
  }

  return null
}

export const getGeneralContributions = (contributions: []) => {
  const flattenedContributions = getAllContributions(contributions)

  let getDayFromDateContributionsArray
  let contributionsPerDayArray = []
  let totalContributionsPerDay = []

  if (flattenedContributions.length) {
    getDayFromDateContributionsArray = flattenedContributions.map(
      (contribution: IContributionData) => {
        let container: IContributionData = {}

        container.id = contribution.id
        container.isPaid = contribution.isPaid
        container.contribution = contribution.contribution
        container.payout = contribution.payout
        container.position = contribution.position
        container.createdAt = contribution.createdAt
        container.updatedAt = contribution.updatedAt
        container.createdAtDay = moment(contribution.createdAt).format('dddd')
        container.updatedAtDay = moment(contribution.updatedAt).format('dddd')
        container.name = contribution.name

        return container
      }
    )
    const groupedGetDayFromDateContributionsArray = groupArrayOfObjects(
      getDayFromDateContributionsArray,
      'createdAtDay'
    )

    for (const property in groupedGetDayFromDateContributionsArray) {
      let groupedGetDayFromDateContributionsArrayArray = []

      groupedGetDayFromDateContributionsArray[property].forEach((item) => {
        groupedGetDayFromDateContributionsArrayArray.push({
          contribution: item.contribution,
          day: item.createdAtDay
        })
      })
      contributionsPerDayArray.push(
        groupedGetDayFromDateContributionsArrayArray
      )
    }

    contributionsPerDayArray.forEach((item) => {
      const groupedGetDayFromDateContributionsArraySum = item.reduce(
        (a: any, b: any) => {
          return {
            day: a.day,
            contribution: a.contribution + b.contribution
          }
        }
      )
      totalContributionsPerDay.push(groupedGetDayFromDateContributionsArraySum)
    })

    return totalContributionsPerDay
  }
  return []
}
