import React, { lazy } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IRoute } from '../interfaces/routing'
import { IAppState } from '../interfaces/app-state'
import { IAuth } from '../interfaces/auth'

const NotFound = lazy(() => import('../pages/sessions/404'))
const InternalError = lazy(() => import('../pages/sessions/500'))
const SignIn = lazy(() => import('../pages/sessions/SignIn'))
const SignUp = lazy(() => import('../pages/sessions/SignUp'))
const UpdateBankDetails = lazy(() => import('../pages/sessions/UpdateBankDetails'))
const ActivateAccount = lazy(() => import('../pages/sessions/ActivateAccount'))
const ResendActivationToken = lazy(
  () => import('../pages/sessions/ResendActivationToken')
)
const Logout = lazy(() => import('../pages/sessions/Logout'))
const LogoutUser = lazy(() => import('../pages/sessions/LogoutUser'))
const ForgotPassword = lazy(() => import('../pages/sessions/ForgotPassword'))
const VerifyToken = lazy(() => import('../pages/sessions/ActivateAccount'))
const ResetPassword = lazy(() => import('../pages/sessions/ResetPassword'))
const Dashboard = lazy(
  () => import('../pages/applications/Dashboard/Dashboard')
)
const Users = lazy(() => import('../pages/applications/Users'))
const UsersDetails = lazy(
  () => import('../pages/applications/Users/components/UserDetails')
)

const User = lazy(() => import('../pages/applications/User'))
const UserAccountDetails = lazy(
  () => import('../pages/applications/User/components/UserDetails')
)
const Contributions = lazy(() => import('../pages/applications/Contributions'))
const Referrals = lazy(() => import('../pages/applications/Referrals'))
const MyReferrals = lazy(() => import('../pages/applications/MyReferrals'))
const Payouts = lazy(() => import('../pages/applications/Payouts'))
const PayoutsDetails = lazy(() => import('../pages/applications/Payouts/components/PayoutDetails'))
const LendMeDashboard = lazy(() => import('../pages/applications/LendMe/LendMeDashboard'))
const LendMeLoanRequests = lazy(() => import('../pages/applications/LendMe/LoanRequests'))
const LendMeLenders = lazy(() => import('../pages/applications/LendMe/Lenders'))
const LendMeSettings = lazy(() => import('../pages/applications/LendMe/Settings'))
// ========
const LendMeUserHome = lazy(() => import('../pages/applications/User/LendMe/LendMeDashboard'))
const LendMeDepositHistory = lazy(() => import('../pages/applications/User/LendMe/DepositHistory'))
const LendMeLoanHistory = lazy(() => import('../pages/applications/User/LendMe/LoanHistory'))
const LendMeRequestLoan = lazy(() => import('../pages/applications/User/LendMe/RequestLoan'))
const Settings = lazy(() => import('../pages/applications/Settings'))

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
  const auth = useSelector<IAppState, IAuth>((state) => state.authentication)
  const { token, user_role } = auth
  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (!token) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{
                pathname: '/auth/sign-in',
                state: { from: props.location }
              }}
            />
          )
        }
        if (token && user_role) {
          if (user_role !== 'ADMIN') {
            // logged in but not admin so redirect to user dashboard with the return url
            return (
              <Redirect
                to={{
                  pathname: '/app/home',
                  state: { from: props.location }
                }}
              />
            )
          }
        }
        return <Component {...props} />
      }}
    />
  )
}

// eslint-disable-next-line
const UserPrivateRoute = ({ component: Component, roles, ...rest }: any) => {
  const auth = useSelector<IAppState, IAuth>((state) => state.authentication)
  const { token, user_role } = auth
  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (!token) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{
                pathname: '/auth/sign-in',
                state: { from: props.location }
              }}
            />
          )
        }
        if (token && user_role) {
          if (user_role === 'ADMIN') {
            // logged in and Admin so redirect to product purchase page with the return url
            return (
              <Redirect
                to={{
                  pathname: '/app/dashboard',
                  state: { from: props.location }
                }}
              />
            )
          }
        }
        // authorised so return component
        return <Component {...props} />
      }}
    />
  )
}

export const sessionRoutes: IRoute[] = [
  {
    path: 'page-404',
    exact: true,
    component: NotFound,
    route: Route
  },
  {
    path: 'page-500',
    exact: true,
    component: InternalError,
    route: Route
  },
  {
    path: 'sign-in',
    exact: true,
    component: SignIn,
    route: Route
  },
  {
    path: 'sign-up',
    exact: true,
    component: SignUp,
    route: Route
  },
  {
    path: 'resend-activation-token',
    exact: true,
    component: ResendActivationToken,
    route: Route
  },
  {
    path: 'activate-account/:token',
    exact: true,
    component: ActivateAccount,
    route: Route
  },
  {
    path : 'update-bank-details',
    exact: true,
    component: UpdateBankDetails,
    route: Route
  },
  {
    path: 'forgot-password',
    exact: true,
    component: ForgotPassword,
    route: Route
  },
  {
    path: 'verify-token',
    exact: true,
    component: VerifyToken,
    route: Route
  },
  {
    path: 'reset-password/:token',
    exact: true,
    component: ResetPassword,
    route: Route
  }
]

export const defaultRoutes: IRoute[] = [
  {
    path: 'dashboard',
    exact: true,
    component: Dashboard,
    route: PrivateRoute
  },
  {
    path: 'users',
    exact: true,
    component: Users,
    route: PrivateRoute
  },
  {
    path: 'users/:id',
    exact: true,
    component: UsersDetails,
    route: PrivateRoute
  },
  {
    path: 'contributions',

    exact: true,
    component: Contributions,
    route: PrivateRoute
  },
  {
    path: 'invitees',

    exact: true,
    component: Referrals,
    route: PrivateRoute
  },
  {
    path: 'my-invitees',

    exact: true,
    component: MyReferrals,
    route: UserPrivateRoute
  },
  {
    path: 'payouts',
    exact: true,
    component: Payouts,
    route: PrivateRoute
  },
  {
    path: 'lend-me-dashboard',
    exact: true,
    component: LendMeDashboard,
    route: PrivateRoute
  },
  {
    path: 'lend-me-admin-loan-requests',
    exact: true,
    component: LendMeLoanRequests,
    route: PrivateRoute
  },
  {
    path: 'lend-me-admin-lenders',
    exact: true,
    component: LendMeLenders,
    route: PrivateRoute
  }, 
  {
    path: 'my-lend-me-dashboard',
    exact: true,
    component: LendMeUserHome,
    route: UserPrivateRoute
  },
  {
    path: 'my-lend-me-deposit-history',
    exact: true,
    component: LendMeLoanHistory,
    route: UserPrivateRoute
  },
  {
    path: 'my-lend-me-loan-history',
    exact: true,
    component: LendMeDepositHistory,
    route: UserPrivateRoute
  },
  {
    path: 'my-lend-request-loan',
    exact: true,
    component: LendMeRequestLoan,
    route: UserPrivateRoute
  },
  {
    path: 'lend-me-settings',
    exact: true,
    component: LendMeSettings,
    route: PrivateRoute
  },
  {
    path: 'payouts/:position',
    exact: true,
    component: PayoutsDetails,
    route: PrivateRoute
  },
  {
    path: 'settings',
    exact: true,
    component: Settings,
    route: PrivateRoute
  },
  {
    path: 'home',
    exact: true,
    component: User,
    route: UserPrivateRoute
  },
  {
    path: 'account',
    exact: true,
    component: UserAccountDetails,
    route: UserPrivateRoute
  },
  // {
  //   path: 'lend-me-user',
  //   exact: true,
  //   component: MyLendMe,
  //   route: UserPrivateRoute
  // },
  {
    path: 'logout-user',
    exact: true,
    component: LogoutUser,
    route: UserPrivateRoute
  },
  {
    path: 'logout',
    exact: true,
    component: Logout,
    route: PrivateRoute
  }
]
