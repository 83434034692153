import { useEffect } from 'react'

export function useHideLoader() {
  useEffect(() => {
    const overlay = document.querySelector('.loader-overlay')
    const loader = document.querySelector('.main-loader')

    setTimeout(() => {
      overlay && loader.remove()
      loader && overlay.remove()
    }, 300)
  }, [])
}
