import axios from '../../utils/axios'
import { setAlert } from '../alert/actions'
import { Dispatch } from 'redux'
import {
  GET_ALL_TRANSACTIONS,
  GetAllTransactions,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GetAllTransactionsSuccess,
  GET_ALL_TRANSACTIONS_FAILED,
  GetAllTransactionsFailed,
  GET_ALL_TRANSACTIONS_FOR_A_USER,
  GetAllTransactionsForAUser,
  GET_ALL_TRANSACTIONS_FOR_A_USER_SUCCESS,
  GetAllTransactionsForAUserSuccess,
  GET_ALL_TRANSACTIONS_FOR_A_USER_FAILED,
  GetAllTransactionsForAUserFailed
} from './constants'

// Get All Transactions
export const getAllTransactions =
  (page?: number | undefined, pageSize?: number | undefined, searchValue?: any, query?: any) =>
  async (
    dispatch: Dispatch<
      | GetAllTransactions
      | GetAllTransactionsSuccess
      | GetAllTransactionsFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: GET_ALL_TRANSACTIONS })

      let res: any;

      if (searchValue && query) {
        res = await axios.get(`/transaction/list?page=${1}&pageSize=${20}&${query}=${searchValue}`)
      } else {
        res = await axios.get(`/transaction/list?page=${page ?? 1}&pageSize=${pageSize ?? 20}`)
      }

      // const res = await axios.get(``)
      dispatch({
        type: GET_ALL_TRANSACTIONS_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_ALL_TRANSACTIONS_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_ALL_TRANSACTIONS_FAILED,
          payload: err.message
        })
      }
    }
  }

//  Get All Transactions For A User
export const getAllTransactionsForAUser =
  (id: string, page?: number | undefined, pageSize?: number | undefined, searchValue?: any, query?: any) =>
  async (
    dispatch: Dispatch<
      | GetAllTransactionsForAUser
      | GetAllTransactionsForAUserSuccess
      | GetAllTransactionsForAUserFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: GET_ALL_TRANSACTIONS_FOR_A_USER })

      let res: any;

      if (searchValue && query) {
        res = await axios.get(`/transaction/user/${id}?page=${1}&pageSize=${20}&${query}=${searchValue}`)
      } else {
        res = await axios.get(`/transaction/user/${id}?page=${page ?? 1}&pageSize=${pageSize ?? 20}`)
      }

      // const res = await axios.get(`/transaction/user/${id}?page=${page ?? 1}&pageSize=${pageSize ?? 20}`)

      dispatch({
        type: GET_ALL_TRANSACTIONS_FOR_A_USER_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_ALL_TRANSACTIONS_FOR_A_USER_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_ALL_TRANSACTIONS_FOR_A_USER_FAILED,
          payload: err.message
        })
      }
    }
  }
