import {
  typeLoginUserSuccess,
  typeLoadUserSuccess,
  typeActivateAccountSuccess,
  typeAdminActivateAccountSuccess,
  typeForgotPasswordSuccess,
  typeGetStatesSuccess,
typeResetPasswordSuccess
} from '../../interfaces/auth'

export const CHECK_AUTH_TIMEOUT = 'CHECK_AUTH_TIMEOUT'
export const CHECK_AUTH_STATE = 'CHECK_AUTH_STATE'

export const LOAD_USER = 'LOAD_USER'
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
export const LOAD_USER_FAILED = 'LOAD_USER_FAILED'

export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED'

export const CLEAN_UP_LOGIN_USER = 'CLEAN_UP_LOGIN_USER'

export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED'

export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT'
export const ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVATE_ACCOUNT_SUCCESS'
export const ACTIVATE_ACCOUNT_FAILED = 'ACTIVATE_ACCOUNT_FAILED'

export const ADMIN_ACTIVATE_ACCOUNT = 'ADMIN_ACTIVATE_ACCOUNT'
export const ADMIN_ACTIVATE_ACCOUNT_SUCCESS = 'ADMIN_ACTIVATE_ACCOUNT_SUCCESS'
export const ADMIN_ACTIVATE_ACCOUNT_FAILED = 'ADMIN_ACTIVATE_ACCOUNT_FAILED'

export const GET_STATES = 'GET_STATES'
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS'
export const GET_STATES_FAILED = 'GET_STATES_FAILED'

export const RESEND_ACTIVATION_TOKEN = 'RESEND_ACTIVATION_TOKEN'
export const RESEND_ACTIVATION_TOKEN_SUCCESS = 'RESEND_ACTIVATION_TOKEN_SUCCESS'
export const RESEND_ACTIVATION_TOKEN_FAILED = 'RESEND_ACTIVATION_TOKEN_FAILED'

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED'

export const VERIFY_TOKEN = 'VERIFY_TOKEN'
export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS'
export const VERIFY_TOKEN_FAILED = 'VERIFY_TOKEN_FAILED'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'

export const INITIATE_LOGOUT_USER = 'INITIATE_LOGOUT_USER'
export const LOGOUT_USER = 'LOGOUT_USER'

export interface CheckAuthTimeout {
  type: typeof CHECK_AUTH_TIMEOUT
}
export interface CheckAuthState {
  type: typeof CHECK_AUTH_STATE
}

export interface LoadUser {
  type: typeof LOAD_USER
}
export interface LoadUserSuccess {
  type: typeof LOAD_USER_SUCCESS
  payload: typeLoadUserSuccess
}
export interface LoadUserFailed {
  type: typeof LOAD_USER_FAILED
  payload: any
}
export interface LoginUser {
  type: typeof LOGIN_USER
}
export interface LoginUserSuccess {
  type: typeof LOGIN_USER_SUCCESS
  payload: typeLoginUserSuccess
}
export interface LoginUserFailed {
  type: typeof LOGIN_USER_FAILED
  payload: any
}

export interface GetStates {
  type: typeof GET_STATES
}
export interface GetStatesSuccess {
  type: typeof GET_STATES_SUCCESS
  payload: typeGetStatesSuccess
}
export interface GetStatesFailed {
  type: typeof GET_STATES_FAILED
  payload: any
}

export interface CleanupLoginUser {
  type: typeof CLEAN_UP_LOGIN_USER
}

export interface ResendActivationToken {
  type: typeof RESEND_ACTIVATION_TOKEN
}
export interface ResendActivationTokenSuccess {
  type: typeof RESEND_ACTIVATION_TOKEN_SUCCESS
  payload: any
}
export interface ResendActivationTokenFailed {
  type: typeof RESEND_ACTIVATION_TOKEN_FAILED
  payload: any
}
export interface RegisterUser {
  type: typeof REGISTER_USER
}
export interface RegisterUserSuccess {
  type: typeof REGISTER_USER_SUCCESS
  payload: any
}
export interface RegisterUserFailed {
  type: typeof REGISTER_USER_FAILED
  payload: any
}

export interface ActivateAccount {
  type: typeof ACTIVATE_ACCOUNT
}
export interface ActivateAccountSuccess {
  type: typeof ACTIVATE_ACCOUNT_SUCCESS
  payload: typeActivateAccountSuccess
}
export interface ActivateAccountFailed {
  type: typeof ACTIVATE_ACCOUNT_FAILED
  payload: any
}

export interface AdminActivateAccount {
  type: typeof ADMIN_ACTIVATE_ACCOUNT
}
export interface AdminActivateAccountSuccess {
  type: typeof ADMIN_ACTIVATE_ACCOUNT_SUCCESS
  payload: typeAdminActivateAccountSuccess
}
export interface AdminActivateAccountFailed {
  type: typeof ADMIN_ACTIVATE_ACCOUNT_FAILED
  payload: any
}

export interface ForgotPassword {
  type: typeof FORGOT_PASSWORD
}
export interface ForgotPasswordSuccess {
  type: typeof FORGOT_PASSWORD_SUCCESS
  payload: typeForgotPasswordSuccess
}
export interface ForgotPasswordFailed {
  type: typeof FORGOT_PASSWORD_FAILED
  payload: any
}

export interface VerifyToken {
  type: typeof VERIFY_TOKEN
}
export interface VerifyTokenSuccess {
  type: typeof VERIFY_TOKEN_SUCCESS
  payload: any
}
export interface VerifyTokenFailed {
  type: typeof VERIFY_TOKEN_FAILED
  payload: any
}
export interface ResetPassword {
  type: typeof RESET_PASSWORD
}
export interface ResetPasswordSuccess {
  type: typeof RESET_PASSWORD_SUCCESS
  payload: typeResetPasswordSuccess
}
export interface ResetPasswordFailed {
  type: typeof RESET_PASSWORD_FAILED
  payload: any
}

export interface InitiateLogoutUser {
  type: typeof INITIATE_LOGOUT_USER
}
export interface LogoutUser {
  type: typeof LOGOUT_USER
}

export type AuthActionConstants =
  | CheckAuthTimeout
  | CheckAuthState
  | LoadUser
  | LoadUserSuccess
  | LoadUserFailed
  | LoginUser
  | LoginUserSuccess
  | LoginUserFailed
  | CleanupLoginUser
  | ResendActivationToken
  | ResendActivationTokenSuccess
  | ResendActivationTokenFailed
  | RegisterUser
  | RegisterUserSuccess
  | RegisterUserFailed
  | ActivateAccount
  | ActivateAccountSuccess
  | AdminActivateAccount
  | AdminActivateAccountSuccess
  | AdminActivateAccountFailed
  | ActivateAccountFailed
  | ForgotPassword
  | ForgotPasswordSuccess
  | ForgotPasswordFailed
  | VerifyToken
  | VerifyTokenSuccess
  | VerifyTokenFailed
  | ResetPassword
  | ResetPasswordSuccess
  | ResetPasswordFailed
  | InitiateLogoutUser
  | LogoutUser
  | GetStates
| GetStatesSuccess
| GetStatesFailed
