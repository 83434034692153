export const DEFAULT_LEND_ME = {
  get_user_home_data:  null,
  get_user_home_data_loading: false,
  get_user_settings:  null,
  get_user_settings_loading: false,
  fund_user_lend_me:  null,
  fund_user_lend_me_loading: false,
  get_user_lend_me_deposit_history:  null,
  get_user_lend_me_deposit_history_pagination:  null,
  get_user_lend_me_deposit_history_loading: false,
  request_user_loan:  null,
  request_user_loan_loading: false,
  get_user_loan_history:  null,
  get_user_loan_history_loading: false,
  get_user_lend_me_balance_transaction_history:  null,
  get_user_lend_me_balance_transaction_history_loading: false,
  withdraw_from_lend_me_wallet: null,
  withdraw_from_lend_me_wallet_loading: false,
  // ===============================================================
  get_admin_home_data:  null,
  get_admin_home_data_loading: false,
  get_admin_settings:  null,
  get_admin_settings_loading: false,
  update_settings:  null,
  update_settings_loading: false,
  get_loan_requests:  null,
  get_loan_requests_pagination:  null,
  get_loan_requests_loading: false,
  update_loan_requests_status:  null,
  update_loan_requests_status_loading: false,
  update_loan_payment:  null,
  update_loan_payment_loading: false,
  get_lenders: null,
  get_lenders_pagination: null,
  get_lenders_loading: false,
  // ==============================================================
  file_upload:  null,
  file_upload_loading: false,
  error: {
    get_user_home_data_error:  null,
    get_user_settings_error:  null,
    fund_user_lend_me_error:  null,
    get_user_lend_me_deposit_history_error:  null,
    request_user_load_error:  null,
    get_user_loan_history_error:  null,
    get_user_lend_me_balance_transaction_history_error:  null,
    withdraw_from_lend_me_wallet_error: null,
    // ================================================================
    get_admin_home_data_error:  null,
    get_admin_settings_error:  null,
    update_settings_error:  null,
    get_loan_requests_error:  null,
    update_loan_requests_status_error:  null,
    update_loan_payment_error: null, 
    get_lenders_error: null,
    // =================================================================
    file_upload_error:  null,
  }
}
