import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { Button, Input } from 'antd'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { getSettings, getUserSettingsRequest } from '../../../../../redux/actions'

import { hasErrorFactory } from '../../../../../utils/hasError'

interface IWithdraw {
  amount: string
}

type Props = {
  get_user_balance?: {
    accountName: any
    accountNumber: any
    balance: any
    bankName: any
    id: any
    user: any
  }
  withdrawal_charge?: any
  get_settings_loading?: any
  get_settings?: any
  amount?: any
  request_withdrawal_loading?: boolean
  onSubmit?: (withdraw: IWithdraw) => void
  onCancel?: () => void
  withdraw?: IWithdraw
  submitText?: string
  handleCancel?: () => void
  disabled?: boolean
  htmlType?: 'submit'
  getSettings?: () => void
  is_authenticated?: boolean | undefined
  user_role?: string | undefined
  getUserSettingsRequest?: any | undefined
}

const defaultSubmitText = 'Withdraw'
const emptyWithdraw = {
  amount: '100'
}

const withdrawScheme = Yup.object({
  amount: Yup.number()
    .required('This field is required')
    .min(100, `Must be ₦${new Intl.NumberFormat('en-US').format(1000)} or more`)
})

const WarningComponent = ({
  get_user_balance,
  amount,
  request_withdrawal_loading,
  handleCancel,
  disabled,
  htmlType
}: Props) => {
  return (
    <>
      {get_user_balance.balance >= amount ? (
        <>
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <h6>
              You are about to make a withdrawal of{' '}
              {`₦${new Intl.NumberFormat('en-US').format(amount)}`}.
            </h6>
            <p>Are you sure you want to continue?</p>
          </div>
          <div className='d-flex justify-content-between buttons-list settings-actions'>
            <Button danger onClick={handleCancel}>
              Cancel
            </Button>

            <Button
              type='primary'
              htmlType={htmlType}
              disabled={disabled || request_withdrawal_loading}
              loading={request_withdrawal_loading}
            >
              Continue
            </Button>
          </div>
        </>
      ) : (
        <div className='d-flex justify-content-center'>
          <p>
            Sorry, your attempted withdrawal of{' '}
            {`₦${new Intl.NumberFormat('en-US').format(amount)}`}, <br /> will
            not be allowed due to insufficient funds.
          </p>
        </div>
      )}
    </>
  )
}

const WithdrawForm = ({
  get_user_balance,
  request_withdrawal_loading,
  submitText = defaultSubmitText,
  withdraw = emptyWithdraw,
  withdrawal_charge,
  onSubmit,
  onCancel,
  get_settings_loading,
get_settings,
  getSettings,
  is_authenticated,
  user_role,
   getUserSettingsRequest
}: Props) => {
  const [showWarningPrompt, setShowWarning] = useState(false)
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    touched,
    values,
    errors,
    isValid
  } = useFormik<IWithdraw>({
    validationSchema: withdrawScheme,
    initialValues: withdraw,
    onSubmit: (values) => {
      onSubmit(values)
    }
  })

  useEffect(() => {
    let mounted = true
    if (mounted) {
      if (is_authenticated && user_role === 'ADMIN') {
        !get_settings && getSettings()
      }

      if (is_authenticated && user_role === 'USER') {
        getUserSettingsRequest()
      }
    }
    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasError = hasErrorFactory(touched, errors)

  const handleCancel = () => {
    resetForm()
    setShowWarning(!showWarningPrompt)
    onCancel()
  }
  const handleShowWarningPrompt = (amount) => {
    amount && setShowWarning(!showWarningPrompt)
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        {showWarningPrompt ? (
          <WarningComponent
            get_user_balance={get_user_balance}
            amount={values.amount}
            request_withdrawal_loading={request_withdrawal_loading}
            handleCancel={handleCancel}
            disabled={!isValid}
            htmlType='submit'
            withdrawal_charge={get_settings?.withdrawalCharge}
          />
        ) : (
          <>{
              get_settings_loading ? 
              <>
                <div className='d-flex flex-column align-items-center justify-content-center'>
                  <h6>
                    Loading withdrawal charge, Please wait a moment.
                  </h6>
                </div>
              </> : 
              <>
                  <div className='d-flex flex-column align-items-center justify-content-center'>
                    <h6>
                      You will be charged{' '}
                      {`${new Intl.NumberFormat('en-US').format(get_settings?.withdrawalCharge)}%`}{' '}of the amount for this transaction.
                    </h6>
                  </div>
                  <div className='form-group'>
                    <Input
                      placeholder={`Amount Must be ₦${new Intl.NumberFormat(
                        'en-US'
                      ).format(100)} or more`}
                      name='amount'
                      type='number'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultValue={values.amount}
                      className={hasError('amount')}
                    />
                  </div>
                  <div className='d-flex justify-content-between buttons-list settings-actions'>
                    <Button
                      type='primary'
                      disabled={!isValid}
                      onClick={() => handleShowWarningPrompt(values.amount)}
                    >
                      {submitText}
                    </Button>
                  </div>
              </>
          }  
          </>
        )}
      </form>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    is_authenticated: state.authentication.is_authenticated,
    user_role: state.authentication.user_role,
    get_settings_loading: state.configurations.get_settings_loading,
    get_settings: state.configurations.get_settings,
    request_withdrawal_loading: state.withdrawals.request_withdrawal_loading,
    get_user_balance: state.wallet.get_user_balance
  }
}

export default connect(mapStateToProps, { getSettings, getUserSettingsRequest })(WithdrawForm)
