import React, {ReactNode} from 'react'
import './Home.scss';

type Props = { children: ReactNode; }

const HomeLayout = ({ children }: Props) => (
  <div className='home-layout'>
    {children}
  </div>
)

export default HomeLayout