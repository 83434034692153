import axios from '../../utils/axios'
import { setAlert } from '../alert/actions'
import { Dispatch } from 'redux'
import {
  PURCHASE_MEMBERSHIP,
  PurchaseMembership,
  PURCHASE_MEMBERSHIP_SUCCESS,
  PurchaseMembershipSuccess,
  PURCHASE_MEMBERSHIP_FAILED,
  PurchaseMembershipFailed,
  ADMIN_ASSIGN_MEMBERSHIP,
  AdminAssignMembership,
  ADMIN_ASSIGN_MEMBERSHIP_SUCCESS,
  AdminAssignMembershipSuccess,
  ADMIN_ASSIGN_MEMBERSHIP_FAILED,
  AdminAssignMembershipFailed,
  ADMIN_REVOKE_MEMBERSHIP,
  AdminRevokeMembership,
  ADMIN_REVOKE_MEMBERSHIP_SUCCESS,
  AdminRevokeMembershipSuccess,
  ADMIN_REVOKE_MEMBERSHIP_FAILED,
  AdminRevokeMembershipFailed
} from './constants'

// Purchase Membership
export const purchaseMembership =
  () =>
  async (
    dispatch: Dispatch<
      | PurchaseMembership
      | PurchaseMembershipSuccess
      | PurchaseMembershipFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: PURCHASE_MEMBERSHIP })
      const res = await axios.post(`/membership/purchase`)
      dispatch({
        type: PURCHASE_MEMBERSHIP_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: PURCHASE_MEMBERSHIP_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: PURCHASE_MEMBERSHIP_FAILED,
          payload: err.message
        })
      }
    }
  }

// Admin Assign Membership
export const adminAssignMembership =
  (id: string) =>
  async (
    dispatch: Dispatch<
      | AdminAssignMembership
      | AdminAssignMembershipSuccess
      | AdminAssignMembershipFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: ADMIN_ASSIGN_MEMBERSHIP })
      const res = await axios.post(`/membership/assign/${id}`)
      dispatch({
        type: ADMIN_ASSIGN_MEMBERSHIP_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: ADMIN_ASSIGN_MEMBERSHIP_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: ADMIN_ASSIGN_MEMBERSHIP_FAILED,
          payload: err.message
        })
      }
    }
  }

// Admin Assign Membership
export const adminRevokeMembership =
  (id: string) =>
  async (
    dispatch: Dispatch<
      | AdminRevokeMembership
      | AdminRevokeMembershipSuccess
      | AdminRevokeMembershipFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: ADMIN_REVOKE_MEMBERSHIP })
      const res = await axios.post(`/membership/revoke/${id}`)
      dispatch({
        type: ADMIN_REVOKE_MEMBERSHIP_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: ADMIN_REVOKE_MEMBERSHIP_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: ADMIN_REVOKE_MEMBERSHIP_FAILED,
          payload: err.message
        })
      }
    }
  }
