import {
  WithdrawalActionConstants,
  REQUEST_WITHDRAWAL,
  REQUEST_WITHDRAWAL_SUCCESS,
  REQUEST_WITHDRAWAL_FAILED,
  GET_ALL_WITHDRAWALS,
  GET_ALL_WITHDRAWALS_SUCCESS,
  GET_ALL_WITHDRAWALS_FAILED,
  GET_WITHDRAWALS_FOR_A_USER,
  GET_WITHDRAWALS_FOR_A_USER_SUCCESS,
  GET_WITHDRAWALS_FOR_A_USER_FAILED,
  GET_ALL_PAYOUTS,
  GET_ALL_PAYOUTS_SUCCESS,
  GET_ALL_PAYOUTS_FAILED,
  GET_ALL_REFERRALS,
  GET_ALL_REFERRALS_SUCCESS,
  GET_ALL_REFERRALS_FAILED,
  REQUEST_OTP,
  REQUEST_OTP_SUCCESS,
  REQUEST_OTP_FAILED,
  AUTHORIZE_WITHDRAWAL,
  AUTHORIZE_WITHDRAWAL_SUCCESS,
  AUTHORIZE_WITHDRAWAL_FAILED,
  APPROVE_WITHDRAWAL,
  APPROVE_WITHDRAWAL_SUCCESS,
  APPROVE_WITHDRAWAL_FAILED,
  DECLINE_WITHDRAWAL,
  DECLINE_WITHDRAWAL_SUCCESS,
  DECLINE_WITHDRAWAL_FAILED
} from './constants'

import { IWithdrawals } from '../../interfaces/withdrawals'
import { DEFAULT_WITHDRAWALS } from './withdrawals'

import { getAllPayouts, getAllWithdrawals, getAllReferrals } from './utility'

const initialState: IWithdrawals = DEFAULT_WITHDRAWALS

export const withdrawalReducer = (
  state: IWithdrawals = initialState,
  action: WithdrawalActionConstants
) => {
  switch (action.type) {
    case REQUEST_WITHDRAWAL:
      return { ...state, request_withdrawal_loading: true }
    case REQUEST_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        request_withdrawal_loading: false,
        request_withdrawal: action.payload.data
      }
    case REQUEST_WITHDRAWAL_FAILED:
      return {
        ...state,
        request_withdrawal_loading: false,
        error: { request_withdrawal_error: action.payload }
      }

    case GET_ALL_WITHDRAWALS:
      return { ...state, get_all_withdrawals_loading: true }
    case GET_ALL_WITHDRAWALS_SUCCESS:
      return {
        ...state,
        get_all_withdrawals_loading: false,
        get_all_withdrawals: getAllWithdrawals(action.payload.data.data)
      }
    case GET_ALL_WITHDRAWALS_FAILED:
      return {
        ...state,
        get_all_withdrawals_loading: false,
        error: { get_all_withdrawals_error: action.payload }
      }

    case GET_WITHDRAWALS_FOR_A_USER:
      return { ...state, get_withdrawals_for_a_user_loading: true }
    case GET_WITHDRAWALS_FOR_A_USER_SUCCESS:
      return {
        ...state,
        get_withdrawals_for_a_user_loading: false,
        get_withdrawals_for_a_user: action.payload.data
      }
    case GET_WITHDRAWALS_FOR_A_USER_FAILED:
      return {
        ...state,
        get_withdrawals_for_a_user_loading: false,
        error: { get_withdrawals_for_a_user_error: action.payload }
      }

    case GET_ALL_PAYOUTS:
      return { ...state, get_all_payouts_loading: true }
    case GET_ALL_PAYOUTS_SUCCESS:
      return {
        ...state,
        get_all_payouts_loading: false,
        get_all_payouts: getAllPayouts(action.payload.data.data),
        get_all_payouts_pagination: action.payload.data.pagination
      }
    case GET_ALL_PAYOUTS_FAILED:
      return {
        ...state,
        get_all_payouts_loading: false,
        error: { get_all_payouts_error: action.payload }
      }

    case GET_ALL_REFERRALS:
      return { ...state, get_all_referrals_loading: true }
    case GET_ALL_REFERRALS_SUCCESS:
      return {
        ...state,
        get_all_referrals_loading: false,
        get_all_referrals: getAllReferrals(action.payload.data.data)
      }
    case GET_ALL_REFERRALS_FAILED:
      return {
        ...state,
        get_all_referrals_loading: false,
        error: { get_all_referrals_error: action.payload }
      }

    case REQUEST_OTP:
      return { ...state, request_otp_loading: true }
    case REQUEST_OTP_SUCCESS:
      return {
        ...state,
        request_otp_loading: false,
        request_otp: action.payload.data
      }
    case REQUEST_OTP_FAILED:
      return {
        ...state,
        request_otp_loading: false,
        error: { request_otp_error: action.payload }
      }

    case AUTHORIZE_WITHDRAWAL:
      return { ...state, authorize_withdrawal_loading: true }
    case AUTHORIZE_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        authorize_withdrawal: action.payload.data,
        authorize_withdrawal_loading: false
      }
    case AUTHORIZE_WITHDRAWAL_FAILED:
      return {
        ...state,
        authorize_withdrawal_loading: false,
        error: { authorize_withdrawal_error: action.payload }
      }

    case APPROVE_WITHDRAWAL:
      return { ...state, approve_withdrawal_loading: true }
    case APPROVE_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        approve_withdrawal_loading: false,
        approve_withdrawal: action.payload.data
      }
    case APPROVE_WITHDRAWAL_FAILED:
      return {
        ...state,
        approve_withdrawal_loading: false,
        error: { approve_withdrawal_error: action.payload }
      }

    case DECLINE_WITHDRAWAL:
      return { ...state, decline_withdrawal_loading: true }
    case DECLINE_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        decline_withdrawal: action.payload.data,
        decline_withdrawal_loading: false
      }
    case DECLINE_WITHDRAWAL_FAILED:
      return {
        ...state,
        decline_withdrawal_loading: false,
        error: { decline_withdrawal_error: action.payload }
      }

    default:
      return state
  }
}
