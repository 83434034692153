import axios from '../../utils/axios'
import { setAlert } from '../alert/actions'
import { Dispatch } from 'redux'
import {
  GET_BANK_LIST,
  GetBankList,
  GET_BANK_LIST_SUCCESS,
  GetBankListSuccess,
  GET_BANK_LIST_FAILED,
  GetBankListFailed,
  VERIFY_ACCOUNT_NUMBER,
  VerifyAccountNumber,
  VERIFY_ACCOUNT_NUMBER_SUCCESS,
  VerifyAccountNumberSuccess,
  VERIFY_ACCOUNT_NUMBER_FAILED,
  VerifyAccountNumberFailed
} from './constants'

// Get Bank List
export const getBankList =
  () =>
  async (
    dispatch: Dispatch<
      GetBankList | GetBankListSuccess | GetBankListFailed | any
    >
  ) => {
    try {
      dispatch({ type: GET_BANK_LIST })
      const res = await axios.get(`/bank/list`)
      dispatch({
        type: GET_BANK_LIST_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_BANK_LIST_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_BANK_LIST_FAILED,
          payload: err.message
        })
      }
    }
  }

// Verify Account Number
export const verifyAccountNumber =
  (account_number: string, bank_code: string) =>
  async (
    dispatch: Dispatch<
      | VerifyAccountNumber
      | VerifyAccountNumberSuccess
      | VerifyAccountNumberFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: VERIFY_ACCOUNT_NUMBER })
      const res = await axios.get(
        `/bank/verify?account_number=${account_number}&bank_code=${bank_code}`
      )

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: VERIFY_ACCOUNT_NUMBER_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: VERIFY_ACCOUNT_NUMBER_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: VERIFY_ACCOUNT_NUMBER_FAILED,
          payload: err.message
        })
      }
    }
  }
