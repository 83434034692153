import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import VerticalLayout from '../layout/vertical/Vertical'
import HomeLayout from '../layout/Home/Home'
import { defaultRoutes, sessionRoutes } from './index'
import { useHideLoader } from '../hooks/useHideLoader'
const NotFound = lazy(() => import('../pages/sessions/404'))
const Home = lazy(() => import('../pages/home/home'))

const loading = () => <div></div>

const Routes = ({ routes, layout = '' }: any) => {
  return (
    <Suspense fallback={loading()}>
      <Switch>
        {routes.map((route: any, index: any) => (
          <route.route
            key={index}
            path={
              layout.length > 0
                ? `/${layout}/${route.path}`
                : `/${route.path}\``
            }
            // roles={route.roles}
            exact={route.exact}
            component={route.component}
          ></route.route>
        ))}
        <Route component={NotFound}></Route>
      </Switch>
    </Suspense>
  )
}

const DefaultRoutes = ({ layout }: any) => (<Routes routes={defaultRoutes} layout={layout} />)
const SessionRoutes = () => <Routes routes={sessionRoutes} layout='auth' />

const App = () => {
  useHideLoader()

  return (
    <Suspense fallback={loading()}>
      <Switch>
        <Route path='/' exact>
          <HomeLayout>
          <Home />
          </HomeLayout>
        </Route>

        <Route path='/auth'>
          <SessionRoutes />
        </Route>

        <Route path='/app'>
          <VerticalLayout>
            <DefaultRoutes layout='app' />
          </VerticalLayout>
        </Route>

        <Route path='*'>
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    user_role: state.authentication.user_role,
    is_authenticated: state.authentication.is_authenticated
  }
}

export default connect(mapStateToProps, null)(App)
