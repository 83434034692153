import { v4 as uuid } from 'uuid'
import { Dispatch } from 'redux'
import {
  SET_ALERT,
  SetAlertAction,
  REMOVE_ALERT,
  RemoveAlertAction,
  CLOSE_ALERT,
  CloseAlertAction
} from './constants'

export const setAlert =
  (msg: string, alertType: string, timeOut = 2500) =>
  (dispatch: Dispatch<SetAlertAction | RemoveAlertAction>) => {
    const id: string = uuid()

    dispatch({
      type: SET_ALERT,
      payload: { msg, alertType, id, timeOut }
    })

    setTimeout(() => dispatch(removeAlert(id)), timeOut)
  }

export const removeAlert = (id: string): RemoveAlertAction => ({
  type: REMOVE_ALERT,
  payload: id
})

export const closeAlert = (id: string): CloseAlertAction => ({
  type: CLOSE_ALERT,
  payload: id
})
