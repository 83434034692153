import axios from './axios'

const setAuthToken = (token: any) => {
  if (token) {
    const expirationDate: any = localStorage.getItem('the_ceo_expiration_date')
    const todaysDate: any = Date.now()
    if (todaysDate >= expirationDate) {
      // console.log(todaysDate, expirationDate)
      localStorage.removeItem('the_ceo_expiration_date')
    } else {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      localStorage.setItem('the_ceo_token', token)
    }
  } else {
    delete axios.defaults.headers.common['Authorization']
    localStorage.removeItem('the_ceo_token')
  }
}

export default setAuthToken
