import {
  ConfigurationsActionConstants,
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILED,
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILED,
  START_COUNTER,
  START_COUNTER_SUCCESS,
  START_COUNTER_FAILED,
  GET_COUNT,
  GET_COUNT_SUCCESS,
  GET_COUNT_FAILED,
  RESET_COUNTER,
  RESET_COUNTER_SUCCESS,
  RESET_COUNTER_FAILED
} from './constants'

import { IConfigurations } from '../../interfaces/configurations'
import { DEFAULT_CONFIGURATIONS } from './configurations'

const initialState: IConfigurations = DEFAULT_CONFIGURATIONS

export const configurationsReducer = (
  state: IConfigurations = initialState,
  action: ConfigurationsActionConstants
) => {
  switch (action.type) {
    case UPDATE_SETTINGS:
      return { ...state, update_settings_loading: true }
    case UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        update_settings_loading: false,
        update_settings: action.payload.data
      }
    case UPDATE_SETTINGS_FAILED:
      return {
        ...state,
        update_settings: false,
        update_settings_loading: false,
        error: { update_settings_error: action.payload }
      }

    case GET_SETTINGS:
      return { ...state, get_settings_loading: true }
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        get_settings_loading: false,
        get_settings: action.payload.data.data
      }
    case GET_SETTINGS_FAILED:
      return {
        ...state,
        get_settings_loading: false,
        error: { get_settings_error: action.payload }
      }

    case START_COUNTER:
      return { ...state, start_counter_loading: true }
    case START_COUNTER_SUCCESS:
      return {
        ...state,
        start_counter_loading: false,
        start_counter: action.payload.data
      }
    case START_COUNTER_FAILED:
      return {
        ...state,
        start_counter_loading: false,
        error: { start_counter_error: action.payload }
      }

    case GET_COUNT:
      return { ...state, get_count_loading: true }
    case GET_COUNT_SUCCESS:
      return {
        ...state,
        get_count_loading: false,
        get_count: action.payload.data
      }
    case GET_COUNT_FAILED:
      return {
        ...state,
        get_count_loading: false,
        error: { get_count_error: action.payload }
      }

    case RESET_COUNTER:
      return { ...state, reset_counter_loading: true }
    case RESET_COUNTER_SUCCESS:
      return {
        ...state,
        reset_counter: action.payload.data,
        reset_counter_loading: false
      }
    case RESET_COUNTER_FAILED:
      return {
        ...state,
        reset_counter_loading: false,
        error: { reset_counter_error: action.payload }
      }

    default:
      return state
  }
}
