export const DEFAULT_USERS = {
  logged_in_user: null,
  logged_in_user_loading: false,
  get_user: null,
  get_user_loading: false,
  get_all_users: null,
  get_all_users_pagination: null,
  get_all_users_loading: false,
  get_all_invitees: null,
  get_all_invitees_loading: false,
  update_user_profile: null,
  update_user_profile_loading: false,
  update_user_bvn_details: null,
  update_user_bvn_details_loading: false,
  delete_user: null,
  delete_user_loading: false,
  upload_avatar: null,
  upload_avatar_loading: false,
  block_user: null,
  block_user_loading: false,
  search_get_user: null,
  search_get_user_loading: false,
  search_user: null,
  search_user_loading: false,
  update_bank_details: null,
  update_bank_details_loading: false,
  change_password: null,
  change_password_loading: false,
  error: {
    logged_in_user_error: null,
    get_user_error: null,
    get_all_users_error: null,
    update_user_profile_error: null,
    update_user_bvn_details_error: null,
    delete_user_error: null,
    upload_avatar_error: null,
    block_user_error: null,
    search_get_user_error: null,
    search_user_error: null,
    update_bank_details_error: null,
    change_password_error: null,
    get_all_invitees_error: null,
  }
}
