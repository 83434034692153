import {
  typeGetAllTransactionsSuccess,
  typGetAllTransactionsForAUserSuccess
} from '../../interfaces/transactions'

export const GET_ALL_TRANSACTIONS = 'GET_ALL_TRANSACTIONS'
export const GET_ALL_TRANSACTIONS_SUCCESS = 'GET_ALL_TRANSACTIONS_SUCCESS'
export const GET_ALL_TRANSACTIONS_FAILED = 'GET_ALL_TRANSACTIONS_FAILED'

export const GET_ALL_TRANSACTIONS_FOR_A_USER = 'GET_ALL_TRANSACTIONS_FOR_A_USER'
export const GET_ALL_TRANSACTIONS_FOR_A_USER_SUCCESS =
  'GET_ALL_TRANSACTIONS_FOR_A_USER_SUCCESS'
export const GET_ALL_TRANSACTIONS_FOR_A_USER_FAILED =
  'GET_ALL_TRANSACTIONS_FOR_A_USER_FAILED'

export interface GetAllTransactions {
  type: typeof GET_ALL_TRANSACTIONS
}
export interface GetAllTransactionsSuccess {
  type: typeof GET_ALL_TRANSACTIONS_SUCCESS
  payload: typeGetAllTransactionsSuccess
}
export interface GetAllTransactionsFailed {
  type: typeof GET_ALL_TRANSACTIONS_FAILED
  payload: any
}
export interface GetAllTransactionsForAUser {
  type: typeof GET_ALL_TRANSACTIONS_FOR_A_USER
}
export interface GetAllTransactionsForAUserSuccess {
  type: typeof GET_ALL_TRANSACTIONS_FOR_A_USER_SUCCESS
  payload: typGetAllTransactionsForAUserSuccess
}
export interface GetAllTransactionsForAUserFailed {
  type: typeof GET_ALL_TRANSACTIONS_FOR_A_USER_FAILED
  payload: any
}

export type TransactionsActionConstants =
  | GetAllTransactions
  | GetAllTransactionsSuccess
  | GetAllTransactionsFailed
  | GetAllTransactionsForAUser
  | GetAllTransactionsForAUserSuccess
  | GetAllTransactionsForAUserFailed
