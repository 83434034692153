import axios from 'axios'
import { store } from '../redux/store'
import { initiateLogoutUser } from '../redux/auth/actions'

// export const BASE_URL = 'https://the-ceos-a3ea7655ff86.herokuapp.com/api/v1'
export const BASE_URL = 'https://api.the-ceos.com/api/v1'

export const axiosWithoutHeaders = axios;

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

const storeConfig: any = () => store()

/**
 intercept any error responses from the api and check if the token is no longer valid.
 ie. Token has expired.
 logout the user if the token has expired.
**/

instance.interceptors.response.use(
  (res: any) => res,
  (err: any) => {
    if (err.response) {
      if (
        err.response.data.error === 'Unauthorized access' ||
        err.response.data.error === 'Unauthorized'
      ) {
        storeConfig.dispatch(initiateLogoutUser())
      }
    }
    return Promise.reject(err)
  }
)

export default instance