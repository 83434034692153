import axios from '../../utils/axios'
import { setAlert } from '../alert/actions'
import { Dispatch } from 'redux'
import {
  UPDATE_SETTINGS,
  UpdateSettings,
  UPDATE_SETTINGS_SUCCESS,
  UpdateSettingsSuccess,
  UPDATE_SETTINGS_FAILED,
  UpdateSettingsFailed,
  GET_SETTINGS,
  GetSettings,
  GET_SETTINGS_SUCCESS,
  GetSettingsSuccess,
  GET_SETTINGS_FAILED,
  GetSettingsFailed,
  START_COUNTER,
  StartCounter,
  START_COUNTER_SUCCESS,
  StartCounterSuccess,
  START_COUNTER_FAILED,
  StartCounterFailed,
  GET_COUNT,
  GetCount,
  GET_COUNT_SUCCESS,
  GetCountSuccess,
  GET_COUNT_FAILED,
  GetCountFailed,
  RESET_COUNTER,
  ResetCounter,
  RESET_COUNTER_SUCCESS,
  ResetCounterSuccess,
  RESET_COUNTER_FAILED,
  ResetCounterFailed
} from './constants'

type formDataType = object

// Update Settings
export const updateSettings2 =
  (formData: formDataType) =>
  async (
    dispatch: Dispatch<
      UpdateSettings | UpdateSettingsSuccess | UpdateSettingsFailed | any
    >
  ) => {
    try {
      dispatch({ type: UPDATE_SETTINGS })
      const res = await axios.put(`/settings/update`, formData)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: UPDATE_SETTINGS_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: UPDATE_SETTINGS_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: UPDATE_SETTINGS_FAILED,
          payload: err.message
        })
      }
    }
  }

// Get Settings
export const getSettings =
  () =>
  async (
    dispatch: Dispatch<
      GetSettings | GetSettingsSuccess | GetSettingsFailed | any
    >
  ) => {
    try {
      dispatch({ type: GET_SETTINGS })
      const res = await axios.get(`/settings`)

      dispatch({
        type: GET_SETTINGS_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_SETTINGS_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_SETTINGS_FAILED,
          payload: err.message
        })
      }
    }
  }

// Start Counter
export const startCounter =
  () =>
  async (
    dispatch: Dispatch<
      StartCounter | StartCounterSuccess | StartCounterFailed | any
    >
  ) => {
    try {
      dispatch({ type: START_COUNTER })
      const res = await axios.post(`/counter/start`)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: START_COUNTER_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: START_COUNTER_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: START_COUNTER_FAILED,
          payload: err.message
        })
      }
    }
  }

// Get Count
export const getCount =
  () =>
  async (
    dispatch: Dispatch<GetCount | GetCountSuccess | GetCountFailed | any>
  ) => {
    try {
      dispatch({ type: GET_COUNT })
      const res = await axios.get(`/counter`)

      dispatch({
        type: GET_COUNT_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_COUNT_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_COUNT_FAILED,
          payload: err.message
        })
      }
    }
  }

// Reset Counter
export const resetCounter =
  () =>
  async (
    dispatch: Dispatch<
      ResetCounter | ResetCounterSuccess | ResetCounterFailed | any
    >
  ) => {
    try {
      dispatch({ type: RESET_COUNTER })
      const res = await axios.put(`/counter/reset`)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: RESET_COUNTER_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: RESET_COUNTER_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: RESET_COUNTER_FAILED,
          payload: err.message
        })
      }
    }
  }
