export const AdminNavigation = [
  {
    title: 'DASHBOARD',
    routing: 'dashboard'
  },
  {
    title: 'USERS',
    routing: 'users'
  },
  {
    title: 'INVITEES',
    routing: 'invitees'
  },
  {
    title: 'PAYOUTS',
    routing: 'payouts'
  },
  {
    title: 'SETTINGS',
    routing: 'settings'
  },
  {
    title: 'LEND ME',
    sub: [
      {
        "title": "Dashboard",
        "routing": "lend-me-dashboard"
      },
      {
        "title": "Settings",
        "routing": "lend-me-settings"
      },
      {
        "title": "Quick Loan",
        "routing": "lend-me-admin-loan-requests"
      },
      {
        "title": "Lend Me",
        "routing": "lend-me-admin-lenders"
      }
    ]
  },
  {
    title: 'LOGOUT',
    routing: 'logout'
  }
]

export const UserNavigation = [
  {
    title: 'HOME',
    routing: 'home'
  },

  {
    title: 'ACCOUNT',
    routing: 'account'
  },

  {
    title: 'INVITEES',
    routing: 'my-invitees'
  },

  {
    title: 'LEND ME',
    sub: [
      {
        "title": "Dashboard",
        "routing": "my-lend-me-dashboard"
      },
      {
        "title": "Quick Loan",
        "routing": "my-lend-me-deposit-history"
      },
      {
        "title": "Lend Me",
        "routing": "my-lend-me-loan-history"
      },
      // {
      //   "title": "Request Loan",
      //   "routing": "my-lend-request-loan"
      // }
    ]
  },
  {
    title: 'LOGOUT',
    routing: 'logout-user'
  }
]
