export const DEFAULT_TRANSACTIONS = {
  get_all_transactions: null,
  get_all_transactions_pagination: null,
  get_all_transactions_loading: false,
  get_all_transactions_for_a_user: null,
  get_all_transactions_for_a_user_loading: false,
  get_all_transactions_for_a_user_pagination: null,
  error: {
    get_all_transactions_error: null,
    get_all_transactions_for_a_user_error: null
  }
}
