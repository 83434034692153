import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Input,/* AutoComplete,*/ Form } from 'antd'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getAllUsers, searchUser } from '../../../../../redux/actions'
import { hasErrorFactory } from '../../../../../utils/hasError'
import '../../../search/Search.scss'
import { LoaderOne } from '../../../Loader'

import { useForm } from 'antd/es/form/Form'

interface ITransfer {
  user_id: any
  amount: string
}

// interface IuserSearchData {
//   id?: any
//   name?: any
//   firstname?: any,
//   lastname?: any,
//   username?: any
//   label?: any
//   value?: any
//   phone?: any
//   email?: any
// }

type Props = {
  data?: { value: string; text: string }[]
  onSubmit: (transfer: ITransfer) => void
  onCancel: () => void
  transfer?: ITransfer
  submitText?: string
  get_all_users?: any
  get_all_users_loading?: boolean
  transfer_between_wallets?: any
  transfer_between_wallets_loading?: boolean
  getAllUsers: () => void,
  get_settings_loading: any,
  get_settings: any,
  searchUser: (value: any) => void,
  search_user: any,
  search_user_loading: boolean
}

const { Item } = Form

const defaultSubmitText = 'Transfer'
const emptyTransfer = {
  user_id: null,
  amount: '100'
}

const transferScheme = Yup.object({
  amount: Yup.number()
    .required('This field is required')
    .min(100, `Must be ₦${new Intl.NumberFormat('en-US').format(100)} or more`)
})

const TransferForm = ({
  // data,
  submitText = defaultSubmitText,
  transfer = emptyTransfer,
  onSubmit,
  onCancel,
  // get_all_users,
  get_all_users_loading,
  transfer_between_wallets,
  transfer_between_wallets_loading,
  // getAllUsers,
  get_settings_loading,
  get_settings,
  searchUser,
  search_user,
  search_user_loading,
}: Props) => {
  const [ID, setID] = useState(null)
  // const [searchData, setSearchData] = useState([])
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    touched,
    values,
    errors,
    isValid
  } = useFormik<ITransfer>({
    validationSchema: transferScheme,
    initialValues: transfer,
    onSubmit: (values) => {
      const payload = { user_id: ID.id, amount: values.amount }
      onSubmit(payload)
    }
  })

  const [form] = useForm()

  useEffect(() => {
    let mounted = true
    if (mounted) {
      if (search_user) {
        setID(search_user[0])
      }
    }
    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search_user])

  useEffect(() => {
    let mounted = true
    if (mounted) {
      if (transfer_between_wallets) {
        setID(null)
      }
    }
    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transfer_between_wallets])

  // useEffect(() => {
  //   let mounted = true
  //   if (mounted) {
  //     if (!get_all_users) {
  //       getAllUsers()
  //     }
  //   }
  //   return () => {
  //     setID(null)
  //     mounted = false
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [get_all_users])

  // useEffect(() => {
  //   let mounted = true
  //   if (mounted) {
  //     if (get_all_users) {
  //       let dataArray = get_all_users.map((user: IuserSearchData) => {
  //         let container: IuserSearchData = {}

  //         container.value = `${user.firstname} ${user.lastname} - ${user.phone}`
  //         container.label = `${user.firstname} ${user.lastname}`
  //         container.phone = user.phone
  //         container.email = user.email
  //         container.id = user.id

  //         return container
  //       }).filter(function (v, i, self) {

  //         // It returns the index of the first
  //         // instance of each value
  //         return i == self.indexOf(v);
  //       });
  //       setSearchData(dataArray)
  //     }
  //   }
  //   return () => {
  //     setID(null)
  //     mounted = false
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [get_all_users])

  const hasError = hasErrorFactory(touched, errors)

  const handleCancel = () => {
    resetForm()
    onCancel()
  }

  // const handleAutoSelect = (value: string, option: any) => {
  //   setID(option)
  // }

  // const handleAutoChange  = (value) => {
  //   console.log(value)
  //   searchUser(value)
  // }

  const onFinish = (values: any) => {
    const { confirm, indemnity, ...rest } = values
    const payload = {...rest};
    searchUser(payload.phone);
  }

  const handleSearchAgain = () => setID(null)

  return (
    <>
      {get_all_users_loading ? (
        <LoaderOne />
      ) : (
        <>
          {!ID && (
            <>
              {
                get_settings_loading ? 
                  <div className='d-flex flex-column align-items-center justify-content-center'>
                    <h6>
                      Loading wallet transfer charge, Please wait a moment.
                    </h6>
                  </div>
                   : 
                  <>
                      <div className='d-flex flex-column align-items-center justify-content-center'>
                        <h6>
                         The receipient will be charged{' '}
                          {`${new Intl.NumberFormat('en-US').format(get_settings?.walletTransferCharge)}%`}{' '}of the amount for this transaction.
                        </h6>
                          {/* <Input
                            placeholder={`Search user by Phone Number`}
                            name='amount'
                            type='number'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            defaultValue={values.amount}
                            className={hasError('amount')}
                          /> */}
                          {/* <AutoComplete
                            filterOption
                            options={searchData}
                            className='app-search'
                            placeholder='Search for a user'
                            onChange={handleAutoChange}
                            onSelect={handleAutoSelect}
                          ></AutoComplete> */}

                        <Form
                          form={form}
                          layout='vertical'
                          onFinish={onFinish}
                        >
                          <div className='mb-2 d-flex justify-content-center'>
                            <span>
                            Enter a valid phone number to search for a user
                            </span>
                          </div>
                          <div className='d-flex'>
                            <Item
                              className='mb-0 mr-3'
                              name='phone'
                              rules={[
                                { required: true, message: 'Please input a phone number to search user!' },
                                {
                                  min: 11,
                                  message: 'Phone number too short'
                                },
                                {
                                  max: 11,
                                  message: 'Phone number too long'
                                }
                              ]}
                            >
                              <Input
                                placeholder='Phone Number'
                                disabled={search_user_loading}
                              />
                            </Item>

                            <Button
                              block={false}
                              type='primary'
                              loading={search_user_loading}
                              disabled={search_user_loading}
                              htmlType='submit'
                              icon={
                                <span
                                  className='icofont-search-2 mr-2'
                                  style={{ fontSize: '1.2rem' }}
                                />
                              }
                            >
                              Search User
                            </Button>
                          </div>
                        </Form>
                      </div>
                  </>
              }
            </>
          )}
          {ID && (
            <>
              <div className='d-flex flex-column align-items-center justify-content-center mb-3'>
                <p>Are you sure you want to make a transfer to</p>
                <p>
                    <strong>{`${ID?.firstname} ${ID?.lastname}`}</strong>
                </p>
                <p>
                  <strong>{ID?.email}</strong>
                </p>
                <p>
                  <strong>{ID?.phone}</strong>
                </p>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='form-group'>
                  <Input
                    placeholder={`Amount Must be ₦${new Intl.NumberFormat(
                      'en-US'
                    ).format(100)} or more`}
                    name='amount'
                    type='number'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    defaultValue={values.amount}
                    className={hasError('amount')}
                  />
                </div>
                <div className='d-flex justify-content-between buttons-list settings-actions'>
                  <Button danger onClick={handleCancel}>
                    Cancel
                  </Button>

                  <Button onClick={handleSearchAgain}>
                    Search User
                  </Button>

                  <Button
                    disabled={!isValid}
                    loading={transfer_between_wallets_loading}
                    type='primary'
                    htmlType='submit'
                  >
                    {submitText}
                  </Button>
                </div>
              </form>
            </>
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  get_settings_loading: state.configurations.get_settings_loading,
  get_settings: state.configurations.get_settings,
  get_all_users: state.users.get_all_users,
  get_all_users_loading: state.users.get_all_users_loading,
  search_user: state.users.search_user,
  search_user_loading: state.users.search_user_loading,
  transfer_between_wallets:
    state.wallet.transfer_between_wallets,
  transfer_between_wallets_loading:
    state.wallet.transfer_between_wallets_loading
})

export default connect(mapStateToProps, { getAllUsers, searchUser })(TransferForm)
