export const getAllTransactions = (transactions: []) => {
  if (transactions.length) {
    const flattenedTransactions = transactions.map(
      (transaction: { user: object }) => {
        const { user, ...rest } = transaction
        return {
          ...rest,
          ...user
        }
      }
    )

    return flattenedTransactions
  }

  return []
}

export const getAllTransactionsForAUser = (transactions: []) => {
  if (transactions.length) {
    const flattenedTransactions = transactions.map(
      (transaction: { user: object }) => {
        const { user, ...rest } = transaction
        return {
          ...rest,
          user
        }
      }
    )

    return flattenedTransactions
  }

  return []
}
