import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'antd'
import { TransferForm, WithdrawForm, ContributeForm } from './Forms'
import {
  purchaseContribution,
  requestWithdrawal,
  transferBetweenWallet,
  getAllUsers,
  getSettings,
  getUserSettingsRequest,
} from '../../../redux/actions'
import './Actions.scss'
import { ToastAlert } from '../../../pages/components'

const buttonStyle = {
  borderRadius: '10px 0px 10px 10px'
}

type Props = {
  is_authenticated: boolean
  purchaseContribution?: () => void
  purchase_contribution: object
  purchase_contribution_error: string
  requestWithdrawal: (withdraw: object) => void
  request_withdrawal: object
  request_withdrawal_error: string
  transferBetweenWallet: (transfer: object) => void
  transfer_between_wallets: object
  transfer_between_wallets_error: string
  getAllUsers: () => void
  user_role: string
  getSettings: () => void
  get_settings: {
    membershipFee: number | null
    contributionPrice: number | null
    payoutPrice: number | null
    user_payout_count: number | null
    minimum_wallet_balance: number | null
    referralPercentage: any
    isReferralActive: boolean
    withdrawal_charge: number | null
    referral_payout_count: number | null
  }
  get_settings_loading: boolean
  getUserSettingsRequest: any
}

const Actions = ({
  purchaseContribution,
  purchase_contribution,
  purchase_contribution_error,
  requestWithdrawal,
  request_withdrawal,
  request_withdrawal_error,
  transferBetweenWallet,
  transfer_between_wallets,
  transfer_between_wallets_error,
  is_authenticated,
  getAllUsers,
  user_role,
  getSettings,
  get_settings,
  get_settings_loading,
  getUserSettingsRequest,
}: Props) => {
  useEffect(() => {
    let mounted = true
    if (mounted) {
      if (is_authenticated && user_role === 'ADMIN'){
        getAllUsers()
        getSettings()
      }

      if (is_authenticated && user_role === 'USER') {
        getUserSettingsRequest()
      }
    }
    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_authenticated, user_role])

  useEffect(() => {
    let mounted = true
    if (mounted) {
      purchase_contribution && closeContributeModal()
      request_withdrawal && closeWithdrawModal()
      transfer_between_wallets && closeTransferModal()
    }
    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchase_contribution, request_withdrawal, transfer_between_wallets])

  const [transferFormVisible, setTransferFormVisible] = useState(false)
  const [withdrawFormVisible, setWithdrawFormVisible] = useState(false)
  const [contributeFormVisible, setContributeFormVisible] = useState(false)

  const handleTransferFormClick = () =>
    setTransferFormVisible(!transferFormVisible)
  const handleWithdrawFormClick = () =>
    setWithdrawFormVisible(!withdrawFormVisible)
  // const handleContributeFormClick = () =>
  //   setContributeFormVisible(!contributeFormVisible)

  const closeTransferModal = () => setTransferFormVisible(false)
  const closeWithdrawModal = () => setWithdrawFormVisible(false)
  const closeContributeModal = () => setContributeFormVisible(false)

  const handleTransfer = (transfer: object) => transferBetweenWallet(transfer)
  const handleWithdraw = (withdraw: object) => {
    requestWithdrawal(withdraw)
  }
  const handleContribute = () => purchaseContribution()

  return (
    <div className='actions custom-actions p-3'>
      <div className='row align-items-center'>
        {((user_role === 'USER') || user_role === 'ADMIN') && (
          <div
            className={
              user_role === 'USER'
                ? `col-12 col-sm-4 col-md-4 mb-2`
                : `col-12 col-sm-6 col-md-6 mb-2`
            }
          >
            <Button
              style={buttonStyle}
              block={false}
              type='primary'
              onClick={handleTransferFormClick}
            >
              Transfer
            </Button>
          </div>
        )}

        {/* {user_role === 'USER' && (
          <div className={`col-12 col-sm-4 col-md-4 ml-3 mb-2`}>
            <Button
              style={buttonStyle}
              block={false}
              type='primary'
              onClick={handleContributeFormClick}
            >
              Contribute
            </Button>
          </div>
        )} */}

        {((user_role === 'USER') || user_role === 'ADMIN') && (
          <div
            className={
              user_role === 'USER'
                ? `col-12 col-sm-4 col-md-4 ml-3  mb-2`
                : `col-12 col-sm-6 col-md-6 ml-3  mb-2`
            }
          >
            <Button
              style={buttonStyle}
              block={false}
              type='primary'
              onClick={handleWithdrawFormClick}
            >
              Withdraw
            </Button>
          </div>
        )}
      </div>
      <Modal
        visible={transferFormVisible}
        footer={null}
        closable={true}
        maskClosable={false}
        onCancel={closeTransferModal}
        destroyOnClose
        title={
          <div className='d-flex justify-content-center'>
            <h4 className='title'>Transfer</h4>
          </div>
        }
      >
        <TransferForm onCancel={closeTransferModal} onSubmit={handleTransfer} />
      </Modal>
      <Modal
        visible={withdrawFormVisible}
        footer={null}
        closable={true}
        maskClosable={false}
        onCancel={closeWithdrawModal}
        destroyOnClose
        title={
          <div className='d-flex justify-content-center'>
            <h4 className='title'>Withdraw</h4>
          </div>
        }
      >
        <WithdrawForm
          withdrawal_charge={get_settings && get_settings.withdrawal_charge}
          onCancel={closeWithdrawModal}
          onSubmit={handleWithdraw}
        />
      </Modal>
      <Modal
        visible={contributeFormVisible}
        footer={null}
        closable={true}
        maskClosable={false}
        onCancel={closeContributeModal}
        destroyOnClose
        title={
          <div className='d-flex justify-content-center'>
            <h4 className='title'>Contribute</h4>
          </div>
        }
      >
        <ContributeForm
          onCancel={closeContributeModal}
          onSubmit={handleContribute}
        />
      </Modal>
      {(purchase_contribution ||
        request_withdrawal ||
        transfer_between_wallets ||
        purchase_contribution_error ||
        request_withdrawal_error ||
        transfer_between_wallets_error) && <ToastAlert />}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    is_authenticated: state.authentication.is_authenticated,
    user_role: state.authentication.user_role,
    user_loading: state.authentication.user_loading,
    request_withdrawal: state.withdrawals.request_withdrawal,
    purchase_contribution: state.contributions.purchase_contribution,
    transfer_between_wallets: state.wallet.transfer_between_wallets,
    transfer_between_wallets_error:
      state.wallet.error.transfer_between_wallets_error,
    request_withdrawal_error: state.withdrawals.error.request_withdrawal_error,
    purchase_contribution_error:
      state.contributions.error.purchase_contribution_error,
    get_settings: state.configurations.get_settings,
    get_settings_loading: state.configurations.get_settings_loading
  }
}

export default connect(mapStateToProps, {
  purchaseContribution,
  requestWithdrawal,
  transferBetweenWallet,
  getAllUsers,
  getSettings,
  getUserSettingsRequest
})(Actions)
