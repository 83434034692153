import {
  LentMeActionConstants,
  GET_USER_HOME_DATA,
  GET_USER_HOME_DATA_SUCCESS,
  GET_USER_HOME_DATA_FAILED,
  GET_USER_SETTINGS,
  GET_USER_SETTINGS_SUCCESS,
  GET_USER_SETTINGS_FAILED,
  FUND_USER_LEND_ME,
  FUND_USER_LEND_ME_SUCCESS,
  FUND_USER_LEND_ME_FAILED,
  GET_USER_LEND_ME_DEPOSIT_HISTORY,
  GET_USER_LEND_ME_DEPOSIT_HISTORY_SUCCESS,
  GET_USER_LEND_ME_DEPOSIT_HISTORY_FAILED,
  REQUEST_USER_LOAN,
  REQUEST_USER_LOAN_SUCCESS,
  REQUEST_USER_LOAN_FAILED,
  GET_USER_LOAN_HISTORY,
  GET_USER_LOAN_HISTORY_SUCCESS,
  GET_USER_LOAN_HISTORY_FAILED,
  GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY,
  GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY_SUCCESS,
  GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY_FAILED,
  WITHDRAW_FROM_LEND_ME_WALLET,
  WITHDRAW_FROM_LEND_ME_WALLET_SUCCESS,
  WITHDRAW_FROM_LEND_ME_WALLET_FAILED,

  GET_ADMIN_HOME_DATA,
  GET_ADMIN_HOME_DATA_SUCCESS,
  GET_ADMIN_HOME_DATA_FAILED,
  GET_ADMIN_SETTINGS,
  GET_ADMIN_SETTINGS_SUCCESS,
  GET_ADMIN_SETTINGS_FAILED,
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILED,
  GET_LOAN_REQUESTS,
  GET_LOAN_REQUESTS_SUCCESS,
  GET_LOAN_REQUESTS_FAILED,
  UPDATE_LOAN_REQUEST_STATUS,
  UPDATE_LOAN_REQUEST_STATUS_SUCCESS,
  UPDATE_LOAN_REQUEST_STATUS_FAILED,
  UPDATE_LOAN_PAYMENT,
  UPDATE_LOAN_PAYMENT_SUCCESS,
  UPDATE_LOAN_PAYMENT_FAILED,
  GET_LENDERS,
  GET_LENDERS_SUCCESS,
  GET_LENDERS_FAILED,
  FILE_UPLOAD,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILED,
} from './constants'

import { ILendMe } from '../../interfaces/lend-me'
import { DEFAULT_LEND_ME } from './lend-me'

import {  getAllLendMeTransactions } from './utility'

const initialState: ILendMe = DEFAULT_LEND_ME

export const lendMeReducer = (
  state: ILendMe = initialState,
  action: LentMeActionConstants
) => {
  switch (action.type) {
    case GET_USER_HOME_DATA:
      return { ...state, get_user_home_data_loading: true }
    case GET_USER_HOME_DATA_SUCCESS:
      return {
        ...state,
        get_user_home_data_loading: false,
        get_user_home_data: action.payload.data.data
      }
    case GET_USER_HOME_DATA_FAILED:
      return {
        ...state,
        get_user_home_data_loading: false,
        error: { get_user_home_data_error: action.payload }
      }

    case GET_USER_SETTINGS:
      return { ...state, get_user_settings_loading: true }
    case GET_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        get_user_settings_loading: false,
        get_user_settings: action.payload.data.data
      }
    case GET_USER_SETTINGS_FAILED:
      return {
        ...state,
        get_user_settings_loading: false,
        error: { get_user_settings_error: action.payload }
      }

    case FUND_USER_LEND_ME:
      return { ...state, fund_user_lend_me_loading: true }
    case FUND_USER_LEND_ME_SUCCESS:
      return {
        ...state,
       fund_user_lend_me_loading: false,
       fund_user_lend_me: action.payload.data
      }
    case FUND_USER_LEND_ME_FAILED:
      return {
        ...state,
        fund_user_lend_me_loading: false,
        error: { fund_user_lend_me_error: action.payload }
      }

    case GET_USER_LEND_ME_DEPOSIT_HISTORY:
      return { ...state, get_user_lend_me_deposit_history_loading: true }
    case GET_USER_LEND_ME_DEPOSIT_HISTORY_SUCCESS:
      return {
        ...state,
        get_user_lend_me_deposit_history_loading: false,
        get_user_lend_me_deposit_history: action.payload.data,
        get_user_lend_me_deposit_history_pagination: action.payload.data.pagination
      }
    case GET_USER_LEND_ME_DEPOSIT_HISTORY_FAILED:
      return {
        ...state,
        get_user_lend_me_deposit_history_loading: false,
        error: { get_user_lend_me_deposit_history_error: action.payload }
      }

    case REQUEST_USER_LOAN:
      return { ...state, request_user_loan_loading: true }
    case REQUEST_USER_LOAN_SUCCESS:
      return {
        ...state,
        request_user_loan_loading: false,
        request_user_loan: action.payload.data.data
      }
    case REQUEST_USER_LOAN_FAILED:
      return {
        ...state,
        request_user_loan_loading: false,
        error: { request_user_loan_error: action.payload }
      }

    case GET_USER_LOAN_HISTORY:
      return { ...state, get_user_loan_history_loading: true }
    case GET_USER_LOAN_HISTORY_SUCCESS:
      return {
        ...state,
        get_user_loan_history_loading: false,
        get_user_loan_history_pagination: action.payload.data.pagination,
        get_user_loan_history: action.payload.data
      }
    case GET_USER_LOAN_HISTORY_FAILED:
      return {
        ...state,
        get_user_loan_history_loading: false,
        error: { get_user_loan_history_error: action.payload }
      }

    case GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY:
      return { ...state, get_user_lend_me_balance_transaction_history_loading: true }
    case GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        get_user_lend_me_balance_transaction_history: getAllLendMeTransactions(action.payload.data.data),
        get_user_lend_me_balance_transaction_history_pagination: action.payload.data.pagination,
        get_user_lend_me_balance_transaction_history_loading: false
      }
    case GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY_FAILED:
      return {
        ...state,
        get_user_lend_me_balance_transaction_history_loading: false,
        error: { get_user_lend_me_balance_transaction_history_error: action.payload }
      }

    case WITHDRAW_FROM_LEND_ME_WALLET:
      return { ...state, withdraw_from_lend_me_wallet_loading: true }
    case WITHDRAW_FROM_LEND_ME_WALLET_SUCCESS:
      return {
        ...state,
        withdraw_from_lend_me_wallet: action.payload.data.data,
        withdraw_from_lend_me_wallet_loading: false
      }
    case WITHDRAW_FROM_LEND_ME_WALLET_FAILED:
      return {
        ...state,
        withdraw_from_lend_me_wallet_loading: false,
        error: { withdraw_from_lend_me_wallet_error: action.payload }
      }

    case GET_ADMIN_HOME_DATA:
      return { ...state, get_admin_home_data_loading: true }
    case GET_ADMIN_HOME_DATA_SUCCESS:
      return {
        ...state,
       get_admin_home_data_loading: false,
       get_admin_home_data: action.payload.data.data
      }
    case GET_ADMIN_HOME_DATA_FAILED:
      return {
        ...state,
       get_admin_home_data_loading: false,
        error: {get_admin_home_data_error: action.payload }
      }

   case GET_ADMIN_SETTINGS:
      return { ...state, get_admin_settings_loading: true }
   case GET_ADMIN_SETTINGS_SUCCESS:
      return {
        ...state,
        get_admin_settings: action.payload.data.data,
        get_admin_settings_loading: false
      }
   case GET_ADMIN_SETTINGS_FAILED:
      return {
        ...state,
        get_admin_settings_loading: false,
        error: { get_admin_settings_error: action.payload }
      }

    case UPDATE_SETTINGS:
      return { ...state, update_settings_loading: true }
    case UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        update_settings: action.payload.data,
        update_settings_loading: false
      }
    case UPDATE_SETTINGS_FAILED:
      return {
        ...state,
        update_settings_loading: false,
        error: { update_settings_error: action.payload }
      }

    case GET_LOAN_REQUESTS:
      return { ...state, get_loan_requests_loading: true }
    case GET_LOAN_REQUESTS_SUCCESS:
      return {
        ...state,
        get_loan_requests: action.payload.data.data,
        get_loan_requests_pagination: action.payload.data.pagination,
        get_loan_requests_loading: false
      }
    case GET_LOAN_REQUESTS_FAILED:
      return {
        ...state,
        get_loan_requests_loading: false,
        error: { get_loan_requests_error: action.payload }
      }

    case UPDATE_LOAN_REQUEST_STATUS:
      return { ...state, update_loan_requests_status_loading: true }
    case UPDATE_LOAN_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        update_loan_requests_status: action.payload.data,
        update_loan_requests_status_loading: false
      }
    case UPDATE_LOAN_REQUEST_STATUS_FAILED:
      return {
        ...state,
        update_loan_requests_status_loading: false,
        error: { update_loan_requests_status_error: action.payload }
      }

    case UPDATE_LOAN_PAYMENT:
      return { ...state, update_loan_payment_loading: true }
    case UPDATE_LOAN_PAYMENT_SUCCESS:
      return {
        ...state,
        update_loan_payment: action.payload.data,
        update_loan_payment_loading: false
      }
    case UPDATE_LOAN_PAYMENT_FAILED:
      return {
        ...state,
        update_loan_payment_loading: false,
        error: { update_loan_payment_error: action.payload }
      }


    case GET_LENDERS:
      return { ...state, get_lenders_loading: true }
    case GET_LENDERS_SUCCESS:
      return {
        ...state,
        get_lenders: action.payload.data.data,
        get_lenders_pagination: action.payload.data.pagination,
        get_lenders_loading: false
      }
    case GET_LENDERS_FAILED:
      return {
        ...state,
        get_lenders_loading: false,
        error: { get_lenders_error: action.payload }
      }


    case FILE_UPLOAD:
      return { ...state, file_upload_loading: true }
    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        file_upload: action.payload.data,
        file_upload_loading: false
      }
    case FILE_UPLOAD_FAILED:
      return {
        ...state,
        file_upload_loading: false,
        error: { file_upload_error: action.payload }
      }

    default:
      return state
  }
}
