import {
  typeGetUserHomeDataSuccess,
  typeGetUserSettingsSuccess,
  typeFundUserLendMeSuccess,
  typeGetUserLendMeDepositHistorySuccess,
  typeGetUserLoanHistorySuccess,
  typeRequestUserLoanSuccess,
  typeGetUserLendMeBalanceTransactionHistorySuccess,
  typeWithDrawFromLendMeWalletSuccess,
// ===================================================
  typeGetAdminHomeDataSuccess,
  typeGetAdminSettingsSuccess,
  typeUpdateSettingsSuccess,
  typeGetLoanRequestsSuccess,
  typeUpdateLoanRequestStatusSuccess,
  typeUpdateLoanPaymentSuccess,
  typeGetLendersSuccess,
  typeFileUploadSuccess,

} from '../../interfaces/lend-me'

export const GET_USER_HOME_DATA = 'GET_USER_HOME_DATA'
export const GET_USER_HOME_DATA_SUCCESS = 'GET_USER_HOME_DATA_SUCCESS'
export const GET_USER_HOME_DATA_FAILED = 'GET_USER_HOME_DATA_FAILED'

export const GET_USER_SETTINGS = 'GET_USER_SETTINGS'
export const GET_USER_SETTINGS_SUCCESS = 'GET_USER_SETTINGS_SUCCESS'
export const GET_USER_SETTINGS_FAILED = 'GET_USER_SETTINGS_FAILED'

export const FUND_USER_LEND_ME = 'FUND_USER_LEND_ME'
export const FUND_USER_LEND_ME_SUCCESS = 'FUND_USER_LEND_ME_SUCCESS'
export const FUND_USER_LEND_ME_FAILED = 'FUND_USER_LEND_ME_FAILED'

export const GET_USER_LEND_ME_DEPOSIT_HISTORY = 'GET_USER_LEND_ME_DEPOSIT_HISTORY'
export const GET_USER_LEND_ME_DEPOSIT_HISTORY_SUCCESS = 'GET_USER_LEND_ME_DEPOSIT_HISTORY_SUCCESS'
export const GET_USER_LEND_ME_DEPOSIT_HISTORY_FAILED = 'GET_USER_LEND_ME_DEPOSIT_HISTORY_FAILED'

export const REQUEST_USER_LOAN = 'REQUEST_USER_LOAN'
export const REQUEST_USER_LOAN_SUCCESS = 'REQUEST_USER_LOAN_SUCCESS'
export const REQUEST_USER_LOAN_FAILED = 'REQUEST_USER_LOAN_FAILED'

export const GET_USER_LOAN_HISTORY = 'GET_USER_LOAN_HISTORY'
export const GET_USER_LOAN_HISTORY_SUCCESS = 'GET_USER_LOAN_HISTORY_SUCCESS'
export const GET_USER_LOAN_HISTORY_FAILED = 'GET_USER_LOAN_HISTORY_FAILED'

export const GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY = 'GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY'
export const GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY_SUCCESS = 'GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY_SUCCESS'
export const GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY_FAILED = 'GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY_FAILED'

export const WITHDRAW_FROM_LEND_ME_WALLET = 'WITHDRAW_FROM_LEND_ME_WALLET'
export const WITHDRAW_FROM_LEND_ME_WALLET_SUCCESS = 'WITHDRAW_FROM_LEND_ME_WALLET_SUCCESS'
export const WITHDRAW_FROM_LEND_ME_WALLET_FAILED = 'WITHDRAW_FROM_LEND_ME_WALLET_FAILED'

// =========================================================================================

export const GET_ADMIN_HOME_DATA = 'GET_ADMIN_HOME_DATA'
export const GET_ADMIN_HOME_DATA_SUCCESS = 'GET_ADMIN_HOME_DATA_SUCCESS'
export const GET_ADMIN_HOME_DATA_FAILED = 'GET_ADMIN_HOME_DATA_FAILED'

export const GET_ADMIN_SETTINGS = 'GET_ADMIN_SETTINGS'
export const GET_ADMIN_SETTINGS_SUCCESS = 'GET_ADMIN_SETTINGS_SUCCESS'
export const GET_ADMIN_SETTINGS_FAILED = 'GET_ADMIN_SETTINGS_FAILED'

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS'
export const UPDATE_SETTINGS_FAILED = 'UPDATE_SETTINGS_FAILED'

export const GET_LOAN_REQUESTS = 'GET_LOAN_REQUESTS'
export const GET_LOAN_REQUESTS_SUCCESS = 'GET_LOAN_REQUESTS_SUCCESS'
export const GET_LOAN_REQUESTS_FAILED = 'GET_LOAN_REQUESTS_FAILED'

export const UPDATE_LOAN_REQUEST_STATUS = 'UPDATE_LOAN_REQUEST_STATUS'
export const UPDATE_LOAN_REQUEST_STATUS_SUCCESS = 'UPDATE_LOAN_REQUEST_STATUS_SUCCESS'
export const UPDATE_LOAN_REQUEST_STATUS_FAILED = 'UPDATE_LOAN_REQUEST_STATUS_FAILED'

export const UPDATE_LOAN_PAYMENT = 'UPDATE_LOAN_PAYMENT'
export const UPDATE_LOAN_PAYMENT_SUCCESS = 'UPDATE_LOAN_PAYMENT_SUCCESS'
export const UPDATE_LOAN_PAYMENT_FAILED = 'UPDATE_LOAN_PAYMENT_FAILED'

export const GET_LENDERS = 'GET_LENDERS'
export const GET_LENDERS_SUCCESS = 'GET_LENDERS_SUCCESS'
export const GET_LENDERS_FAILED = 'GET_LENDERS_FAILED'

export const FILE_UPLOAD = 'FILE_UPLOAD'
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS'
export const FILE_UPLOAD_FAILED = 'FILE_UPLOAD_FAILED'


export interface GetUserHomeData {
  type: typeof GET_USER_HOME_DATA
}
export interface GetUserHomeDataSuccess {
  type: typeof GET_USER_HOME_DATA_SUCCESS
  payload: typeGetUserHomeDataSuccess
}
export interface GetUserHomeDataFailed {
  type: typeof GET_USER_HOME_DATA_FAILED
  payload: any
}
export interface GetUserSettings {
  type: typeof GET_USER_SETTINGS
}
export interface GetUserSettingsSuccess {
  type: typeof GET_USER_SETTINGS_SUCCESS
  payload: typeGetUserSettingsSuccess
}
export interface GetUserSettingsFailed {
  type: typeof GET_USER_SETTINGS_FAILED
  payload: any
}
export interface FundUserLendMe {
  type: typeof FUND_USER_LEND_ME
}
export interface FundUserLendMeSuccess {
  type: typeof FUND_USER_LEND_ME_SUCCESS
  payload: typeFundUserLendMeSuccess
}
export interface FundUserLendMeFailed {
  type: typeof FUND_USER_LEND_ME_FAILED
  payload: any
}

export interface GetUserLendMeDepositHistory {
  type: typeof GET_USER_LEND_ME_DEPOSIT_HISTORY
}
export interface GetUserLendMeDepositHistorySuccess {
  type: typeof GET_USER_LEND_ME_DEPOSIT_HISTORY_SUCCESS
  payload: typeGetUserLendMeDepositHistorySuccess
}
export interface GetUserLendMeDepositHistoryFailed {
  type: typeof GET_USER_LEND_ME_DEPOSIT_HISTORY_FAILED
  payload: any
}

export interface RequestUserLoan {
  type: typeof REQUEST_USER_LOAN
}
export interface RequestUserLoanSuccess {
  type: typeof REQUEST_USER_LOAN_SUCCESS
  payload: typeGetUserLoanHistorySuccess
}
export interface RequestUserLoanFailed {
  type: typeof REQUEST_USER_LOAN_FAILED
  payload: any
}

export interface GetUserLoanHistory {
  type: typeof GET_USER_LOAN_HISTORY
}
export interface GetUserLoanHistorySuccess {
  type: typeof GET_USER_LOAN_HISTORY_SUCCESS
  payload: typeRequestUserLoanSuccess
}
export interface GetUserLoanHistoryFailed {
  type: typeof GET_USER_LOAN_HISTORY_FAILED
  payload: any
}

export interface GetUserLendMeBalanceTransactionHistory {
  type: typeof GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY
}
export interface GetUserLendMeBalanceTransactionHistorySuccess {
  type: typeof GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY_SUCCESS
  payload: typeGetUserLendMeBalanceTransactionHistorySuccess
}
export interface GetUserLendMeBalanceTransactionHistoryFailed {
  type: typeof GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY_FAILED
  payload: any
}

export interface WithDrawFromLendMeWallet {
  type: typeof WITHDRAW_FROM_LEND_ME_WALLET
}
export interface WithDrawFromLendMeWalletSuccess {
  type: typeof WITHDRAW_FROM_LEND_ME_WALLET_SUCCESS
  payload: typeWithDrawFromLendMeWalletSuccess
}
export interface WithDrawFromLendMeWalletFailed {
  type: typeof WITHDRAW_FROM_LEND_ME_WALLET_FAILED
  payload: any
}

// ====================================================
export interface GetAdminHomeData {
  type: typeof GET_ADMIN_HOME_DATA
}
export interface GetAdminHomeDataSuccess {
  type: typeof GET_ADMIN_HOME_DATA_SUCCESS
  payload: typeGetAdminHomeDataSuccess
}
export interface GetAdminHomeDataFailed {
  type: typeof GET_ADMIN_HOME_DATA_FAILED
  payload: any
}
export interface GetAdminSettings {
  type: typeof GET_ADMIN_SETTINGS
}
export interface GetAdminSettingsSuccess {
  type: typeof GET_ADMIN_SETTINGS_SUCCESS
  payload: typeGetAdminSettingsSuccess
}
export interface GetAdminSettingsFailed {
  type: typeof GET_ADMIN_SETTINGS_FAILED
  payload: any
}
export interface UpdateSettings {
  type: typeof UPDATE_SETTINGS
}
export interface UpdateSettingsSuccess {
  type: typeof UPDATE_SETTINGS_SUCCESS
  payload: typeUpdateSettingsSuccess
}
export interface UpdateSettingsFailed {
  type: typeof UPDATE_SETTINGS_FAILED
  payload: any
}

export interface GetLoanRequests {
  type: typeof GET_LOAN_REQUESTS
}
export interface GetLoanRequestsSuccess {
  type: typeof GET_LOAN_REQUESTS_SUCCESS
  payload: typeGetLoanRequestsSuccess
}
export interface GetLoanRequestsFailed {
  type: typeof GET_LOAN_REQUESTS_FAILED
  payload: any
}

export interface UpdateLoanRequestStatus {
  type: typeof UPDATE_LOAN_REQUEST_STATUS
}
export interface UpdateLoanRequestStatusSuccess {
  type: typeof UPDATE_LOAN_REQUEST_STATUS_SUCCESS
  payload: typeUpdateLoanRequestStatusSuccess
}
export interface UpdateLoanRequestStatusFailed {
  type: typeof UPDATE_LOAN_REQUEST_STATUS_FAILED
  payload: any
}

export interface UpdateLoanPayment {
  type: typeof UPDATE_LOAN_PAYMENT
}
export interface UpdateLoanPaymentSuccess {
  type: typeof UPDATE_LOAN_PAYMENT_SUCCESS
  payload: typeUpdateLoanPaymentSuccess
}
export interface UpdateLoanPaymentFailed {
  type: typeof UPDATE_LOAN_PAYMENT_FAILED
  payload: any
}

export interface GetLenders {
  type: typeof GET_LENDERS
}
export interface GetLendersSuccess {
  type: typeof GET_LENDERS_SUCCESS
  payload: typeGetLendersSuccess
}
export interface GetLendersFailed {
  type: typeof GET_LENDERS_FAILED
  payload: any
}

export interface FileUpload {
  type: typeof FILE_UPLOAD
}
export interface FileUploadSuccess {
  type: typeof FILE_UPLOAD_SUCCESS
  payload: typeFileUploadSuccess
}
export interface FileUploadFailed {
  type: typeof FILE_UPLOAD_FAILED
  payload: any
}

export type LentMeActionConstants =
  | GetUserHomeData
  | GetUserHomeDataSuccess
  | GetUserHomeDataFailed
  | GetUserSettings
  | GetUserSettingsSuccess
  | GetUserSettingsFailed
  | GetUserLendMeDepositHistory
  | GetUserLendMeDepositHistorySuccess
  | GetUserLendMeDepositHistoryFailed
  | FundUserLendMe
  | FundUserLendMeSuccess
  | FundUserLendMeFailed
  | RequestUserLoan
  | RequestUserLoanSuccess
  | RequestUserLoanFailed
  | GetUserLoanHistory
  | GetUserLoanHistorySuccess
  | GetUserLoanHistoryFailed
  | GetUserLendMeBalanceTransactionHistory
  | GetUserLendMeBalanceTransactionHistorySuccess
  | GetUserLendMeBalanceTransactionHistoryFailed
  | WithDrawFromLendMeWallet
  | WithDrawFromLendMeWalletSuccess
  | WithDrawFromLendMeWalletFailed
  | GetAdminHomeData
  | GetAdminHomeDataSuccess
  | GetAdminHomeDataFailed
  | GetAdminSettings
  | GetAdminSettingsSuccess
  | GetAdminSettingsFailed
  | GetLoanRequests
  | GetLoanRequestsSuccess
  | GetLoanRequestsFailed
  | UpdateSettings
  | UpdateSettingsSuccess
  | UpdateSettingsFailed
  | UpdateLoanRequestStatus
  | UpdateLoanRequestStatusSuccess
  | UpdateLoanRequestStatusFailed
  | UpdateLoanPayment
  | UpdateLoanPaymentSuccess
  | UpdateLoanPaymentFailed
  | GetLenders
  | GetLendersSuccess
  | GetLendersFailed
  | FileUpload
  | FileUploadSuccess
  | FileUploadFailed

