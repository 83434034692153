import axios from '../../utils/axios'
import { setAlert } from '../alert/actions'
import { Dispatch } from 'redux'
import {
  PURCHASE_CONTRIBUTION,
  PurchaseContribution,
  PURCHASE_CONTRIBUTION_SUCCESS,
  PurchaseContributionSuccess,
  PURCHASE_CONTRIBUTION_FAILED,
  PurchaseContributionFailed,
  GET_ALL_CONTRIBUTIONS,
  GetAllContributions,
  GET_ALL_CONTRIBUTIONS_SUCCESS,
  GetAllContributionsSuccess,
  GET_ALL_CONTRIBUTIONS_FAILED,
  GetAllContributionsFailed,
  GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER,
  GetAllContributionsForASingleUser,
  GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER_SUCCESS,
  GetAllContributionsForASingleUserSuccess,
  GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER_FAILED,
  GetAllContributionsForASingleUserFailed,
  GET_TOTAL_PAYOUTS,
  GetTotalPayouts,
  GET_TOTAL_PAYOUTS_SUCCESS,
  GetTotalPayoutsSuccess,
  GET_TOTAL_PAYOUTS_FAILED,
  GetTotalPayoutsFailed,
  GET_PAYERS,
  GetPayers,
  GET_PAYERS_SUCCESS,
  GetPayersSuccess,
  GET_PAYERS_FAILED,
  GetPayersFailed,
  GET_CURRENT_POSITION_OF_A_USER,
  GetCurrentPositionOfAUser,
  GET_CURRENT_POSITION_OF_A_USER_SUCCESS,
  GetCurrentPositionOfAUserSuccess,
  GET_CURRENT_POSITION_OF_A_USER_FAILED,
  GetCurrentPositionOfAUserFailed,
  GET_NEXT_INLINE_FOR_A_PAYOUT,
  GetNextInLineForAPayout,
  GET_NEXT_INLINE_FOR_A_PAYOUT_SUCCESS,
  GetNextInLineForAPayoutSuccess,
  GET_NEXT_INLINE_FOR_A_PAYOUT_FAILED,
  GetNextInLineForAPayoutFailed
} from './constants'

// Purchase Contribution
export const purchaseContribution = (userPosition?: any, userId?: any) =>
  async (
    dispatch: Dispatch<
      | PurchaseContribution
      | PurchaseContributionSuccess
      | PurchaseContributionFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: PURCHASE_CONTRIBUTION })
      const payload = {
        "position": userPosition,
        "userId": userId
      }
      console.log(payload)
      const res = await axios.put(`/contribution/purchase`, payload)
      dispatch({
        type: PURCHASE_CONTRIBUTION_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: PURCHASE_CONTRIBUTION_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: PURCHASE_CONTRIBUTION_FAILED,
          payload: err.message
        })
      }
    }
  }

// Get All Contributions
export const getAllContributions =
  (page?: number | undefined, pageSize?: number | undefined) =>
  async (
    dispatch: Dispatch<
      | GetAllContributions
      | GetAllContributionsSuccess
      | GetAllContributionsFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: GET_ALL_CONTRIBUTIONS })
      const res = await axios.get(`/contribution/list?page=${page ?? 1}&pageSize=${pageSize ?? 20}`)

      dispatch({
        type: GET_ALL_CONTRIBUTIONS_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_ALL_CONTRIBUTIONS_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_ALL_CONTRIBUTIONS_FAILED,
          payload: err.message
        })
      }
    }
  }

// Get All Contributions For A Single User
export const gerAllContributionsForASingleUser =
  (id: string) =>
  async (
    dispatch: Dispatch<
      | GetAllContributionsForASingleUser
      | GetAllContributionsForASingleUserSuccess
      | GetAllContributionsForASingleUserFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER })
      const res = await axios.get(`/contribution/user/${id}`)

      dispatch({
        type: GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER_FAILED,
          payload: err.message
        })
      }
    }
  }

// Get Total Payouts
export const getTotalPayouts =
  () =>
  async (
    dispatch: Dispatch<
      GetTotalPayouts | GetTotalPayoutsSuccess | GetTotalPayoutsFailed | any
    >
  ) => {
    try {
      dispatch({ type: GET_TOTAL_PAYOUTS })
      const res = await axios.get(`/contribution/total-payout`)

      dispatch({
        type: GET_TOTAL_PAYOUTS_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_TOTAL_PAYOUTS_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_TOTAL_PAYOUTS_FAILED,
          payload: err.message
        })
      }
    }
  }


// Get Payers
export const getPayers =
  (position: any) =>
    async (
      dispatch: Dispatch<
        GetPayers | GetPayersSuccess | GetPayersFailed | any
      >
    ) => {
      try {
        dispatch({ type: GET_PAYERS })
        const res = await axios.get(`/contribution/payers/${position}`)

        dispatch({
          type: GET_PAYERS_SUCCESS,
          payload: res
        })
      } catch (err: any) {
        if (err.response) {
          dispatch(setAlert(err.response.data.message, 'warning'))
          dispatch({
            type: GET_PAYERS_FAILED,
            payload: err.response.data.message
          })
        } else {
          dispatch(setAlert(err.message, 'error'))
          dispatch({
            type: GET_PAYERS_FAILED,
            payload: err.message
          })
        }
      }
    }

// Get Current Position Of A User
export const getCurrentPositionOfAUser =
  (id: string) =>
  async (
    dispatch: Dispatch<
      | GetCurrentPositionOfAUser
      | GetCurrentPositionOfAUserSuccess
      | GetCurrentPositionOfAUserFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: GET_CURRENT_POSITION_OF_A_USER })
      const res = await axios.get(`/contribution/user/${id}/position`)

      dispatch({
        type: GET_CURRENT_POSITION_OF_A_USER_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_CURRENT_POSITION_OF_A_USER_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_CURRENT_POSITION_OF_A_USER_FAILED,
          payload: err.message
        })
      }
    }
  }

// Get Next Inline For A Payout
export const getNextInLineForAPayout =
  () =>
  async (
    dispatch: Dispatch<
      | GetNextInLineForAPayout
      | GetNextInLineForAPayoutSuccess
      | GetNextInLineForAPayoutFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: GET_NEXT_INLINE_FOR_A_PAYOUT })
      const res = await axios.get(`/contribution/next/payout`)

      dispatch({
        type: GET_NEXT_INLINE_FOR_A_PAYOUT_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_NEXT_INLINE_FOR_A_PAYOUT_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_NEXT_INLINE_FOR_A_PAYOUT_FAILED,
          payload: err.message
        })
      }
    }
  }
