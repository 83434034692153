import {
  MembershipActionConstants,
  PURCHASE_MEMBERSHIP,
  PURCHASE_MEMBERSHIP_SUCCESS,
  PURCHASE_MEMBERSHIP_FAILED,
  ADMIN_ASSIGN_MEMBERSHIP,
  ADMIN_ASSIGN_MEMBERSHIP_SUCCESS,
  ADMIN_ASSIGN_MEMBERSHIP_FAILED,
  ADMIN_REVOKE_MEMBERSHIP,
  ADMIN_REVOKE_MEMBERSHIP_SUCCESS,
  ADMIN_REVOKE_MEMBERSHIP_FAILED
} from './constants'

import { IMembership } from '../../interfaces/membership'
import { DEFAULT_MEMBERSHIP } from './membership'

const initialState: IMembership = DEFAULT_MEMBERSHIP

export const membershipReducer = (
  state: IMembership = initialState,
  action: MembershipActionConstants
) => {
  switch (action.type) {
    case PURCHASE_MEMBERSHIP:
      return { ...state, purchase_membership_loading: true }
    case PURCHASE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        purchase_membership_loading: false,
        purchase_membership: action.payload.data
      }
    case PURCHASE_MEMBERSHIP_FAILED:
      return {
        ...state,
        purchase_membership_loading: false,
        error: { purchase_membership_error: action.payload }
      }

    case ADMIN_ASSIGN_MEMBERSHIP:
      return { ...state, admin_assign_membership_loading: true }
    case ADMIN_ASSIGN_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        admin_assign_membership_loading: false,
        admin_assign_membership: action.payload.data
      }
    case ADMIN_ASSIGN_MEMBERSHIP_FAILED:
      return {
        ...state,
        admin_assign_membership_loading: false,
        error: { admin_assign_membership_error: action.payload }
      }

    case ADMIN_REVOKE_MEMBERSHIP:
      return { ...state, admin_revoke_membership_loading: true }
    case ADMIN_REVOKE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        admin_revoke_membership_loading: false,
        admin_revoke_membership: action.payload.data
      }
    case ADMIN_REVOKE_MEMBERSHIP_FAILED:
      return {
        ...state,
        admin_revoke_membership_loading: false,
        error: { admin_revoke_membership_error: action.payload }
      }
    default:
      return state
  }
}
