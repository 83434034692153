import {
  ContributionActionConstants,
  PURCHASE_CONTRIBUTION,
  PURCHASE_CONTRIBUTION_SUCCESS,
  PURCHASE_CONTRIBUTION_FAILED,
  GET_ALL_CONTRIBUTIONS,
  GET_ALL_CONTRIBUTIONS_SUCCESS,
  GET_ALL_CONTRIBUTIONS_FAILED,
  GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER,
  GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER_SUCCESS,
  GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER_FAILED,
  GET_TOTAL_PAYOUTS,
  GET_TOTAL_PAYOUTS_SUCCESS,
  GET_TOTAL_PAYOUTS_FAILED,
  GET_CURRENT_POSITION_OF_A_USER,
  GET_CURRENT_POSITION_OF_A_USER_SUCCESS,
  GET_CURRENT_POSITION_OF_A_USER_FAILED,
  GET_NEXT_INLINE_FOR_A_PAYOUT,
  GET_NEXT_INLINE_FOR_A_PAYOUT_SUCCESS,
  GET_NEXT_INLINE_FOR_A_PAYOUT_FAILED,
  GET_PAYERS,
  GET_PAYERS_SUCCESS,
  GET_PAYERS_FAILED,
} from './constants'

import {
  getNextInLineForPayout,
  getAllContributions,
  getLastContributor,
  getCurrentPositionOfAUser,
  getGeneralContributions,
  getAllPayers,
} from './utility'
import { IContribution } from '../../interfaces/contribution'
import { DEFAULT_CONTRIBUTION } from './contribution'

const initialState: IContribution = DEFAULT_CONTRIBUTION

export const contributionsReducer = (
  state: IContribution = initialState,
  action: ContributionActionConstants
) => {
  switch (action.type) {
    case PURCHASE_CONTRIBUTION:
      return { ...state, purchase_contribution_loading: true }
    case PURCHASE_CONTRIBUTION_SUCCESS:
      return {
        ...state,
        purchase_contribution_loading: false,
        purchase_contribution: action.payload.data
      }
    case PURCHASE_CONTRIBUTION_FAILED:
      return {
        ...state,
        purchase_contribution_loading: false,
        error: { ...state.error, purchase_contribution_error: action.payload }
      }

    case GET_ALL_CONTRIBUTIONS:
      return { ...state, get_all_contributions_loading: true }
    case GET_ALL_CONTRIBUTIONS_SUCCESS:
      return {
        ...state,
        get_all_contributions_loading: false,
        get_all_contributions: getAllContributions(action.payload.data.data),
        get_all_contributions_pagination: action.payload.data.pagination,
        get_total_contributions: action.payload.data.pagination.pageTotal,
        get_total_contribution_amount:
          action.payload.data.pagination.totalAmount,
        get_last_contributor: getLastContributor(action.payload.data.data),
        general_contributions: getGeneralContributions(action.payload.data.data)
      }
    case GET_ALL_CONTRIBUTIONS_FAILED:
      return {
        ...state,
        get_all_contributions_loading: false,
        error: { ...state.error, get_all_contributions_error: action.payload }
      }

    case GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER:
      return { ...state, get_contributions_for_a_single_user_loading: true }
    case GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER_SUCCESS:
      return {
        ...state,
        get_contributions_for_a_single_user_loading: false,
        get_contributions_for_a_single_user: action.payload.data.data
      }
    case GET_ALL_CONTRIBUTIONS_FOR_A_SINGLE_USER_FAILED:
      return {
        ...state,
        get_contributions_for_a_single_user_loading: false,
        error: { ...state.error, get_contributions_for_a_single_user_error: action.payload }
      }

    case GET_TOTAL_PAYOUTS:
      return { ...state, get_total_payouts_loading: true }
    case GET_TOTAL_PAYOUTS_SUCCESS:
      return {
        ...state,
        get_total_payouts_loading: false,
        get_total_payouts: action.payload.data.data
      }
    case GET_TOTAL_PAYOUTS_FAILED:
      return {
        ...state,
        get_total_payouts_loading: false,
        error: { ...state.error, get_total_payouts_error: action.payload }
      }
      
    case GET_PAYERS:
      return { ...state, get_payers_loading: true }
    case GET_PAYERS_SUCCESS:
      return {
        ...state,
        get_payers_loading: false,
        get_payers: getAllPayers(action.payload.data.data)
      }
    case GET_PAYERS_FAILED:
      return {
        ...state,
        get_payers_loading: false,
        error: { ...state.error, get_payers_error: action.payload }
      }

    case GET_CURRENT_POSITION_OF_A_USER:
      return { ...state, get_current_position_for_a_user_loading: true }
    case GET_CURRENT_POSITION_OF_A_USER_SUCCESS:
      return {
        ...state,
        get_current_position_for_a_user_loading: false,
        get_current_position_for_a_user: getCurrentPositionOfAUser(
          action.payload.data.data
        )
      }
    case GET_CURRENT_POSITION_OF_A_USER_FAILED:
      return {
        ...state,
        get_current_position_for_a_user_loading: false,
        error: { ...state.error, get_current_position_for_a_user_error: action.payload }
      }

    case GET_NEXT_INLINE_FOR_A_PAYOUT:
      return { ...state, get_next_inline_for_a_payout_loading: true }
    case GET_NEXT_INLINE_FOR_A_PAYOUT_SUCCESS:
      return {
        ...state,
        get_next_inline_for_a_payout_loading: false,
        get_next_inline_for_a_payout: getNextInLineForPayout(
          action.payload.data.data
        )
      }
    case GET_NEXT_INLINE_FOR_A_PAYOUT_FAILED:
      return {
        ...state,
        get_next_inline_for_a_payout_loading: false,
        error: { ...state.error, get_next_inline_for_a_payout_error: action.payload }
      }

    default:
      return state
  }
}
