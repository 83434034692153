import {
  AuthActionConstants,
  CHECK_AUTH_STATE,
  CHECK_AUTH_TIMEOUT,
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAILED,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  CLEAN_UP_LOGIN_USER,
  RESEND_ACTIVATION_TOKEN,
  RESEND_ACTIVATION_TOKEN_SUCCESS,
  RESEND_ACTIVATION_TOKEN_FAILED,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_STATES_FAILED,
  ACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT_SUCCESS,
  ACTIVATE_ACCOUNT_FAILED,
  ADMIN_ACTIVATE_ACCOUNT,
  ADMIN_ACTIVATE_ACCOUNT_SUCCESS,
  ADMIN_ACTIVATE_ACCOUNT_FAILED,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  INITIATE_LOGOUT_USER,
  LOGOUT_USER,
} from './constants'

import { IAuth } from '../../interfaces/auth'
import { DEFAULT_AUTH } from './auth'

const initialState: IAuth = DEFAULT_AUTH

export const authReducer = (
  state: IAuth = initialState,
  action: AuthActionConstants
) => {
  switch (action.type) {
    case CHECK_AUTH_STATE:
    case CHECK_AUTH_TIMEOUT:
      return {
        ...state,
        is_authenticated: true,
        is_authenticated_loading: false
      }

    case LOAD_USER:
      return { ...state, user_loading: true }
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        is_authenticated: true,
        user_loading: false,
        is_activated: action.payload
          ? action.payload.isActive
            ? true
            : false
          : null,
        user: action.payload,
        user_role: action.payload
          ? action.payload.isAdmin
            ? 'ADMIN'
            : 'USER'
          : null
      }
    case LOAD_USER_FAILED:
      return {
        ...state,
        is_authenticated: false,
        user_loading: false,
        error: action.payload
      }

    case LOGIN_USER:
      return { ...state, is_authenticated_loading: true }
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        is_authenticated_loading: false,
        is_authenticated: true,
        user_role: action.payload.data.data.isAdmin ? 'ADMIN' : 'USER',
        is_activated: action.payload.data.data.isActive ? true : false,
        token: action.payload.data.authorization.token,
        user: action.payload.data.data
      }
    case LOGIN_USER_FAILED:
      return {
        ...state,
        is_authenticated_loading: false,
        is_authenticated: false,
        is_activated: false,
        token: null,
        user: null,
        user_role: null,
        error: action.payload
      }

    case GET_STATES:
      return { ...state, is_get_states_loading: true }
    case GET_STATES_SUCCESS:
      return {
        ...state,
        is_get_states_loading: false,
        get_states: action.payload,
      }
    case GET_STATES_FAILED:
      return {
        ...state,
        is_get_states_loading: false,
        error: action.payload
      }

    case CLEAN_UP_LOGIN_USER:
      return {
        ...state,
        is_authenticated: null,
        is_activated: null,
        error: null
      }

    case RESEND_ACTIVATION_TOKEN:
      return {
        ...state,
        resend_activation_token_loading: true,
        is_authenticated: null,
        is_activated: null,
        token: null,
        user: null,
        user_role: null,
        error: null
      }
    case RESEND_ACTIVATION_TOKEN_SUCCESS:
      return {
        ...state,
        resend_activation_token: action.payload,
        resend_activation_token_loading: false
      }
    case RESEND_ACTIVATION_TOKEN_FAILED:
      return {
        ...state,
        resend_activation_token_loading: false,
        error: action.payload
      }

    case REGISTER_USER:
      return { ...state, is_registered_loading: true }
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        is_registered: action.payload.data,
        user: action.payload.data,
        is_registered_loading: false,
        error: null
      }
    case REGISTER_USER_FAILED:
      return { ...state, is_registered_loading: false, error: action.payload }

    case ACTIVATE_ACCOUNT:
      return { ...state, activate_account_loading: true }
    case ACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        activate_account: action.payload,
        activate_account_loading: false,
        error: null
      }
    case ACTIVATE_ACCOUNT_FAILED:
      return {
        ...state,
        activate_account_loading: false,
        error: action.payload
      }

    case ADMIN_ACTIVATE_ACCOUNT:
      return { ...state, admin_activate_account_loading: true }
    case ADMIN_ACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        admin_activate_account: action.payload,
        admin_activate_account_loading: false,
        error: null
      }
    case ADMIN_ACTIVATE_ACCOUNT_FAILED:
      return {
        ...state,
        admin_activate_account_loading: false,
        error: action.payload
      }

    case FORGOT_PASSWORD:
      return { ...state, forgot_password_loading: true }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgot_password: action.payload.data,
        forgot_password_loading: false,
        error: null
      }
    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        forgot_password_loading: false,
        error: action.payload
      }

    case RESET_PASSWORD:
      return { ...state, reset_password_loading: true }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        reset_password: action.payload.data,
        reset_password_loading: false,
        error: null
      }
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        reset_password_loading: false,
        error: action.payload
      }

    case LOGOUT_USER:
      return { ...state }

    case INITIATE_LOGOUT_USER:
      return {
        ...state,
        token: null,
        user: null,
        user_role: null,
        is_authenticated: false,
        is_registered: null,
        activate_account: null
      }

    default:
      return state
  }
}
