import {
  TransactionsActionConstants,
  GET_ALL_TRANSACTIONS,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GET_ALL_TRANSACTIONS_FAILED,
  GET_ALL_TRANSACTIONS_FOR_A_USER,
  GET_ALL_TRANSACTIONS_FOR_A_USER_SUCCESS,
  GET_ALL_TRANSACTIONS_FOR_A_USER_FAILED
} from './constants'

import { ITransactions } from '../../interfaces/transactions'
import { DEFAULT_TRANSACTIONS } from './transactions'

import { getAllTransactions, getAllTransactionsForAUser } from './utility'

const initialState: ITransactions = DEFAULT_TRANSACTIONS

export const transactionsReducer = (
  state: ITransactions = initialState,
  action: TransactionsActionConstants
) => {
  switch (action.type) {
    case GET_ALL_TRANSACTIONS:
      return { ...state, get_all_transactions_loading: true }
    case GET_ALL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        get_all_transactions_loading: false,
        get_all_transactions: getAllTransactions(action.payload.data.data),
        get_all_transactions_pagination: action.payload.data.pagination
      }
    case GET_ALL_TRANSACTIONS_FAILED:
      return {
        ...state,
        get_all_transactions_loading: false,
        error: { get_all_transactions_error: action.payload }
      }

    case GET_ALL_TRANSACTIONS_FOR_A_USER:
      return { ...state, get_all_transactions_for_a_user_loading: true }
    case GET_ALL_TRANSACTIONS_FOR_A_USER_SUCCESS:
      return {
        ...state,
        get_all_transactions_for_a_user_loading: false,
        get_all_transactions_for_a_user: getAllTransactionsForAUser(
          action.payload.data.data
        ),
        get_all_transactions_for_a_user_pagination: action.payload.data.pagination
      }
    case GET_ALL_TRANSACTIONS_FOR_A_USER_FAILED:
      return {
        ...state,
        get_all_transactions_for_a_user_loading: false,
        error: { get_all_transactions_for_a_user_error: action.payload }
      }

    default:
      return state
  }
}
