import {
  typePurchaseMembershipSuccess,
  typeAdminAssignMembershipSuccess,
  typeAdminRevokeMembershipSuccess
} from '../../interfaces/membership'

export const PURCHASE_MEMBERSHIP = 'PURCHASE_MEMBERSHIP'
export const PURCHASE_MEMBERSHIP_SUCCESS = 'PURCHASE_MEMBERSHIP_SUCCESS'
export const PURCHASE_MEMBERSHIP_FAILED = 'PURCHASE_MEMBERSHIP_FAILED'

export const ADMIN_ASSIGN_MEMBERSHIP = 'ADMIN_ASSIGN_MEMBERSHIP'
export const ADMIN_ASSIGN_MEMBERSHIP_SUCCESS = 'ADMIN_ASSIGN_MEMBERSHIP_SUCCESS'
export const ADMIN_ASSIGN_MEMBERSHIP_FAILED = 'ADMIN_ASSIGN_MEMBERSHIP_FAILED'

export const ADMIN_REVOKE_MEMBERSHIP = 'ADMIN_REVOKE_MEMBERSHIP'
export const ADMIN_REVOKE_MEMBERSHIP_SUCCESS = 'ADMIN_REVOKE_MEMBERSHIP_SUCCESS'
export const ADMIN_REVOKE_MEMBERSHIP_FAILED = 'ADMIN_REVOKE_MEMBERSHIP_FAILED'

export interface PurchaseMembership {
  type: typeof PURCHASE_MEMBERSHIP
}
export interface PurchaseMembershipSuccess {
  type: typeof PURCHASE_MEMBERSHIP_SUCCESS
  payload: typePurchaseMembershipSuccess
}
export interface PurchaseMembershipFailed {
  type: typeof PURCHASE_MEMBERSHIP_FAILED
  payload: any
}

export interface AdminAssignMembership {
  type: typeof ADMIN_ASSIGN_MEMBERSHIP
}
export interface AdminAssignMembershipSuccess {
  type: typeof ADMIN_ASSIGN_MEMBERSHIP_SUCCESS
  payload: typeAdminAssignMembershipSuccess
}
export interface AdminAssignMembershipFailed {
  type: typeof ADMIN_ASSIGN_MEMBERSHIP_FAILED
  payload: any
}

export interface AdminRevokeMembership {
  type: typeof ADMIN_REVOKE_MEMBERSHIP
}
export interface AdminRevokeMembershipSuccess {
  type: typeof ADMIN_REVOKE_MEMBERSHIP_SUCCESS
  payload: typeAdminRevokeMembershipSuccess
}
export interface AdminRevokeMembershipFailed {
  type: typeof ADMIN_REVOKE_MEMBERSHIP_FAILED
  payload: any
}

export type MembershipActionConstants =
  | PurchaseMembership
  | PurchaseMembershipSuccess
  | PurchaseMembershipFailed
  | AdminAssignMembership
  | AdminAssignMembershipSuccess
  | AdminAssignMembershipFailed
  | AdminRevokeMembership
  | AdminRevokeMembershipSuccess
  | AdminRevokeMembershipFailed
