import {
  UsersActionConstants,
  LOGGED_IN_USER,
  LOGGED_IN_USER_SUCCESS,
  LOGGED_IN_USER_FAILED,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILED,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_USER_BVN_DETAILS,
  UPDATE_USER_BVN_DETAILS_SUCCESS,
  UPDATE_USER_BVN_DETAILS_FAILED,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  UPLOAD_USER_AVATAR,
  UPLOAD_USER_AVATAR_SUCCESS,
  UPLOAD_USER_AVATAR_FAILED,
  BLOCK_USER,
  BLOCK_USER_SUCCESS,
  BLOCK_USER_FAILED,
  UPDATE_BANK_DETAILS,
  UPDATE_BANK_DETAILS_SUCCESS,
  UPDATE_BANK_DETAILS_FAILED,
  SEARCH_GET_USER,
  SEARCH_GET_USER_SUCCESS,
  SEARCH_GET_USER_FAILED,
  SEARCH_USER,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  GET_ALL_INVITEES,
  GET_ALL_INVITEES_SUCCESS,
  GET_ALL_INVITEES_FAILED,
} from './constants'

import { IUsers } from '../../interfaces/users'
import { DEFAULT_USERS } from './users'

import { getAllUsers } from './utility'

const initialState: IUsers = DEFAULT_USERS

export const usersReducer = (
  state: IUsers = initialState,
  action: UsersActionConstants
) => {
  switch (action.type) {
    case LOGGED_IN_USER:
      return { ...state, logged_in_user_loading: true }
    case LOGGED_IN_USER_SUCCESS:
      return {
        ...state,
        logged_in_user_loading: false,
        logged_in_user: action.payload.data.data
      }
    case LOGGED_IN_USER_FAILED:
      return {
        ...state,
        logged_in_user_loading: false,
        error: { logged_in_user_error: action.payload }
      }

    case GET_USER:
      return { ...state, get_user_loading: true }
    case GET_USER_SUCCESS:
      return {
        ...state,
        get_user_loading: false,
        get_user: action.payload.data.data
      }
    case GET_USER_FAILED:
      return {
        ...state,
        get_user_loading: false,
        error: { get_user_error: action.payload }
      }

    case GET_ALL_USERS:
      return { ...state, delete_user: null, get_all_users_loading: true }
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        get_all_users_loading: false,
        get_all_users: getAllUsers(action.payload.data.data),
        get_all_users_pagination: action.payload.data.pagination
      }
    case GET_ALL_USERS_FAILED:
      return {
        ...state,
        get_all_users_loading: false,
        error: { get_all_users_error: action.payload }
      }

    case GET_ALL_INVITEES:
      return { ...state, delete_user: null, get_all_invitees_loading: true }
    case GET_ALL_INVITEES_SUCCESS:
      return {
        ...state,
        get_all_invitees_loading: false,
        get_all_invitees: action.payload.data.data,
      }
    case GET_ALL_INVITEES_FAILED:
      return {
        ...state,
        get_all_invitees_loading: false,
        error: { get_all_invitees_error: action.payload }
      }

    case UPDATE_USER_PROFILE:
      return { ...state, update_user_profile_loading: true }
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        update_user_profile_loading: false,
        update_user_profile: action.payload.data
      }
    case UPDATE_USER_PROFILE_FAILED:
      return {
        ...state,
        update_user_profile_loading: false,
        error: { update_user_profile_error: action.payload }
      }

    case UPDATE_USER_BVN_DETAILS:
      return { ...state, update_user_bvn_details_loading: true }
    case UPDATE_USER_BVN_DETAILS_SUCCESS:
      return {
        ...state,
        update_user_bvn_details_loading: false,
        update_user_bvn_details: action.payload.data
      }
    case UPDATE_USER_BVN_DETAILS_FAILED:
      return {
        ...state,
        update_user_bvn_details_loading: false,
        error: { update_user_bvn_details_error: action.payload }
      }

    case DELETE_USER:
      return { ...state, delete_user_loading: true }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        delete_user_loading: false,
        delete_user: action.payload.data
      }
    case DELETE_USER_FAILED:
      return {
        ...state,
        delete_user_loading: false,
        error: { delete_user_error: action.payload }
      }

    case UPLOAD_USER_AVATAR:
      return { ...state, upload_avatar_loading: true }
    case UPLOAD_USER_AVATAR_SUCCESS:
      return {
        ...state,
        upload_avatar: action.payload.data,
        upload_avatar_loading: false
      }
    case UPLOAD_USER_AVATAR_FAILED:
      return {
        ...state,
        upload_avatar_loading: false,
        error: { upload_avatar_error: action.payload }
      }

    case BLOCK_USER:
      return { ...state, block_user_loading: true }
    case BLOCK_USER_SUCCESS:
      return {
        ...state,
        block_user: action.payload.data,
        block_user_loading: false
      }
    case BLOCK_USER_FAILED:
      return {
        ...state,
        block_user_loading: false,
        error: { block_user_error: action.payload }
      }

    case SEARCH_GET_USER:
      return { ...state, search_get_user_loading: true }
    case SEARCH_GET_USER_SUCCESS:
      return {
        ...state,
        search_get_user: action.payload.data.data,
        search_get_user_loading: false
      }
    case SEARCH_GET_USER_FAILED:
      return {
        ...state,
        search_get_user_loading: false,
        error: { search_get_user_error: action.payload }
      }

    case SEARCH_USER:
      return { ...state, search_user_loading: true }
    case SEARCH_USER_SUCCESS:
      return {
        ...state,
        search_user: action.payload.data.data,
        search_user_loading: false
      }
    case SEARCH_USER_FAILED:
      return {
        ...state,
        search_user_loading: false,
        error: { search_user_error: action.payload }
      }

    case UPDATE_BANK_DETAILS:
      return { ...state, update_bank_details_loading: true }
    case UPDATE_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        update_bank_details: action.payload.data,
        update_bank_details_loading: false
      }
    case UPDATE_BANK_DETAILS_FAILED:
      return {
        ...state,
        update_bank_details_loading: false,
        error: { update_bank_details_error: action.payload }
      }

    case CHANGE_PASSWORD:
      return { ...state, change_password_loading: true }
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        change_password: action.payload.data,
        change_password_loading: false
      }
    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        change_password_loading: false,
        error: { change_password_error: action.payload }
      }

    default:
      return state
  }
}
