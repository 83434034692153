import axios from '../../utils/axios'
import { setAlert } from '../alert/actions'
import { Dispatch } from 'redux'
import {
  REQUEST_WITHDRAWAL,
  RequestWithdrawal,
  REQUEST_WITHDRAWAL_SUCCESS,
  RequestWithdrawalSuccess,
  REQUEST_WITHDRAWAL_FAILED,
  RequestWithdrawalFailed,
  GET_ALL_WITHDRAWALS,
  GetAllWithdrawals,
  GET_ALL_WITHDRAWALS_SUCCESS,
  GetAllWithdrawalsSuccess,
  GET_ALL_WITHDRAWALS_FAILED,
  GetAllWithdrawalsFailed,
  GET_WITHDRAWALS_FOR_A_USER,
  GetWithdrawalsForAUser,
  GET_WITHDRAWALS_FOR_A_USER_SUCCESS,
  GetWithdrawalsForAUserSuccess,
  GET_WITHDRAWALS_FOR_A_USER_FAILED,
  GetWithdrawalsForAUserFailed,
  GET_ALL_PAYOUTS,
  GetAllPayouts,
  GET_ALL_PAYOUTS_SUCCESS,
  GetAllPayoutsSuccess,
  GET_ALL_PAYOUTS_FAILED,
  GetAllPayoutsFailed,
  GET_ALL_REFERRALS,
  GetAllReferrals,
  GET_ALL_REFERRALS_SUCCESS,
  GetAllReferralsSuccess,
  GET_ALL_REFERRALS_FAILED,
  GetAllReferralsFailed,
  REQUEST_OTP,
  RequestOTP,
  REQUEST_OTP_SUCCESS,
  RequestOTPSuccess,
  REQUEST_OTP_FAILED,
  RequestOTPFailed,
  AUTHORIZE_WITHDRAWAL,
  AuthorizeWithdrawal,
  AUTHORIZE_WITHDRAWAL_SUCCESS,
  AuthorizeWithdrawalSuccess,
  AUTHORIZE_WITHDRAWAL_FAILED,
  AuthorizeWithdrawalFailed,
  APPROVE_WITHDRAWAL,
  ApproveWithdrawal,
  APPROVE_WITHDRAWAL_SUCCESS,
  ApproveWithdrawalSuccess,
  APPROVE_WITHDRAWAL_FAILED,
  ApproveWithdrawalFailed,
  DECLINE_WITHDRAWAL,
  DeclineWithdrawal,
  DECLINE_WITHDRAWAL_SUCCESS,
  DeclineWithdrawalSuccess,
  DECLINE_WITHDRAWAL_FAILED,
  DeclineWithdrawalFailed
} from './constants'

type formDataType = object

// Request Withdrawal
export const requestWithdrawal =
  (formData: formDataType) =>
  async (
    dispatch: Dispatch<
      | RequestWithdrawal
      | RequestWithdrawalSuccess
      | RequestWithdrawalFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: REQUEST_WITHDRAWAL })
      const res = await axios.post(`/withdrawal/request`, formData)
      dispatch({
        type: REQUEST_WITHDRAWAL_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: REQUEST_WITHDRAWAL_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: REQUEST_WITHDRAWAL_FAILED,
          payload: err.message
        })
      }
    }
  }

// Get All Withdrawals
export const getAllWithdrawals =
  () =>
  async (
    dispatch: Dispatch<
      | GetAllWithdrawals
      | GetAllWithdrawalsSuccess
      | GetAllWithdrawalsFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: GET_ALL_WITHDRAWALS })
      const res = await axios.get(`/withdrawal/list`)

      dispatch({
        type: GET_ALL_WITHDRAWALS_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_ALL_WITHDRAWALS_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_ALL_WITHDRAWALS_FAILED,
          payload: err.message
        })
      }
    }
  }

// Get Withdrawals For A User
export const getWithdrawalsForAUser =
  (id: number) =>
  async (
    dispatch: Dispatch<
      | GetWithdrawalsForAUser
      | GetWithdrawalsForAUserSuccess
      | GetWithdrawalsForAUserFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: GET_WITHDRAWALS_FOR_A_USER })
      const res = await axios.get(`/withdrawal/${id}`)

      dispatch({
        type: GET_WITHDRAWALS_FOR_A_USER_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_WITHDRAWALS_FOR_A_USER_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_WITHDRAWALS_FOR_A_USER_FAILED,
          payload: err.message
        })
      }
    }
  }

// Get All Payouts
export const getAllPayouts =
  (page?: number | undefined, pageSize?: number | undefined) =>
  async (
    dispatch: Dispatch<
      GetAllPayouts | GetAllPayoutsSuccess | GetAllPayoutsFailed | any
    >
  ) => {
    try {
      dispatch({ type: GET_ALL_PAYOUTS })
      const res = await axios.get(`/contribution/payouts?page=${page ?? 1}&pageSize=${pageSize ?? 20}`)

      dispatch({
        type: GET_ALL_PAYOUTS_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_ALL_PAYOUTS_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_ALL_PAYOUTS_FAILED,
          payload: err.message
        })
      }
    }
  }

// Get All Referrals
export const getAllReferrals =
  () =>
  async (
    dispatch: Dispatch<
      GetAllReferrals | GetAllReferralsSuccess | GetAllReferralsFailed | any
    >
  ) => {
    try {
      dispatch({ type: GET_ALL_REFERRALS })
      const res = await axios.get(`/user/invitees/admin`)

      dispatch({
        type: GET_ALL_REFERRALS_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_ALL_REFERRALS_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_ALL_REFERRALS_FAILED,
          payload: err.message
        })
      }
    }
  }

// Request OTP
export const requestOTP =
  (formData: formDataType) =>
  async (
    dispatch: Dispatch<RequestOTP | RequestOTPSuccess | RequestOTPFailed | any>
  ) => {
    try {
      dispatch({ type: REQUEST_OTP })
      const res = await axios.post(`/withdrawal/request-otp`, formData)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: REQUEST_OTP_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: REQUEST_OTP_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: REQUEST_OTP_FAILED,
          payload: err.message
        })
      }
    }
  }

// Authorize Withdrawal
export const authorizeWithdrawal =
  (formData: formDataType) =>
  async (
    dispatch: Dispatch<
      | AuthorizeWithdrawal
      | AuthorizeWithdrawalSuccess
      | AuthorizeWithdrawalFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: AUTHORIZE_WITHDRAWAL })
      const res = await axios.post(`/withdrawal/authorize-withdrawal`, formData)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: AUTHORIZE_WITHDRAWAL_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: AUTHORIZE_WITHDRAWAL_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: AUTHORIZE_WITHDRAWAL_FAILED,
          payload: err.message
        })
      }
    }
  }

//  Approve Withdrawal
export const approveWithdrawal =
  (formData: formDataType) =>
  async (
    dispatch: Dispatch<
      | ApproveWithdrawal
      | ApproveWithdrawalSuccess
      | ApproveWithdrawalFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: APPROVE_WITHDRAWAL })
      const res = await axios.put(`/withdrawal/approve`, formData)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: APPROVE_WITHDRAWAL_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: APPROVE_WITHDRAWAL_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: APPROVE_WITHDRAWAL_FAILED,
          payload: err.message
        })
      }
    }
  }

// Decline Withdrawal
export const declineWithdrawal =
  (formData: formDataType) =>
  async (
    dispatch: Dispatch<
      | DeclineWithdrawal
      | DeclineWithdrawalSuccess
      | DeclineWithdrawalFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: DECLINE_WITHDRAWAL })
      const res = await axios.post(`/withdrawal/decline`, formData)

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: DECLINE_WITHDRAWAL_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: DECLINE_WITHDRAWAL_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: DECLINE_WITHDRAWAL_FAILED,
          payload: err.message
        })
      }
    }
  }
