import React, { ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import Footer from '../components/footer/Footer'
import { IAppSettings } from '../../interfaces/settings'
import { IAppState } from '../../interfaces/app-state'
import { IPageData } from '../../interfaces/page'
import className from '../../utils/class-names'

import './BaseLayout.scss'

type Props = {
  nav: ReactNode
  sideNav?: ReactNode
  topNav?: ReactNode
  children: ReactNode
  orientation: 'vertical'
}

const BaseLayout = ({ nav, topNav, sideNav, orientation, children }: Props) => {
  const [scrolled, setScrolled] = useState(false)

  const sidebarOpened = useSelector<IAppState, boolean>(
    (state) => state.settings.sidebarOpened
  )
  const settings = useSelector<IAppState, IAppSettings>(
    (state) => state.settings
  )
  const pageData = useSelector<IAppState, IPageData>((state) => state.pageData)

  const handleScroll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScrolled(event.target.scrollTop > 0)
  }

  const mainContentClasses = className({
    'main-content': true,
    loaded: pageData.loaded,
    fulfilled: pageData.fulFilled
  })

  const mainContentWrapClasses = className({
    'main-content-wrap': true
  })

  const contentOverlay = (
    <div
      className={className({
        'content-overlay': true,
        show: sidebarOpened
      })}
    />
  )

  return (
    <div className={`layout ${orientation}`}>
      <div
        className={`app-container ${settings.boxed && 'boxed'} ${
          scrolled && 'scrolled'
        }`}
      >
        {nav}

        {topNav}

        {sideNav}

        <main onScroll={() => handleScroll} className={mainContentClasses}>
          <div className='app-loader'>
            <i className='icofont-spinner-alt-4 rotate' />
          </div>

          <div className={mainContentWrapClasses}>
            {pageData && !!pageData.title && (
              <header className='page-header'>
                <h1 className='page-title'>{pageData.title}</h1>
              </header>
            )}
            {children}
          </div>
        </main>

        <Footer
          breadcrumbs={pageData.breadcrumbs}
          layout={settings.layout}
          boxed={settings.boxed}
          loaded={pageData.loaded}
        />
        {contentOverlay}
      </div>
    </div>
  )
}

export default BaseLayout
