import {
  typeUpdateSettingsSuccess,
  typeGetSettingsSuccess,
  typeStartCounterSuccess,
  typeGetCountSuccess,
  typeResetCounterSuccess
} from '../../interfaces/configurations'

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS'
export const UPDATE_SETTINGS_FAILED = 'UPDATE_SETTINGS_FAILED'

export const GET_SETTINGS = 'GET_SETTINGS'
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS'
export const GET_SETTINGS_FAILED = 'GET_SETTINGS_FAILED'

export const START_COUNTER = 'START_COUNTER'
export const START_COUNTER_SUCCESS = 'START_COUNTER_SUCCESS'
export const START_COUNTER_FAILED = 'START_COUNTER_FAILED'

export const GET_COUNT = 'GET_COUNT'
export const GET_COUNT_SUCCESS = 'GET_COUNT_SUCCESS'
export const GET_COUNT_FAILED = 'GET_COUNT_FAILED'

export const RESET_COUNTER = 'RESET_COUNTER'
export const RESET_COUNTER_SUCCESS = 'RESET_COUNTER_SUCCESS'
export const RESET_COUNTER_FAILED = 'RESET_COUNTER_FAILED'

export interface UpdateSettings {
  type: typeof UPDATE_SETTINGS
}
export interface UpdateSettingsSuccess {
  type: typeof UPDATE_SETTINGS_SUCCESS
  payload: typeUpdateSettingsSuccess
}
export interface UpdateSettingsFailed {
  type: typeof UPDATE_SETTINGS_FAILED
  payload: any
}
export interface GetSettings {
  type: typeof GET_SETTINGS
}
export interface GetSettingsSuccess {
  type: typeof GET_SETTINGS_SUCCESS
  payload: typeGetSettingsSuccess
}
export interface GetSettingsFailed {
  type: typeof GET_SETTINGS_FAILED
  payload: any
}
export interface StartCounter {
  type: typeof START_COUNTER
}
export interface StartCounterSuccess {
  type: typeof START_COUNTER_SUCCESS
  payload: typeStartCounterSuccess
}
export interface StartCounterFailed {
  type: typeof START_COUNTER_FAILED
  payload: any
}

export interface GetCount {
  type: typeof GET_COUNT
}
export interface GetCountSuccess {
  type: typeof GET_COUNT_SUCCESS
  payload: typeGetCountSuccess
}
export interface GetCountFailed {
  type: typeof GET_COUNT_FAILED
  payload: any
}

export interface ResetCounter {
  type: typeof RESET_COUNTER
}
export interface ResetCounterSuccess {
  type: typeof RESET_COUNTER_SUCCESS
  payload: typeResetCounterSuccess
}
export interface ResetCounterFailed {
  type: typeof RESET_COUNTER_FAILED
  payload: any
}

export type ConfigurationsActionConstants =
  | UpdateSettings
  | UpdateSettingsSuccess
  | UpdateSettingsFailed
  | GetSettings
  | GetSettingsSuccess
  | GetSettingsFailed
  | ResetCounter
  | ResetCounterSuccess
  | ResetCounterFailed
  | StartCounter
  | StartCounterSuccess
  | StartCounterFailed
  | GetCount
  | GetCountSuccess
  | GetCountFailed
