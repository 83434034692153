import {
  typeRequestWithdrawalSuccess,
  typeGetAllWithdrawalsSuccess,
  typeGetWithdrawalsForAUserSuccess,
  typeGetAllPayoutsSuccess,
  typeGetAllReferralsSuccess,
  typeRequestOTPSuccess,
  typeAuthorizeWithdrawlSuccess,
  typeApproveWithdrawalSuccess,
  typeDeclineWithdrawalSuccess
} from '../../interfaces/withdrawals'

export const REQUEST_WITHDRAWAL = 'REQUEST_WITHDRAWAL'
export const REQUEST_WITHDRAWAL_SUCCESS = 'REQUEST_WITHDRAWAL_SUCCESS'
export const REQUEST_WITHDRAWAL_FAILED = 'REQUEST_WITHDRAWAL_FAILED'

export const GET_ALL_WITHDRAWALS = 'GET_ALL_WITHDRAWALS'
export const GET_ALL_WITHDRAWALS_SUCCESS = 'GET_ALL_WITHDRAWALS_SUCCESS'
export const GET_ALL_WITHDRAWALS_FAILED = 'GET_ALL_WITHDRAWALS_FAILED'

export const GET_WITHDRAWALS_FOR_A_USER = 'GET_WITHDRAWALS_FOR_A_USER'
export const GET_WITHDRAWALS_FOR_A_USER_SUCCESS =
  'GET_WITHDRAWALS_FOR_A_USER_SUCCESS'
export const GET_WITHDRAWALS_FOR_A_USER_FAILED =
  'GET_WITHDRAWALS_FOR_A_USER_FAILED'

export const GET_ALL_PAYOUTS = 'GET_ALL_PAYOUTS'
export const GET_ALL_PAYOUTS_SUCCESS = 'GET_ALL_PAYOUTS_SUCCESS'
export const GET_ALL_PAYOUTS_FAILED = 'GET_ALL_PAYOUTS_FAILED'

export const GET_ALL_REFERRALS = 'GET_ALL_REFERRALS'
export const GET_ALL_REFERRALS_SUCCESS = 'GET_ALL_REFERRALS_SUCCESS'
export const GET_ALL_REFERRALS_FAILED = 'GET_ALL_REFERRALS_FAILED'

export const REQUEST_OTP = 'REQUEST_OTP'
export const REQUEST_OTP_SUCCESS = 'REQUEST_OTP_SUCCESS'
export const REQUEST_OTP_FAILED = 'REQUEST_OTP_FAILED'

export const AUTHORIZE_WITHDRAWAL = 'AUTHORIZE_WITHDRAWAL'
export const AUTHORIZE_WITHDRAWAL_SUCCESS = 'AUTHORIZE_WITHDRAWAL_SUCCESS'
export const AUTHORIZE_WITHDRAWAL_FAILED = 'AUTHORIZE_WITHDRAWAL_FAILED'

export const APPROVE_WITHDRAWAL = 'APPROVE_WITHDRAWAL'
export const APPROVE_WITHDRAWAL_SUCCESS = 'APPROVE_WITHDRAWAL_SUCCESS'
export const APPROVE_WITHDRAWAL_FAILED = 'APPROVE_WITHDRAWAL_FAILED'

export const DECLINE_WITHDRAWAL = 'DECLINE_WITHDRAWAL'
export const DECLINE_WITHDRAWAL_SUCCESS = 'DECLINE_WITHDRAWAL_SUCCESS'
export const DECLINE_WITHDRAWAL_FAILED = 'DECLINE_WITHDRAWAL_FAILED'

export interface RequestWithdrawal {
  type: typeof REQUEST_WITHDRAWAL
}
export interface RequestWithdrawalSuccess {
  type: typeof REQUEST_WITHDRAWAL_SUCCESS
  payload: typeRequestWithdrawalSuccess
}
export interface RequestWithdrawalFailed {
  type: typeof REQUEST_WITHDRAWAL_FAILED
  payload: any
}
export interface GetAllWithdrawals {
  type: typeof GET_ALL_WITHDRAWALS
}
export interface GetAllWithdrawalsSuccess {
  type: typeof GET_ALL_WITHDRAWALS_SUCCESS
  payload: typeGetAllWithdrawalsSuccess
}
export interface GetAllWithdrawalsFailed {
  type: typeof GET_ALL_WITHDRAWALS_FAILED
  payload: any
}
export interface GetWithdrawalsForAUser {
  type: typeof GET_WITHDRAWALS_FOR_A_USER
}
export interface GetWithdrawalsForAUserSuccess {
  type: typeof GET_WITHDRAWALS_FOR_A_USER_SUCCESS
  payload: typeGetWithdrawalsForAUserSuccess
}
export interface GetWithdrawalsForAUserFailed {
  type: typeof GET_WITHDRAWALS_FOR_A_USER_FAILED
  payload: any
}

export interface GetAllPayouts {
  type: typeof GET_ALL_PAYOUTS
}
export interface GetAllPayoutsSuccess {
  type: typeof GET_ALL_PAYOUTS_SUCCESS
  payload: typeGetAllPayoutsSuccess
}
export interface GetAllPayoutsFailed {
  type: typeof GET_ALL_PAYOUTS_FAILED
  payload: any
}

export interface GetAllReferrals {
  type: typeof GET_ALL_REFERRALS
}
export interface GetAllReferralsSuccess {
  type: typeof GET_ALL_REFERRALS_SUCCESS
  payload: typeGetAllReferralsSuccess
}
export interface GetAllReferralsFailed {
  type: typeof GET_ALL_REFERRALS_FAILED
  payload: any
}

export interface RequestOTP {
  type: typeof REQUEST_OTP
}
export interface RequestOTPSuccess {
  type: typeof REQUEST_OTP_SUCCESS
  payload: typeRequestOTPSuccess
}
export interface RequestOTPFailed {
  type: typeof REQUEST_OTP_FAILED
  payload: any
}

export interface AuthorizeWithdrawal {
  type: typeof AUTHORIZE_WITHDRAWAL
}
export interface AuthorizeWithdrawalSuccess {
  type: typeof AUTHORIZE_WITHDRAWAL_SUCCESS
  payload: typeAuthorizeWithdrawlSuccess
}
export interface AuthorizeWithdrawalFailed {
  type: typeof AUTHORIZE_WITHDRAWAL_FAILED
  payload: any
}

export interface ApproveWithdrawal {
  type: typeof APPROVE_WITHDRAWAL
}
export interface ApproveWithdrawalSuccess {
  type: typeof APPROVE_WITHDRAWAL_SUCCESS
  payload: typeApproveWithdrawalSuccess
}
export interface ApproveWithdrawalFailed {
  type: typeof APPROVE_WITHDRAWAL_FAILED
  payload: any
}

export interface DeclineWithdrawal {
  type: typeof DECLINE_WITHDRAWAL
}
export interface DeclineWithdrawalSuccess {
  type: typeof DECLINE_WITHDRAWAL_SUCCESS
  payload: typeDeclineWithdrawalSuccess
}
export interface DeclineWithdrawalFailed {
  type: typeof DECLINE_WITHDRAWAL_FAILED
  payload: any
}

export type WithdrawalActionConstants =
  | RequestWithdrawal
  | RequestWithdrawalSuccess
  | RequestWithdrawalFailed
  | GetAllWithdrawals
  | GetAllWithdrawalsSuccess
  | GetAllWithdrawalsFailed
  | AuthorizeWithdrawal
  | AuthorizeWithdrawalSuccess
  | AuthorizeWithdrawalFailed
  | GetWithdrawalsForAUser
  | GetWithdrawalsForAUserSuccess
  | GetWithdrawalsForAUserFailed
  | GetAllPayouts
  | GetAllPayoutsSuccess
  | GetAllPayoutsFailed
  | GetAllReferrals
  | GetAllReferralsSuccess
  | GetAllReferralsFailed
  | RequestOTP
  | RequestOTPSuccess
  | RequestOTPFailed
  | ApproveWithdrawal
  | ApproveWithdrawalSuccess
  | ApproveWithdrawalFailed
  | DeclineWithdrawal
  | DeclineWithdrawalSuccess
  | DeclineWithdrawalFailed
