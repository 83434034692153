import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { IAppState } from '../../interfaces/app-state'
import { toast } from 'react-toastify'

type Props = {
  alerts?: any
}

const notification: Function = (
  color: string,
  message: string,
  timeOut: number
) => {
  switch (color) {
    case 'success':
      toast.success(message, { autoClose: timeOut })
      break
    case 'error':
      toast.error(message, { autoClose: timeOut })
      break
    case 'info':
      toast.info(message, { autoClose: timeOut })
      break
    case 'warning':
      toast.warning(message, { autoClose: timeOut })
      break
    case 'danger':
      toast.error(message, { autoClose: timeOut })
      break
    default:
      toast.info(message, { autoClose: timeOut })
  }
}

export const ToastAlert = ({ alerts }: Props) => {
  return (
    <Fragment>
      {alerts !== null && alerts?.length > 0
        ? notification(alerts[0].alertType, alerts[0].msg, alerts[0].timeOut)
        : ''}
    </Fragment>
  )
}

const mapStateToProps = (state: IAppState) => ({
  alerts: state.alert
})

export default connect(mapStateToProps)(ToastAlert)
