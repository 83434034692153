import {
  typeGetUserSuccess,
  typeBlockUserSuccess,
  typeDeleteUserSuccess,
  typeGetAllUsersSuccess,
  typeLoggedInUserSuccess,
  typeUploadAvatarSuccess,
  typeSearchGetUserSuccess,
  typeSearchUserSuccess,
  typeChangePasswordSuccess,
  typeUpdateUserProfileSuccess,
  typeUpdateBankDetailsSuccess,
  typeGetAllInviteesSuccess,
  typeUpdateUserBVNDetailsSuccess,
} from '../../interfaces/users'

export const LOGGED_IN_USER = 'LOGGED_IN_USER'
export const LOGGED_IN_USER_SUCCESS = 'LOGGED_IN_USER_SUCCESS'
export const LOGGED_IN_USER_FAILED = 'LOGGED_IN_USER_FAILED'

export const GET_USER = 'GET_USER'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILED = 'GET_USER_FAILED'

export const SEARCH_GET_USER = 'SEARCH_GET_USER'
export const SEARCH_GET_USER_SUCCESS = 'SEARCH_GET_USER_SUCCESS'
export const SEARCH_GET_USER_FAILED = 'SEARCH_GET_USER_FAILED'


export const SEARCH_USER = 'SEARCH_USER'
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS'
export const SEARCH_USER_FAILED = 'SEARCH_USER_FAILED'

export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'
export const GET_ALL_USERS_FAILED = 'GET_ALL_USERS_FAILED'

export const GET_ALL_INVITEES = 'GET_ALL_INVITEES'
export const GET_ALL_INVITEES_SUCCESS = 'GET_ALL_INVITEES_SUCCESS'
export const GET_ALL_INVITEES_FAILED = 'GET_ALL_INVITEES_FAILED'

export const UPDATE_BANK_DETAILS = 'UPDATE_BANK_DETAILS'
export const UPDATE_BANK_DETAILS_SUCCESS = 'UPDATE_BANK_DETAILS_SUCCESS'
export const UPDATE_BANK_DETAILS_FAILED = 'UPDATE_BANK_DETAILS_FAILED'

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS'
export const UPDATE_USER_PROFILE_FAILED = 'UPDATE_USER_PROFILE_FAILED'

export const UPDATE_USER_BVN_DETAILS = 'UPDATE_USER_BVN_DETAILS'
export const UPDATE_USER_BVN_DETAILS_SUCCESS = 'UPDATE_USER_BVN_DETAILS_SUCCESS'
export const UPDATE_USER_BVN_DETAILS_FAILED = 'UPDATE_USER_BVN_DETAILS_FAILED'

export const BLOCK_USER = 'BLOCK_USER'
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS'
export const BLOCK_USER_FAILED = 'BLOCK_USER_FAILED'

export const UPLOAD_USER_AVATAR = 'UPLOAD_USER_AVATAR'
export const UPLOAD_USER_AVATAR_SUCCESS = 'UPLOAD_USER_AVATAR_SUCCESS'
export const UPLOAD_USER_AVATAR_FAILED = 'UPLOAD_USER_AVATAR_FAILED'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'

export interface LoggedInUser {
  type: typeof LOGGED_IN_USER
}
export interface LoggedInUserSuccess {
  type: typeof LOGGED_IN_USER_SUCCESS
  payload: typeLoggedInUserSuccess
}
export interface LoggedInUserFailed {
  type: typeof LOGGED_IN_USER_FAILED
  payload: any
}

export interface GetUser {
  type: typeof GET_USER
}
export interface GetUserSuccess {
  type: typeof GET_USER_SUCCESS
  payload: typeGetUserSuccess
}
export interface GetUserFailed {
  type: typeof GET_USER_FAILED
  payload: any
}

export interface SearchGetUser {
  type: typeof SEARCH_GET_USER
}
export interface SearchGetUserSuccess {
  type: typeof SEARCH_GET_USER_SUCCESS
  payload: typeSearchGetUserSuccess
}
export interface SearchGetUserFailed {
  type: typeof SEARCH_GET_USER_FAILED
  payload: any
}

export interface SearchUser {
  type: typeof SEARCH_USER
}
export interface SearchUserSuccess {
  type: typeof SEARCH_USER_SUCCESS
  payload: typeSearchUserSuccess
}
export interface SearchUserFailed {
  type: typeof SEARCH_USER_FAILED
  payload: any
}

export interface GetAllUsers {
  type: typeof GET_ALL_USERS
}
export interface GetAllUsersSuccess {
  type: typeof GET_ALL_USERS_SUCCESS
  payload: typeGetAllUsersSuccess
}
export interface GetAllUsersFailed {
  type: typeof GET_ALL_USERS_FAILED
  payload: any
}

export interface GetAllInvitees {
  type: typeof GET_ALL_INVITEES
}
export interface GetAllInviteesSuccess {
  type: typeof GET_ALL_INVITEES_SUCCESS
  payload: typeGetAllInviteesSuccess
}
export interface GetAllInviteesFailed {
  type: typeof GET_ALL_INVITEES_FAILED
  payload: any
}

export interface UpdateBankDetails {
  type: typeof UPDATE_BANK_DETAILS
}
export interface UpdateBankDetailsSuccess {
  type: typeof UPDATE_BANK_DETAILS_SUCCESS
  payload: typeUpdateBankDetailsSuccess
}
export interface UpdateBankDetailsFailed {
  type: typeof UPDATE_BANK_DETAILS_FAILED
  payload: any
}

export interface UpdateUserProfile {
  type: typeof UPDATE_USER_PROFILE
}
export interface UpdateUserProfileSuccess {
  type: typeof UPDATE_USER_PROFILE_SUCCESS
  payload: typeUpdateUserProfileSuccess
}
export interface UpdateUserProfileFailed {
  type: typeof UPDATE_USER_PROFILE_FAILED
  payload: any
}

export interface UpdateUserBVNDetails {
  type: typeof UPDATE_USER_BVN_DETAILS
}
export interface UpdateUserBVNDetailsSuccess {
  type: typeof UPDATE_USER_BVN_DETAILS_SUCCESS
  payload: typeUpdateUserBVNDetailsSuccess
}
export interface UpdateUserBVNDetailsFailed {
  type: typeof UPDATE_USER_BVN_DETAILS_FAILED
  payload: any
}

export interface DeleteUser {
  type: typeof DELETE_USER
}
export interface DeleteUserSuccess {
  type: typeof DELETE_USER_SUCCESS
  payload: typeDeleteUserSuccess
}
export interface DeleteUserFailed {
  type: typeof DELETE_USER_FAILED
  payload: any
}

export interface BlockUser {
  type: typeof BLOCK_USER
}
export interface BlockUserSuccess {
  type: typeof BLOCK_USER_SUCCESS
  payload: typeBlockUserSuccess
}
export interface BlockUserFailed {
  type: typeof BLOCK_USER_FAILED
  payload: any
}

export interface UploadUserAvatar {
  type: typeof UPLOAD_USER_AVATAR
}
export interface UploadUserAvatarSuccess {
  type: typeof UPLOAD_USER_AVATAR_SUCCESS
  payload: typeUploadAvatarSuccess
}
export interface UploadUserAvatarFailed {
  type: typeof UPLOAD_USER_AVATAR_FAILED
  payload: any
}

export interface ChangePassword {
  type: typeof CHANGE_PASSWORD
}
export interface ChangePasswordSuccess {
  type: typeof CHANGE_PASSWORD_SUCCESS
  payload: typeChangePasswordSuccess
}
export interface ChangePasswordFailed {
  type: typeof CHANGE_PASSWORD_FAILED
  payload: any
}

export type UsersActionConstants =
  | LoggedInUser
  | LoggedInUserSuccess
  | LoggedInUserFailed
  | GetUser
  | GetUserSuccess
  | GetUserFailed
  | GetAllUsers
  | GetAllUsersSuccess
  | GetAllUsersFailed
  | UploadUserAvatar
  | UploadUserAvatarSuccess
  | UploadUserAvatarFailed
  | UpdateUserProfile
  | UpdateUserProfileSuccess
  | UpdateUserProfileFailed
  | UpdateUserBVNDetails
  | UpdateUserBVNDetailsSuccess
  | UpdateUserBVNDetailsFailed
  | DeleteUser
  | DeleteUserSuccess
  | DeleteUserFailed
  | SearchGetUser
  | SearchGetUserSuccess
  | SearchGetUserFailed
  | SearchUser
  | SearchUserSuccess
  | SearchUserFailed
  | UpdateBankDetails
  | UpdateBankDetailsSuccess
  | UpdateBankDetailsFailed
  | BlockUser
  | BlockUserSuccess
  | BlockUserFailed
  | ChangePassword
  | ChangePasswordSuccess
  | ChangePasswordFailed
  | GetAllInvitees
  | GetAllInviteesSuccess
  | GetAllInviteesFailed
