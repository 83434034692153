import React from 'react'

import Breadcrumbs from './Breadcrumbs'
import classNames from '../../../utils/class-names'
import { IBreadcrumb } from '../../../interfaces/page'

import './Footer.scss'

type Props = {
  loaded?: boolean
  boxed: boolean
  layout: string
  breadcrumbs: IBreadcrumb[]
  openModal?: () => void
}

const Footer = ({ boxed, loaded = false, layout, breadcrumbs }: Props) => {
  let footerClasses = classNames({
    loaded,
    boxed
  })

  return (
    <div className={`footer ${footerClasses}`}>
      <div className='footer-wrap'>
        <div className='row align-items-center' style={{ height: '100%' }}>
          <div className='col-12 col-md-6 d-none d-md-block'>
            <Breadcrumbs layout={layout} breadcrumbs={breadcrumbs} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
