export const DEFAULT_WITHDRAWALS = {
  request_withdrawal: null,
  request_withdrawal_loading: false,
  get_all_withdrawals: null,
  get_all_withdrawals_loading: false,
  get_withdrawals_for_a_user: null,
  get_withdrawals_for_a_user_loading: false,
  get_all_payouts: null,
  get_all_payouts_pagination: null,
  get_all_payouts_loading: false,
  get_all_referrals: null,
  get_all_referrals_loading: false,
  request_otp: null,
  request_otp_loading: false,
  authorize_withdrawal: null,
  authorize_withdrawal_loading: false,
  approve_withdrawal: null,
  approve_withdrawal_loading: false,
  decline_withdrawal: null,
  decline_withdrawal_loading: false,
  error: {
    request_withdrawal_error: null,
    get_all_withdrawals_error: null,
    get_withdrawals_for_a_user_error: null,
    get_all_payouts_error: null,
    get_all_referrals_error: null,
    request_otp_error: null,
    authorize_withdrawal_error: null,
    approve_withdrawal_error: null,
    decline_withdrawal_error: null
  }
}
