import axios from '../../utils/axios'
import { axiosWithoutHeaders } from '../../utils/axios'

import { setAlert } from '../alert/actions'
import { Dispatch } from 'redux'
import {
  GET_USER_HOME_DATA,
  GetUserHomeData,
  GET_USER_HOME_DATA_SUCCESS,
  GetUserHomeDataSuccess,
  GET_USER_HOME_DATA_FAILED,
  GetUserHomeDataFailed,
  GET_USER_SETTINGS,
  GetUserSettings,
  GET_USER_SETTINGS_SUCCESS,
  GetUserSettingsSuccess,
  GET_USER_SETTINGS_FAILED,
  GetUserSettingsFailed,
  FUND_USER_LEND_ME,
  FundUserLendMe,
  FUND_USER_LEND_ME_SUCCESS,
  FundUserLendMeSuccess,
  FUND_USER_LEND_ME_FAILED,
  FundUserLendMeFailed,
  GET_USER_LEND_ME_DEPOSIT_HISTORY,
  GetUserLendMeDepositHistory,
  GET_USER_LEND_ME_DEPOSIT_HISTORY_SUCCESS,
  GetUserLendMeDepositHistorySuccess,
  GET_USER_LEND_ME_DEPOSIT_HISTORY_FAILED,
  GetUserLendMeDepositHistoryFailed,
  REQUEST_USER_LOAN,
  RequestUserLoan,
  REQUEST_USER_LOAN_SUCCESS,
  RequestUserLoanSuccess,
  REQUEST_USER_LOAN_FAILED,
  RequestUserLoanFailed,
  GET_USER_LOAN_HISTORY,
  GetUserLoanHistory,
  GET_USER_LOAN_HISTORY_SUCCESS,
  GetUserLoanHistorySuccess,
  GET_USER_LOAN_HISTORY_FAILED,
  GetUserLoanHistoryFailed,
  GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY,
  GetUserLendMeBalanceTransactionHistory,
  GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY_SUCCESS,
  GetUserLendMeBalanceTransactionHistorySuccess,
  GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY_FAILED,
  GetUserLendMeBalanceTransactionHistoryFailed,
  WITHDRAW_FROM_LEND_ME_WALLET,
  WithDrawFromLendMeWallet,
  WITHDRAW_FROM_LEND_ME_WALLET_SUCCESS,
  WithDrawFromLendMeWalletSuccess,
  WITHDRAW_FROM_LEND_ME_WALLET_FAILED,
  WithDrawFromLendMeWalletFailed,
  // ======================================================================

  GET_ADMIN_HOME_DATA,
  GetAdminHomeData,
  GET_ADMIN_HOME_DATA_SUCCESS,
  GetAdminHomeDataSuccess,
  GET_ADMIN_HOME_DATA_FAILED,
  GetAdminHomeDataFailed,
  GET_ADMIN_SETTINGS,
  GetAdminSettings,
  GET_ADMIN_SETTINGS_SUCCESS,
  GetAdminSettingsSuccess,
  GET_ADMIN_SETTINGS_FAILED,
  GetAdminSettingsFailed,
  UPDATE_SETTINGS,
  UpdateSettings,
  UPDATE_SETTINGS_SUCCESS,
  UpdateSettingsSuccess,
  UPDATE_SETTINGS_FAILED,
  UpdateSettingsFailed,
  GET_LOAN_REQUESTS,
  GetLoanRequests,
  GET_LOAN_REQUESTS_SUCCESS,
  GetLoanRequestsSuccess,
  GET_LOAN_REQUESTS_FAILED,
  GetLoanRequestsFailed,
  UPDATE_LOAN_REQUEST_STATUS,
  UpdateLoanRequestStatus,
  UPDATE_LOAN_REQUEST_STATUS_SUCCESS,
  UpdateLoanRequestStatusSuccess,
  UPDATE_LOAN_REQUEST_STATUS_FAILED,
  UpdateLoanRequestStatusFailed,
  UPDATE_LOAN_PAYMENT,
  UpdateLoanPayment,
  UPDATE_LOAN_PAYMENT_SUCCESS,
  UpdateLoanPaymentSuccess,
  UPDATE_LOAN_PAYMENT_FAILED,
  UpdateLoanPaymentFailed,
  GET_LENDERS,
  GetLenders,
  GET_LENDERS_SUCCESS,
  GetLendersSuccess,
  GET_LENDERS_FAILED,
  GetLendersFailed,
  FILE_UPLOAD,
  FileUpload,
  FILE_UPLOAD_SUCCESS,
  FileUploadSuccess,
  FILE_UPLOAD_FAILED,
  FileUploadFailed,
} from './constants'

type formDataType = any

export const getUserHomeDataRequest = () => async (
    dispatch: Dispatch<
      | GetUserHomeData
      | GetUserHomeDataSuccess
      | GetUserHomeDataFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: GET_USER_HOME_DATA })
      const res = await axios.get(`/lendme/home`)
      dispatch({
        type: GET_USER_HOME_DATA_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_USER_HOME_DATA_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_USER_HOME_DATA_FAILED,
          payload: err.message
        })
      }
    }
  }


export const getUserSettingsRequest = () => async (
    dispatch: Dispatch<
      | GetUserSettings
      | GetUserSettingsSuccess
      | GetUserSettingsFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: GET_USER_SETTINGS })
      const res = await axios.get(`/lendme/setting`)

      dispatch({
        type: GET_USER_SETTINGS_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_USER_SETTINGS_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_USER_SETTINGS_FAILED,
          payload: err.message
        })
      }
    }
  }


export const fundUserLendMeRequest = (formData: formDataType) => async (
    dispatch: Dispatch<
      | FundUserLendMe
      | FundUserLendMeSuccess
      | FundUserLendMeFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: FUND_USER_LEND_ME })
      const res = await axios.post(`/lendme/fund`, formData)

      dispatch({
        type: FUND_USER_LEND_ME_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: FUND_USER_LEND_ME_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: FUND_USER_LEND_ME_FAILED,
          payload: err.message
        })
      }
    }
  }

export const getUserLendMeDepositHistoryRequest = (page?: number | undefined, pageSize?: number | undefined, searchValue?: any, query?: any) => async (
    dispatch: Dispatch<
      GetUserLendMeDepositHistory | GetUserLendMeDepositHistorySuccess | GetUserLendMeDepositHistoryFailed | any
    >
  ) => {
    try {
      dispatch({ type: GET_USER_LEND_ME_DEPOSIT_HISTORY })
      let res: any;

      if (searchValue && query) {
        res = await axios.get(`/lendme/fund?page=${1}&pageSize=${20}&${query}=${searchValue}`)
      } else {
        res = await axios.get(`/lendme/fund?page=${page ?? 1}&pageSize=${pageSize ?? 20}`)
      }

      dispatch({
        type: GET_USER_LEND_ME_DEPOSIT_HISTORY_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_USER_LEND_ME_DEPOSIT_HISTORY_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_USER_LEND_ME_DEPOSIT_HISTORY_FAILED,
          payload: err.message
        })
      }
    }
  }

export const requestUserLoanRequest = (formDataDetails: formDataType) => async (
    dispatch: Dispatch<
      RequestUserLoan | RequestUserLoanSuccess | RequestUserLoanFailed | any
    >
  ) => {
    try {
      dispatch({ type: REQUEST_USER_LOAN })

      const { 
        nin,
        photo,
        surety_nin,
        surety_photo, 
        amount,
        ...rest 
      } = formDataDetails;

      const imageFiles = [
        nin.file,
        photo.file,
        surety_nin.file,
        surety_photo.file
      ]

      // Upload images to the first URL
      const uploadedImageURLs = [];
      for (const imageFile of imageFiles) {

        // const response = await axios.put('/file/upload', formData);

        const response2 = await axios.get(`/file/signed-url?file_name=${imageFile.name}&file_type=${imageFile.type}`);
        await axiosWithoutHeaders.put(response2.data.data.signedRequest, imageFile);

        // // Assuming the response contains a 'data' object with a 'URL' property
        uploadedImageURLs.push(response2.data.data.url);
      }

      // Variables containing response data for each image
      const [url1, url2, url3, url4] = uploadedImageURLs;


      console.log({ uploadedImageURLs })

      const payload = {
        'nin': url1,
        'photo': url2,
        'surety_nin': url3,
        'surety_photo': url4,
        'amount': Number(amount),
        ...rest
      }
     
      const res = await axios.post(`/lendme/loan/requests`, payload)

      dispatch(setAlert(res?.data?.message ?? 'Request successful', 'success'))
      dispatch({
        type: REQUEST_USER_LOAN_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: REQUEST_USER_LOAN_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: REQUEST_USER_LOAN_FAILED,
          payload: err.message
        })
      }
    }
  }

export const getUserLoanHistoryRequest = (page?: number | undefined, pageSize?: number | undefined, searchValue?: any, query?: any) => async (
    dispatch: Dispatch<GetUserLoanHistory | GetUserLoanHistorySuccess | GetUserLoanHistoryFailed | any>
  ) => {
    try {
      dispatch({ type: GET_USER_LOAN_HISTORY })
      let res: any;

      if (searchValue && query) {
        res = await axios.get(`/lendme/loan/requests?page=${1}&pageSize=${20}&${query}=${searchValue}`)
      } else {
        res = await axios.get(`/lendme/loan/requests?page=${page ?? 1}&pageSize=${pageSize ?? 20}`)
      }

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: GET_USER_LOAN_HISTORY_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_USER_LOAN_HISTORY_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_USER_LOAN_HISTORY_FAILED,
          payload: err.message
        })
      }
    }
  }

export const getUserLendMeBalanceTransactionHistoryRequest = (page?: number | undefined, pageSize?: number | undefined, searchValue?: any, query?: any) => async (
    dispatch: Dispatch<
      | GetUserLendMeBalanceTransactionHistory
      | GetUserLendMeBalanceTransactionHistorySuccess
      | GetUserLendMeBalanceTransactionHistoryFailed
      | any
    >
  ) => {
    try {
      dispatch({ type: GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY })
      let res: any;

      if (searchValue && query) {
        res = await axios.get(`/lendme/transactions?page=${1}&pageSize=${20}&${query}=${searchValue}`)
      } else {
        res = await axios.get(`/lendme/transactions?page=${page ?? 1}&pageSize=${pageSize ?? 20}`)
      }

      dispatch(setAlert(res.data.message, 'success'))
      dispatch({
        type: GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY_SUCCESS,
        payload: res
      })
    } catch (err: any) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, 'warning'))
        dispatch({
          type: GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY_FAILED,
          payload: err.response.data.message
        })
      } else {
        dispatch(setAlert(err.message, 'error'))
        dispatch({
          type: GET_USER_LEND_ME_BALANCE_TRANSACTION_HISTORY_FAILED,
          payload: err.message
        })
      }
    }
  }

export const withdrawFromLendMeWalletRequest = (payload) => async (
  dispatch: Dispatch<
    | WithDrawFromLendMeWallet
    | WithDrawFromLendMeWalletSuccess
    | WithDrawFromLendMeWalletFailed
    | any
  >
) => {
  try {
    dispatch({ type: WITHDRAW_FROM_LEND_ME_WALLET })
    let res: any;

    res = await axios.post(`/lendme/withdraw`, payload)

    dispatch(setAlert(res.data.message, 'success'))
    dispatch({
      type: WITHDRAW_FROM_LEND_ME_WALLET_SUCCESS,
      payload: res
    })
  } catch (err: any) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, 'warning'))
      dispatch({
        type: WITHDRAW_FROM_LEND_ME_WALLET_FAILED,
        payload: err.response.data.message
      })
    } else {
      dispatch(setAlert(err.message, 'error'))
      dispatch({
        type: WITHDRAW_FROM_LEND_ME_WALLET_FAILED,
        payload: err.message
      })
    }
  }
}

  // ========================================================================


export const getAdminHomeDataRequest = () => async (
  dispatch: Dispatch<
    | GetAdminHomeData
    | GetAdminHomeDataSuccess
    | GetAdminHomeDataFailed
    | any
  >
) => {
  try {
    dispatch({ type: GET_ADMIN_HOME_DATA })
    const res = await axios.get(`/lendme/home/admin`)

    dispatch(setAlert(res.data.message, 'success'))
    dispatch({
      type: GET_ADMIN_HOME_DATA_SUCCESS,
      payload: res
    })
  } catch (err: any) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, 'warning'))
      dispatch({
        type: GET_ADMIN_HOME_DATA_FAILED,
        payload: err.response.data.message
      })
    } else {
      dispatch(setAlert(err.message, 'error'))
      dispatch({
        type: GET_ADMIN_HOME_DATA_FAILED,
        payload: err.message
      })
    }
  }
}

export const getAdminHomeSettingsRequest = () => async (
  dispatch: Dispatch<
    | GetAdminSettings
    | GetAdminSettingsSuccess
    | GetAdminSettingsFailed
    | any
  >
) => {
  try {
    dispatch({ type: GET_ADMIN_SETTINGS })
    const res = await axios.get(`/lendme/setting`)

    dispatch(setAlert(res.data.message, 'success'))
    dispatch({
      type: GET_ADMIN_SETTINGS_SUCCESS,
      payload: res
    })
  } catch (err: any) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, 'warning'))
      dispatch({
        type: GET_ADMIN_SETTINGS_FAILED,
        payload: err.response.data.message
      })
    } else {
      dispatch(setAlert(err.message, 'error'))
      dispatch({
        type: GET_ADMIN_SETTINGS_FAILED,
        payload: err.message
      })
    }
  }
}

export const updateSettingsRequest = (formData: formDataType) => async (
  dispatch: Dispatch<
    | UpdateSettings
    | UpdateSettingsSuccess
    | UpdateSettingsFailed
    | any
  >
) => {
  try {
    dispatch({ type: UPDATE_SETTINGS })
    const res = await axios.put(`/lendme/setting`, formData)

    dispatch(setAlert(res.data.message, 'success'))
    dispatch({
      type: UPDATE_SETTINGS_SUCCESS,
      payload: res
    })
  } catch (err: any) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, 'warning'))
      dispatch({
        type: UPDATE_SETTINGS_FAILED,
        payload: err.response.data.message
      })
    } else {
      dispatch(setAlert(err.message, 'error'))
      dispatch({
        type: UPDATE_SETTINGS_FAILED,
        payload: err.message
      })
    }
  }
}

export const getLoanRequest = (page?: number | undefined, pageSize?: number | undefined, searchValue?: any, query?: any) => async (
  dispatch: Dispatch<
    | GetLoanRequests
    | GetLoanRequestsSuccess
    | GetLoanRequestsFailed
    | any
  >
) => {
  try {
    dispatch({ type: GET_LOAN_REQUESTS })
    let res: any;

    if (searchValue && query) {
      res = await axios.get(`/lendme/loan/requests/all?page=${1}&pageSize=${20}&${query}=${searchValue}`)
    } else {
      res = await axios.get(`/lendme/loan/requests/all?page=${page ?? 1}&pageSize=${pageSize ?? 20}`)
    }

    dispatch(setAlert(res.data.message, 'success'))
    dispatch({
      type: GET_LOAN_REQUESTS_SUCCESS,
      payload: res
    })
  } catch (err: any) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, 'warning'))
      dispatch({
        type: GET_LOAN_REQUESTS_FAILED,
        payload: err.response.data.message
      })
    } else {
      dispatch(setAlert(err.message, 'error'))
      dispatch({
        type: GET_LOAN_REQUESTS_FAILED,
        payload: err.message
      })
    }
  }
}

export const updateLoanRequestStatus = (payload: any) => async (
  dispatch: Dispatch<
    | UpdateLoanRequestStatus
    | UpdateLoanRequestStatusSuccess
    | UpdateLoanRequestStatusFailed
    | any
  >
) => {
  try {
    dispatch({ type: UPDATE_LOAN_REQUEST_STATUS })
    const res = await axios.put(`/lendme/loan/requests/update`, payload)

    dispatch(setAlert(res.data.message, 'success'))
    dispatch({
      type: UPDATE_LOAN_REQUEST_STATUS_SUCCESS,
      payload: res
    })
  } catch (err: any) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, 'warning'))
      dispatch({
        type: UPDATE_LOAN_REQUEST_STATUS_FAILED,
        payload: err.response.data.message
      })
    } else {
      dispatch(setAlert(err.message, 'error'))
      dispatch({
        type: UPDATE_LOAN_REQUEST_STATUS_FAILED,
        payload: err.message
      })
    }
  }
}

export const updateLoanPaymentRequest = (payload) => async (
  dispatch: Dispatch<
    | UpdateLoanPayment
    | UpdateLoanPaymentSuccess
    | UpdateLoanPaymentFailed
    | any
  >
) => {
  try {
    dispatch({ type: UPDATE_LOAN_PAYMENT })
    const res = await axios.put(`/lendme/loan/payment`, payload)

    dispatch(setAlert(res.data.message, 'success'))
    dispatch({
      type: UPDATE_LOAN_PAYMENT_SUCCESS,
      payload: res
    })
  } catch (err: any) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, 'warning'))
      dispatch({
        type: UPDATE_LOAN_PAYMENT_FAILED,
        payload: err.response.data.message
      })
    } else {
      dispatch(setAlert(err.message, 'error'))
      dispatch({
        type: UPDATE_LOAN_PAYMENT_FAILED,
        payload: err.message
      })
    }
  }
}


export const getLendersRequest = (page?: number | undefined, pageSize?: number | undefined, searchValue?: any, query?: any) => async (
  dispatch: Dispatch<
    | GetLenders
    | GetLendersSuccess
    | GetLendersFailed
    | any
  >
) => {
  try {
    dispatch({ type: GET_LENDERS })

    let res: any;

    if (searchValue && query) {
      res = await axios.get(`/lendme/fund/all?page=${1}&pageSize=${20}&${query}=${searchValue}`)
    } else {
      res = await axios.get(`/lendme/fund/all?page=${page ?? 1}&pageSize=${pageSize ?? 20}`)
    }

    dispatch(setAlert(res.data.message, 'success'))
    dispatch({
      type: GET_LENDERS_SUCCESS,
      payload: res
    })
  } catch (err: any) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, 'warning'))
      dispatch({
        type: GET_LENDERS_FAILED,
        payload: err.response.data.message
      })
    } else {
      dispatch(setAlert(err.message, 'error'))
      dispatch({
        type: GET_LENDERS_FAILED,
        payload: err.message
      })
    }
  }
}

export const fileUploadRequest = (formData: any) => async (
  dispatch: Dispatch<
    | FileUpload
    | FileUploadSuccess
    | FileUploadFailed
    | any
  >
) => {
  try {
    dispatch({ type: FILE_UPLOAD })
    const res = await axios.put(`/file/upload`, formData)

    dispatch(setAlert(res.data.message, 'success'))
    dispatch({
      type: FILE_UPLOAD_SUCCESS,
      payload: res
    })
  } catch (err: any) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, 'warning'))
      dispatch({
        type: FILE_UPLOAD_FAILED,
        payload: err.response.data.message
      })
    } else {
      dispatch(setAlert(err.message, 'error'))
      dispatch({
        type: FILE_UPLOAD_FAILED,
        payload: err.message
      })
    }
  }
}



// try {
//   console.log(data, id);
//   if ((typeof data.cover_image === 'object' && data.cover_image !== null) || (data.cover_image instanceof Array)) {
//     const image = yield axios.get(`/file/pre-sign?file_name=${data.cover_image.name}&file_type=${data.cover_image.type}`);
//     yield axios.put(image.data.data.signedRequest, data.cover_image);
//     data.cover_image = image.data.data.url
//   };

//   if ((typeof data.content === 'object' && data.content !== null) || (data.content instanceof Array)) {
//     const image = yield axios.get(`/file/pre-sign?file_name=${data.content.name}&file_type=${data.content.type}`);
//     yield axios.put(image.data.data.signedRequest, data.content);
//     data.content = image.data.data.url
//   };

//   if (data.subscription_type === 'free') {
//     data.price = 0;
//   };

//   const response = yield axios.patch(`sermon/${id}/update`, data);
//   yield put(editSermonSuccess(response.data));
//   yield put(setAlert({ message: response.data.message }, 'success'));
// } catch (error) {

// }