export const DEFAULT_SETTINGS = {
  boxed: true,
  loaded: false,
  layout: 'app', // horizontal | vertical
  sidebarBg: '#fbfbfb', // string
  sidebarColor: '#1F2022', // string
  sidebarOpened: false, // boolean
  topbarBg: '#ffffff', // string
  topbarColor: '#1F2022' // string
}
