import {
  typeCreditWalletSuccess,
  typeGetUserBalanceSuccess,
  typeTransferBetweenWalletSuccess,
  typeUpdateWalletBalanceSuccess
} from '../../interfaces/wallet'

export const CREDIT_WALLET = 'CREDIT_WALLET'
export const CREDIT_WALLET_SUCCESS = 'CREDIT_WALLET_SUCCESS'
export const CREDIT_WALLET_FAILED = 'CREDIT_WALLET_FAILED'

export const GET_USER_BALANCE = 'GET_USER_BALANCE'
export const GET_USER_BALANCE_SUCCESS = 'GET_USER_BALANCE_SUCCESS'
export const GET_USER_BALANCE_FAILED = 'GET_USER_BALANCE_FAILED'

export const TRANSFER_BETWEEN_WALLETS = 'TRANSFER_BETWEEN_WALLETS'
export const TRANSFER_BETWEEN_WALLETS_SUCCESS =
  'TRANSFER_BETWEEN_WALLETS_SUCCESS'
export const TRANSFER_BETWEEN_WALLETS_FAILED = 'TRANSFER_BETWEEN_WALLETS_FAILED'

export const UPDATE_WALLET_BALANCE = 'UPDATE_WALLET_BALANCE'
export const UPDATE_WALLET_BALANCE_SUCCESS = 'UPDATE_WALLET_BALANCE_SUCCESS'
export const UPDATE_WALLET_BALANCE_FAILED = 'TRANSFER_BETWEEN_WALLETS_FAILED'
export interface CreditWallet {
  type: typeof CREDIT_WALLET
}
export interface CreditWalletSuccess {
  type: typeof CREDIT_WALLET_SUCCESS
  payload: typeCreditWalletSuccess
}
export interface CreditWalletFailed {
  type: typeof CREDIT_WALLET_FAILED
  payload: any
}
export interface GetUserBalance {
  type: typeof GET_USER_BALANCE
}
export interface GetUserBalanceSuccess {
  type: typeof GET_USER_BALANCE_SUCCESS
  payload: typeGetUserBalanceSuccess
}
export interface GetUserBalanceFailed {
  type: typeof GET_USER_BALANCE_FAILED
  payload: any
}

export interface TransferBetweenWallet {
  type: typeof TRANSFER_BETWEEN_WALLETS
}
export interface TransferBetweenWalletSuccess {
  type: typeof TRANSFER_BETWEEN_WALLETS_SUCCESS
  payload: typeTransferBetweenWalletSuccess
}
export interface TransferBetweenWalletFailed {
  type: typeof TRANSFER_BETWEEN_WALLETS_FAILED
  payload: any
}

export interface UpdateWalletBalance {
  type: typeof UPDATE_WALLET_BALANCE
}
export interface UpdateWalletBalanceSuccess {
  type: typeof UPDATE_WALLET_BALANCE_SUCCESS
  payload: typeUpdateWalletBalanceSuccess
}
export interface UpdateWalletBalanceFailed {
  type: typeof UPDATE_WALLET_BALANCE_FAILED
  payload: any
}

export type WalletActionConstants =
  | CreditWallet
  | CreditWalletSuccess
  | CreditWalletFailed
  | GetUserBalance
  | GetUserBalanceSuccess
  | GetUserBalanceFailed
  | TransferBetweenWallet
  | TransferBetweenWalletSuccess
  | TransferBetweenWalletFailed
  | UpdateWalletBalance
  | UpdateWalletBalanceSuccess
  | UpdateWalletBalanceFailed
