import {
  BankActionConstants,
  GET_BANK_LIST,
  GET_BANK_LIST_SUCCESS,
  GET_BANK_LIST_FAILED,
  VERIFY_ACCOUNT_NUMBER,
  VERIFY_ACCOUNT_NUMBER_SUCCESS,
  VERIFY_ACCOUNT_NUMBER_FAILED
} from './constants'

import { IBank } from '../../interfaces/bank'
import { DEFAULT_BANK } from './bank'

const initialState: IBank = DEFAULT_BANK

export const bankReducer = (
  state: IBank = initialState,
  action: BankActionConstants
) => {
  switch (action.type) {
    case GET_BANK_LIST:
      return {
        ...state,
        verify_account_number: null,
        get_bank_list_loading: true
      }
    case GET_BANK_LIST_SUCCESS:
      return {
        ...state,
        get_bank_list_loading: false,
        get_bank_list: action.payload.data.data
      }
    case GET_BANK_LIST_FAILED:
      return {
        ...state,
        get_bank_list_loading: false,
        error: action.payload
      }

    case VERIFY_ACCOUNT_NUMBER:
      return { ...state, verify_account_number_loading: true, error: null }
    case VERIFY_ACCOUNT_NUMBER_SUCCESS:
      return {
        ...state,
        verify_account_number_loading: false,
        verify_account_number: action.payload.data.data
      }
    case VERIFY_ACCOUNT_NUMBER_FAILED:
      return {
        ...state,
        verify_account_number_loading: false,
        error: action.payload
      }

    default:
      return state
  }
}
