import { combineReducers } from 'redux'

import { connectRouter } from 'connected-react-router'
import { /*createHashHistory*/ createBrowserHistory } from 'history'

import { alertReducer } from './alert/reducer'
import { authReducer } from './auth/reducer'
import { usersReducer } from './users/reducer'
import { contributionsReducer } from './contribution/reducer'
import { withdrawalReducer } from './withdrawals/reducer'
import { transactionsReducer } from './transactions/reducer'
import { membershipReducer } from './membership/reducer'
import { walletReducer } from './wallet/reducer'
import { bankReducer } from './bank/reducer'
import { configurationsReducer } from './configurations/reducer'
import { settingsReducer } from './settings/reducer'
import { pageDataReducer } from './page-data/reducer'
import { lendMeReducer } from './lend-me/reducer'

// export const history = createHashHistory({
//   hashType: 'slash'
// })
export const history = createBrowserHistory()

export const rootReducer = combineReducers({
  alert: alertReducer,
  authentication: authReducer,
  users: usersReducer,
  wallet: walletReducer,
  bank: bankReducer,
  contributions: contributionsReducer,
  withdrawals: withdrawalReducer,
  transactions: transactionsReducer,
  membership: membershipReducer,
  configurations: configurationsReducer,
  pageData: pageDataReducer,
  settings: settingsReducer,
  lend_me: lendMeReducer,
  router: connectRouter(history)
})
