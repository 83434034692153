import {
  WalletActionConstants,
  CREDIT_WALLET,
  CREDIT_WALLET_SUCCESS,
  CREDIT_WALLET_FAILED,
  GET_USER_BALANCE,
  GET_USER_BALANCE_SUCCESS,
  GET_USER_BALANCE_FAILED,
  TRANSFER_BETWEEN_WALLETS,
  TRANSFER_BETWEEN_WALLETS_SUCCESS,
  TRANSFER_BETWEEN_WALLETS_FAILED,
  UPDATE_WALLET_BALANCE,
  UPDATE_WALLET_BALANCE_SUCCESS,
  UPDATE_WALLET_BALANCE_FAILED
} from './constants'

import { IWallet } from '../../interfaces/wallet'
import { DEFAULT_WALLET } from './wallet'

const initialState: IWallet = DEFAULT_WALLET

export const walletReducer = (
  state: IWallet = initialState,
  action: WalletActionConstants
) => {
  switch (action.type) {
    case CREDIT_WALLET:
      return { ...state, credit_wallet_loading: true }
    case CREDIT_WALLET_SUCCESS:
      return {
        ...state,
        credit_wallet_loading: false,
        credit_wallet: action.payload.data
      }
    case CREDIT_WALLET_FAILED:
      return {
        ...state,
        credit_wallet_loading: false,
        error: { credit_wallet_error: action.payload }
      }

    case GET_USER_BALANCE:
      return { ...state, credit_wallet: null, get_user_balance_loading: true }
    case GET_USER_BALANCE_SUCCESS:
      return {
        ...state,
        get_user_balance_loading: false,
        get_user_balance: action.payload.data.data
      }
    case GET_USER_BALANCE_FAILED:
      return {
        ...state,
        get_user_balance_loading: false,
        error: { get_user_balance_error: action.payload }
      }

    case TRANSFER_BETWEEN_WALLETS:
      return { ...state, transfer_between_wallets_loading: true }
    case TRANSFER_BETWEEN_WALLETS_SUCCESS:
      return {
        ...state,
        transfer_between_wallets_loading: false,
        transfer_between_wallets: action.payload.data
      }
    case TRANSFER_BETWEEN_WALLETS_FAILED:
      return {
        ...state,
        transfer_between_wallets_loading: false,
        error: { transfer_between_wallets_error: action.payload }
      }

    case UPDATE_WALLET_BALANCE:
      return { ...state, update_wallet_balance_loading: true }
    case UPDATE_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        update_wallet_balance_loading: false,
        update_wallet_balance: action.payload.data
      }
    case UPDATE_WALLET_BALANCE_FAILED:
      return {
        ...state,
        update_wallet_balance_loading: false,
        error: { update_wallet_balance_error: action.payload }
      }

    default:
      return state
  }
}
