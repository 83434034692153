export const getAllPayouts = (payouts: []) => {
  if (payouts.length) {
    const flattenedPayouts = payouts.map((payout: { user: object }) => {
      const { user, ...rest } = payout
      return {
        ...rest,
        ...user
      }
    })

    return flattenedPayouts
  }

  return []
}

export const getAllWithdrawals = (withdrawals?: []) => {
  if (withdrawals.length) {
    const flattenedWithdrawals = withdrawals.map(
      (withdrawal: { user: object }) => {
        const { user, ...rest } = withdrawal
        return {
          ...rest,
          ...user
        }
      }
    )

    return flattenedWithdrawals
  }

  return []
}

export const getAllReferrals = (referrals?: []) => {
  if (referrals.length) {
    const flattenedWithdrawals = referrals.map((referral: { referredBy: any }) => {
      const { referredBy, ...rest } = referral
      return {
        ...rest,
        referrerName: referredBy?.name ?? '-',
        referrerPhone: referredBy?.phone ?? '-',
        referrerEmail: referredBy?.email ?? '-',
      }
    })

    return flattenedWithdrawals
  }

  return []
}
