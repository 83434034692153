import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunk from 'redux-thunk'
import { rootReducer } from './reducers'
import setAuthToken from '../utils/setAuthToken'

const initialState: any = {}
const middlewares = [thunk]

export function store(): any {
  const configureStore: any = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
  )

  let currentState = configureStore.getState()

  configureStore.subscribe(() => {
    // keep track of the previous and current state to compare changes
    let previousState = currentState
    currentState = configureStore.getState()
    // if the token changes set the value in localStorage and axios headers
    if (
      previousState.authentication.token !== currentState.authentication.token
    ) {
      const token = currentState.authentication.token
      setAuthToken(token)
    }
  })

  return configureStore
}

export type AppState = ReturnType<typeof rootReducer>
